import React, { Fragment, useState, useEffect } from "react";
import { Breadcrumbs, H5, Image } from "../../../AbstractElements";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Row,
} from "reactstrap";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import './ApproveProjectsList.css'
// import { deleteProjectCategory, getProjectCategory } from "../../../api/api";
import { getProject, getProjectCate } from "../../../api/api";
import axios from "axios";
import Select from 'react-select';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export const style = {
    width: 40,
    height: 40,
};
export const style2 = { width: 60, fontSize: 14, padding: 4 };

function ApproveProjectsList() {
    const navigate = useNavigate();
    const [dataAPI, setDataAPI] = useState([]);
    const [data, setdata] = useState([])

    const populateData = async () => {
        const res = await getProject();
        const filteredData = res.data.filter((t) => t.project_status == 'approved')
        setDataAPI(filteredData);

        const res2 = await getProjectCate();
        setdata(res2.data)
    };


    useEffect(() => {
        populateData();
    }, []);

    const addDataAPI = dataAPI?.map((elem) => {

        return {
            Name: `${elem.project_name}`,
            Type: `${elem.project_type}`,
            Category: `${elem.project_category == 'Other' ? (elem.project_category + ',' + elem.other_category) : ((data.filter((t) => t.cate_id == elem.project_category))[0]?.cate_name)}`,
            Status: `${elem.project_status}`,

            // action: (
            //     <select defaultValue={elem.category_status} onChange={(e) => handleChange(elem, e.target.value)}>
            //         <option>Select</option>
            //         <option value='Hot Pick'>Hot Pick</option>
            //         <option value='Top Projects'>Top Projects</option>
            //         <option value='Feature'>Feature</option>
            //         <option value='Popular'>Popular</option>
            //     </select>
            // ),
            isHotPick: (
                <select defaultValue={elem.isHotPick} onChange={(e) => handleHotPick(elem, e.target.value)}>
                    <option value=''>Select</option>
                    <option value='Yes'>Yes</option>
                    <option value='No'>No</option>
                </select>
            ),
            isTopProject: (
                <select defaultValue={elem.isTopProject} onChange={(e) => handleTopProject(elem, e.target.value)}>
                    <option value=''>Select</option>
                    <option value='Yes'>Yes</option>
                    <option value='No'>No</option>
                </select>
            ),
            isFeature: (
                <select defaultValue={elem.isFeature} onChange={(e) => handleFeature(elem, e.target.value)}>
                    <option value=''>Select</option>
                    <option value='Yes'>Yes</option>
                    <option value='No'>No</option>
                </select>
            ),
            isPopular: (
                <select defaultValue={elem.isPopular} onChange={(e) => handlePopular(elem, e.target.value)}>
                    <option value=''>Select</option>
                    <option value='Yes'>Yes</option>
                    <option value='No'>No</option>
                </select>
            ),
        };
    });

    const addColumns = [
        {
            name: "Project Name",
            selector: (row) => row.Name,
            sortable: true,
            center: true,
        },
        {
            name: "Project Type",
            selector: (row) => row.Type,
            sortable: true,
            center: true,
        },
        {
            name: "Project Category",
            selector: (row) => row.Category,
            sortable: true,
            center: true,
        },
        {
            name: "Project Status",
            selector: (row) => row.Status,
            sortable: true,
            center: true,
        },
        // {
        //     name: "Action",
        //     selector: (row) => row.action,
        //     sortable: true,
        //     center: true,
        // },
        {
            name: "Is Hot Pick",
            selector: (row) => row.isHotPick,
            sortable: true,
            center: true,
        },
        {
            name: "Is Top Project",
            selector: (row) => row.isTopProject,
            sortable: true,
            center: true,
        },
        {
            name: "Is Feature",
            selector: (row) => row.isFeature,
            sortable: true,
            center: true,
        },
        // {
        //     name: "Is Popular",
        //     selector: (row) => row.isPopular,
        //     sortable: true,
        //     center: true,
        // },
    ];

    // const handleChange = (elem, value) => {
    //     if (value == 'Select')
    //         return

    //     if (elem.category_status == value)
    //         return

    //     const filterOne = dataAPI.filter((t) => t.project_category == elem.project_category)
    //     if (filterOne.length < 8) {
    //         handleOkay(elem, value)
    //     } else {
    //         const filterTwo = filterOne.filter((t)=>t.category_status == value)
    //         if(filterTwo.length < 8){
    //             handleOkay(elem, value)
    //         }else{
    //             toast.error(`This Category's ${value} length is Full`, {
    //                 position: "top-right",
    //                 autoClose: 3000,
    //                 hideProgressBar: false,
    //                 closeOnClick: true,
    //                 pauseOnHover: true,
    //                 draggable: true,
    //                 progress: undefined,
    //             });
    //         }
    //     }
    // }

    // const handleOkay = (elem, value) => {
    //     const formData = { category_status: value }
    //     axios.put(`${global.backendUrl}/projectCateStatus/update/${elem.project_id}`, formData)
    //         .then((response) => {
    //             toast.success(`Updated Successfully`, {
    //                 position: "top-right",
    //                 autoClose: 3000,
    //                 hideProgressBar: false,
    //                 closeOnClick: true,
    //                 pauseOnHover: true,
    //                 draggable: true,
    //                 progress: undefined,
    //             });
    //             populateData();
    //         }).catch((err) => {
    //             console.log(err)
    //         })
    // }

    const handleHotPick = (elem, value) => {
        const formData = { status: value }
        axios.put(`${global.backendUrl}/projectCateStatus/isHotPick/${elem.project_id}`, formData)
            .then((response) => {
                toast.success(`Updated Successfully`, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                populateData();
            }).catch((err) => {
                console.log(err)
            })
    }

    const handleTopProject = (elem, value) => {
        const formData = { status: value }
        axios.put(`${global.backendUrl}/projectCateStatus/isTopProject/${elem.project_id}`, formData)
            .then((response) => {
                toast.success(`Updated Successfully`, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                populateData();
            }).catch((err) => {
                console.log(err)
            })
    }

    const handleFeature = (elem, value) => {
        const formData = { status: value }
        axios.put(`${global.backendUrl}/projectCateStatus/isFeature/${elem.project_id}`, formData)
            .then((response) => {
                toast.success(`Updated Successfully`, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                populateData();
            }).catch((err) => {
                console.log(err)
            })
    }

    const handlePopular = (elem, value) => {
        const formData = { status: value }
        axios.put(`${global.backendUrl}/projectCateStatus/isPopular/${elem.project_id}`, formData)
            .then((response) => {
                toast.success(`Updated Successfully`, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                populateData();
            }).catch((err) => {
                console.log(err)
            })
    }


    return (
        <Fragment>
            <Breadcrumbs
                parent="Projects"
                title="Approved Projects"
                mainTitle="Approved Projects"
            />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader className="pb-0">
                                <H5>{"User Projects"}</H5>
                            </CardHeader>
                            <CardBody>
                                <div className="table-responsive product-table">
                                    <DataTable
                                        noHeader
                                        pagination
                                        paginationServer
                                        columns={addColumns}
                                        data={addDataAPI}
                                    />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}

export default ApproveProjectsList;
