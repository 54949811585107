import React, { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Breadcrumbs } from '../../../AbstractElements';
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { insertHeroSection, updateHeroSection } from '../../../api/api';

const AntsHero = () => {
  const [title, setTitle] = useState('');
  const [subtitle, setSubTitle] = useState('');
  const [text, setText] = useState('');
  const [btnText, setBtnText] = useState('');
  const [btnUrl, setBtnUrl] = useState('');
  const [sliderUrl, setSliderUrl] = useState('');
  const [imgFile, setImageFile] = useState('');

  const navigate = useNavigate();
  const location = useLocation();

  const handleChangeStatus = ({ meta, file }, status) => {
    setImageFile(file);
  };

  const handleAdd = async () => {
    const formData = new FormData();
    formData.append('hero_title', title);
    formData.append('hero_subtitle', subtitle);
    formData.append('hero_text', text);
    formData.append('hero_btnText', btnText);
    formData.append('hero_btnUrl', btnUrl);
    formData.append('hero_slider', sliderUrl);
    formData.append('hero_image', imgFile);

    const res = await insertHeroSection(formData);
    if (res.status === 200) {
      navigate(`${process.env.PUBLIC_URL}/home/herosection_list`);
    } else {
      alert('Error');
    }
  };
  const handleUpdate = async (id) => {
    const formData = new FormData();
    formData.append('hero_title', title);
    formData.append('hero_subtitle', subtitle);
    formData.append('hero_text', text);
    formData.append('hero_btnText', btnText);
    formData.append('hero_btnUrl', btnUrl);
    formData.append('hero_slider', sliderUrl);
    formData.append('hero_image', imgFile);

    const res = await updateHeroSection(id, formData);
    if (res.status === 200) {
      navigate(`${process.env.PUBLIC_URL}/home/herosection_list`);
    } else {
      alert('Error occured.');
    }
  };

  const populateData = () => {
    if (location?.state) {
      setTitle(location?.state?.dataObj?.hero_title);
      setSubTitle(location?.state?.dataObj?.hero_subtitle);
      setText(location?.state?.dataObj?.hero_text);
      setBtnText(location?.state?.dataObj?.hero_btnText);
      setBtnUrl(location?.state?.dataObj?.hero_btnUrl);
      setSliderUrl(location?.state?.dataObj?.hero_slider)
      setImageFile(location?.state?.dataObj?.hero_image);
    }
  };

  useEffect(() => {
    populateData();
  }, []);

  return (
    <Fragment>
      <Breadcrumbs
        parent="Home"
        title="Add Hero Section"
        mainTitle="Add Hero Section"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Form method="POST">
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{'Title'}</Label>
                        <Input
                          onChange={(e) => setTitle(e.target.value)}
                          value={title}
                          type="text"
                          className="form-control"
                          required
                          placeholder="Enter Title"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <FormGroup>
                        <Label>{'Sub Title'}</Label>
                        <Input
                          onChange={(e) => setSubTitle(e.target.value)}
                          value={subtitle}
                          type="text"
                          className="form-control"
                          required
                          placeholder="Enter Sub Title"
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={6}>
                      <FormGroup>
                        <Label>{'Short Text'}</Label>
                        <Input
                          onChange={(e) => setText(e.target.value)}
                          value={text}
                          type="text"
                          className="form-control"
                          required
                          placeholder="Enter Short Text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <FormGroup>
                        <Label>{'Button Text'}</Label>
                        <Input
                          onChange={(e) => setBtnText(e.target.value)}
                          value={btnText}
                          type="text"
                          className="form-control"
                          required
                          placeholder="Enter Button Text"
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={6}>
                      <FormGroup>
                        <Label>{'Button Url'}</Label>
                        <Input
                          onChange={(e) => setBtnUrl(e.target.value)}
                          value={btnUrl}
                          type="text"
                          className="form-control"
                          required
                          placeholder="Enter Button URL"
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={6}>
                      <FormGroup>
                        <Label>{'Slider Url'}</Label>
                        <Input
                          onChange={(e) => setSliderUrl(e.target.value)}
                          value={sliderUrl}
                          type="text"
                          className="form-control"
                          required
                          placeholder="Enter Slider URL"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{'Image'}</Label>
                        <Dropzone
                          onChangeStatus={handleChangeStatus}
                          inputContent="Drop An Image"
                          multiple={false}
                          canCancel={true}
                          maxFiles={1}
                          styles={{
                            dropzone: { width: '100%', minHeight: 50 },
                            dropzoneActive: { borderColor: 'green' },
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup className="mb-0">
                        {location?.state ? (
                          <Button
                            onClick={() =>
                              handleUpdate(location?.state?.dataObj?.hero_id)
                            }
                            className="me-3"
                            color="success"
                          >
                            {'Update'}
                          </Button>
                        ) : (
                          <Button
                            onClick={handleAdd}
                            className="me-3"
                            color="success"
                          >
                            {'Submit'}
                          </Button>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default AntsHero;
