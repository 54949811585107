import React, { Fragment, useState, useEffect } from 'react'
import { Breadcrumbs, H5, H6, P } from '../../AbstractElements'
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { getEmailTemplates, getProject, getProjectCate, getProjectWithUsers } from '../../api/api';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
export default function UpdateStatus() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setdata] = useState([])
  const [data2, setdata2] = useState([])
  const [type, settype] = useState('')
  const [emailApproveTemplate, setEmailApproveTemplate] = useState([])
  const [emailRejectTemplate, setEmailRejectTemplate] = useState([])

  const populateData = async () => {
    const res = await getProjectWithUsers();
    const filteredData = res.data.filter((t) => t.project_id == id)
    settype(filteredData[0].project_type)
    setdata(filteredData[0])

    const res2 = await getProjectCate();
    setdata2(res2.data)

    const resEmail = await getEmailTemplates();
    const approveTemplates = resEmail.data.filter((item) => item.email_template_type === "Approve Email")
    setEmailApproveTemplate(approveTemplates)
    const rejectTemplates = resEmail.data.filter((item) => item.email_template_type === "Reject Email")
    setEmailRejectTemplate(rejectTemplates)


  };

  const handleUpdate = (e) => {
    // console.log("data", data)
    // console.log("e", e)
    // return;
    const formData = { project_status: e }
    axios.put(`${global.backendUrl}/projectStatus/update/${id}`, formData)
      .then((response) => {
        if (data.email) {
          let finalTemplate = "", subject = "";
          if (e === "approved") {
            let templateString = emailApproveTemplate[0].email_template_content;
            subject = emailApproveTemplate[0].email_template_subject;
            finalTemplate = templateString
              .replace("@donation_amount", "$" + data.how_much_want_raise)
              .replace("@project_name", data.project_name);
            console.log("finalTemplate", finalTemplate)
          } else {
            let templateString = emailRejectTemplate[0].email_template_content;
            subject = emailRejectTemplate[0].email_template_subject;
            finalTemplate = templateString
              .replace("@project_name", data.project_name);
            console.log("finalTemplate", finalTemplate)
          }

          const formDataEmail = {
            user_email: data.email,
            subject: subject,
            message: finalTemplate
          }
          axios.post(`${global.backendUrl}/sendEmail`, formDataEmail)
            .then((res) => {
              toast.success(`Project Status Updated Successfully`, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              navigate(`${process.env.PUBLIC_URL}/project/userProjects`);
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          toast.success(`Project Status Updated Successfully`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          navigate(`${process.env.PUBLIC_URL}/project/userProjects`);
        }

      }).catch((err) => {
        console.log(err)
      })
  }


  useEffect(() => {
    populateData();
  }, []);
  return (
    <Fragment>
      <Breadcrumbs
        parent="Projects"
        title="Update Status"
        mainTitle="Update User's Project Status"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Row>
                  <Col sm="3">
                    <H6>Creator Name:</H6>
                  </Col>
                  <Col sm="3">
                    <P>{data?.userName}</P>
                  </Col>
                  <Col sm="3">
                    <H6>Project Name:</H6>
                  </Col>
                  <Col sm="3">
                    <P>{data?.project_name}</P>
                  </Col>
                </Row>

                <Row className='mt-3'>
                  <Col sm="3">
                    <H6>Project Type:</H6>
                  </Col>
                  <Col sm="3">
                    <P>{data?.project_type}</P>
                  </Col>
                  <Col sm="3">
                    <H6>Project Category:</H6>
                  </Col>
                  <Col sm="3">
                    <P>{data?.project_category == 'Other' ? (data?.project_category + ',' + data?.other_category) : ((data2.filter((t) => t.cate_id == data?.project_category))[0]?.cate_name)}</P>
                  </Col>
                </Row>

                {
                  type.toLowerCase() == "ngo" ?
                    <Row className='mt-3'>
                      <Col sm="3">
                        <H6>NGO Name:</H6>
                      </Col>
                      <Col sm="3">
                        <P>{data?.ngo_name}</P>
                      </Col>
                      <Col sm="3">
                        <H6>Website Url:</H6>
                      </Col>
                      <Col sm="3">
                        <a href={data?.website_url} target='_blank' className='text-primary'>{data?.website_url}</a>
                      </Col>
                    </Row>
                    :
                    <Row className='mt-3'>
                      <Col sm="3">
                        <H6>Project Team:</H6>
                      </Col>
                      <Col sm="3">
                        <P>{data?.project_team}</P>
                      </Col>
                      <Col sm="3">
                        <H6>State of Development:</H6>
                      </Col>
                      <Col sm="3">
                        <P>{data?.stateDevelopment}</P>
                      </Col>
                    </Row>
                }

                {
                  type.toLowerCase() == 'ngo' ?
                    <Row className='mt-3'>
                      <Col sm="3">
                        <H6>Pitch Deck:</H6>
                      </Col>
                      <Col sm="3">
                        <a href={`${global.backendUrl}/nodeassets/${data?.pitchdeck}`} target='_blank' className='btn btn-sm btn-info'>View</a>
                      </Col>
                      <Col sm="3">
                        <H6>How much do you want to raise:</H6>
                      </Col>
                      <Col sm="3">
                        <P>{data?.how_much_want_raise}</P>
                      </Col>
                      <Col sm="3">
                        <H6>How will you spend this fund?:</H6>
                      </Col>
                      <Col sm="3">
                        <P>{data?.how_spend_fund}</P>
                      </Col>
                      <Col sm="3">
                        <H6>How much you already have raised?:</H6>
                      </Col>
                      <Col sm="3">
                        <P>{data?.hm_alreadyRaised}</P>
                      </Col>
                      <Col sm="3">
                        <H6>Is there any other platform are you using to raise?:</H6>
                      </Col>
                      <Col sm="3">
                        <P>{data?.is_anyOtherPlat}</P>
                      </Col>
                      {
                        data?.is_anyOtherPlat == 'Yes' &&
                        <>
                          <Col sm="3">
                            <H6>Which One?:</H6>
                          </Col>
                          <Col sm="3">
                            <P>{data?.Other_platformsPlan}</P>
                          </Col>
                        </>
                      }
                    </Row>
                    :
                    <>
                      <Row className='mt-3'>
                        <Col sm="3">
                          <H6>Website Url:</H6>
                        </Col>
                        <Col sm="3">
                          <a href={data?.website_url} target='_blank' className='text-primary'>{data?.website_url}</a>
                        </Col>
                        <Col sm="3">
                          <H6>Android App Url:</H6>
                        </Col>
                        <Col sm="3">
                          <a href={data?.androidApp_url} className='text-primary' target='_blank'>{data?.androidApp_url}</a>
                        </Col>
                      </Row>

                      <Row className='mt-3'>
                        <Col sm="3">
                          <H6>White Paper Url:</H6>
                        </Col>
                        <Col sm="3">
                          <a href={data?.whitepaper_url} className='text-primary' target='_blank'>{data?.whitepaper_url}</a>
                        </Col>
                        <Col sm="3">
                          <H6>IOS App Url:</H6>
                        </Col>
                        <Col sm="3">
                          <a href={data?.iosApp_url} className='text-primary' target='_blank'>{data?.iosApp_url}</a>
                        </Col>
                      </Row>

                      <Row className='mt-3'>
                        <Col sm="3">
                          <H6>First Biggest Investors:</H6>
                        </Col>
                        <Col sm="3">
                          <P>{data?.first_biggestInvs}</P>
                        </Col>
                        <Col sm="3">
                          <H6>Second Biggest Investors:</H6>
                        </Col>
                        <Col sm="3">
                          <P>{data?.sec_biggestInvs}</P>
                        </Col>
                      </Row>

                      <Row className='mt-3'>
                        <Col sm="3">
                          <H6>Third Biggest Investors:</H6>
                        </Col>
                        <Col sm="3">
                          <P>{data?.third_biggestInvs}</P>
                        </Col>
                        <Col sm="3">
                          <H6>Pitch Deck:</H6>
                        </Col>
                        <Col sm="3">
                          <a href={`${global.backendUrl}/nodeassets/${data?.pitchdeck}`} target='_blank' className='btn btn-sm btn-info'>View</a>
                        </Col>
                      </Row>

                      <Row className='mt-3'>
                        <Col sm="5">
                          <H6>How much you already have raised?:</H6>
                        </Col>
                        <Col sm="1">
                          <P>{data?.hm_alreadyRaised}</P>
                        </Col>
                        <Col sm="5">
                          <H6>Are you flexing with amount?:</H6>
                        </Col>
                        <Col sm="1">
                          <P>{data?.flexingAmount}</P>
                        </Col>
                      </Row>

                      <Row className='mt-3'>
                        <Col sm="5">
                          <H6>How much are you looking to total raise excluding ANTS ?:</H6>
                        </Col>
                        <Col sm="1">
                          <P>{data?.hm_totalRaise}</P>
                        </Col>
                        <Col sm="5">
                          <H6>How much are you looking to raise with ANTS?:</H6>
                        </Col>
                        <Col sm="1">
                          <P>{data?.hm_lookingRaiseWithAnts}</P>
                        </Col>
                      </Row>

                      <Row className='mt-3'>
                        <Col sm="4">
                          <H6>When would you like to launch?:</H6>
                        </Col>
                        <Col sm="2">
                          <P>{data?.project_launchingDate}</P>
                        </Col>
                        <Col sm="4">
                          <H6>What other platforms, are you planning?:</H6>
                        </Col>
                        <Col sm="2">
                          <P>{data?.Other_platformsPlan}</P>
                        </Col>
                      </Row>

                      <Row className='mt-3'>
                        <Col sm="5">
                          <H6>Are you working with any marketing agency?:</H6>
                        </Col>
                        <Col sm="1">
                          <P>{data?.AnyOther_marketAgen}</P>
                        </Col>
                        <Col sm="5">
                          <H6>Are you working with any software development agency ?:</H6>
                        </Col>
                        <Col sm="1">
                          <P>{data?.AnyOther_softwareAgen}</P>
                        </Col>
                      </Row>
                    </>
                }
                <Row className='mt-3'>
                  <Col sm="12">
                    <H6>Project Icon/Image:</H6>
                  </Col>
                  <Col sm="12">
                    <img className='img-fluid' src={`${global.backendUrl}/nodeassets/${data?.project_iconImage}`} />
                  </Col>
                </Row>

                <Row className='mt-3'>
                  <Col sm="12">
                    <H6>Description:</H6>
                  </Col>
                  <Col sm="12">
                    <P>{data?.project_description}</P>
                  </Col>
                </Row>

                <Row className='mt-4'>
                  <Col sm="2">
                    <Button className='btn-success' onClick={() => handleUpdate("approved")}>Approve</Button>
                  </Col>
                  <Col sm="2">
                    <Button className='btn-danger' onClick={() => handleUpdate("rejected")}>Reject</Button>
                  </Col>
                </Row>

              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}
