import React, { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Breadcrumbs } from '../../../../AbstractElements';
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import toast from 'react-hot-toast';
import Web3 from "web3";
import ABI_MyContract from '../../../../api/abi-mycontract';
import { editprivateSale,insertprivateSale } from '../../../../api/api'
import { set } from 'react-hook-form';

function AddPrivateSaleInfo() {
    const [start,setStart] = useState('');
    const [end,setEnd] = useState('');
    const [price, setPrice] = useState('');
    const [totalToken, setTotalToken] = useState('');
    const users = [
      { id: 1, name: "Active" },
      { id: 2, name: "In Active" },
     
    ];
    const [open, setOpen] = useState(false);
    const [dropDownValue, setDropDownValue] = useState("Active");
  
    const openDropDown = () => {
      setOpen(!open);
    };
  
    const handleUserSelection = (name) => {
      setDropDownValue(name);
      setOpen(false); 
    };
  
    const navigate = useNavigate();
    const location = useLocation();
  
  
    const handleAdd = async () => {
      if(!start || !end || !price){
        toast.error("Please enter all fields")
        return
      }
      const formData = {
        private_sale_start_time: start,
        private_sale_end_time: end,
        private_sale_token_price: price,
        private_sale_status:dropDownValue,
        private_total_token:totalToken,
      };
  
      const res = await insertprivateSale(formData);
      
      if (res.status === 200) {
        navigate(`${process.env.PUBLIC_URL}/privateSale/get_saleInfo`);
      } else {
      toast.error("Error occure in adding data")
      }
    };
  
  
  
    const handleUpdate = async (id) => {
      const formData = {
        private_sale_start_time: start,
        private_sale_end_time: end,
        private_sale_token_price: price,
        private_sale_status:dropDownValue,
        private_total_token:totalToken,
      };
  
      const res = await editprivateSale(id, formData);
      if (res.status === 200) {
        navigate(`${process.env.PUBLIC_URL}/privateSale/get_saleInfo`);
      } else {
        alert('Error occured.');
      }
    };
  
    const populateData = () => {
      if (location?.state?.dataObj) {
       const {private_sale_start_time,private_sale_end_time,private_sale_token_price,private_sale_status,private_total_token}=location.state.dataObj;
       setStart(private_sale_start_time)
       setEnd(private_sale_end_time)
       setPrice(private_sale_token_price)
       setDropDownValue(private_sale_status)
       setTotalToken(private_total_token)
      }
    };
  
    useEffect(() => {
      populateData();
    }, []);

   
  return (
    <Fragment>
    <Breadcrumbs
      parent="Home"
      title="Add Private Sale"
      mainTitle="Add Private Sale"
    />
    <Container fluid={true}>
      <Row>
        <Col sm="12">
          <Card>
            <CardBody>
              <Form method="POST">
                <Row>
                  <Col lg={6}>
                    <FormGroup>
                      <Label>{'Start Time'}</Label>
                      <Input
                        onChange={(e) => setStart(e.target.value)}
                        value={start}
                        type="datetime-local"
                        className="form-control"
                        required
                        placeholder="wallet address"
                      />
                    </FormGroup>
                  </Col>
                  <Col lg={6}>
                    <FormGroup>
                      <Label>{'End Time'}</Label>
                      <Input
                        onChange={(e) => setEnd(e.target.value)}
                        value={end}
                        type="datetime-local"
                        className="form-control"
                        required
                        placeholder=""
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                 
                  <Col lg={6}>
                    <FormGroup>
                      <Label>{'Token Price'}</Label>
                      <Input
                        onChange={(e) => setPrice(e.target.value)}
                        value={price}
                        type="number"
                        className="form-control"
                        required
                        placeholder="Lock time period"
                      />
                    </FormGroup>
                  </Col>
                  <Col lg={6}>
                      <FormGroup>
                        <Label>{"Status"}</Label>
                        <div  style={{ position: "relative" }}>
                          <div
                            onClick={openDropDown}
                            style={{
                              cursor: "pointer",
                              backgroundColor: "#f8f9fa",
                              padding: "10px",
                              position: "relative",
                              borderRadius: "5px",
                            }}
                          >
                            {dropDownValue}
                          </div>

                          {open && (
                            <div
                              style={{
                                backgroundColor: "#f8f9fa",
                                position: "absolute",
                                top: "50px",
                                left: "0",
                                right: "0",
                                padding: "10px",
                                borderRadius: "5px",
                                zIndex: 1,
                              }}
                            >
                              {users.map((user) => (
                                <div
                                  key={user.id}
                                  style={{
                                    padding: "8px",
                                    cursor: "pointer",
                                    borderBottom: "1px solid #ddd",
                                  }}
                                  onClick={() => handleUserSelection(user.name)}
                                >
                                  {user.name}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </FormGroup>
                    </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <FormGroup>
                      <Label>{'Total Token'}</Label>
                      <Input
                        onChange={(e) => setTotalToken(e.target.value)}
                        value={totalToken}
                        type="number"
                        className="form-control"
                        required
                        placeholder="Total Token"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup className="mb-0">
                      {location?.state ? (
                        <Button
                          onClick={() =>
                            handleUpdate(location?.state?.dataObj?.private_sale_id)
                          }
                          className="me-3"
                          color="success"
                        >
                          {'Update'}
                        </Button>
                      ) : (
                        <Button
                          onClick={handleAdd}
                          className="me-3"
                          color="success"
                        >
                          {'Submit'}
                        </Button>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  </Fragment>
  )
}

export default AddPrivateSaleInfo
