import React, { Fragment, useEffect, useState } from "react";
import { Breadcrumbs } from "../../../AbstractElements";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { insertProjectCategory, updateProjectCategory } from "../../../api/api";
import { useLocation, useNavigate } from "react-router-dom";

const AddCategories = () => {
  const [catName, setCatName] = useState("");
  const [shortText, setShortText] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false)

  const handleAdd = async () => {
    const formData = {
      category_name: catName,
      category_text: shortText
    }
    setIsLoading(true)
    const res = await insertProjectCategory(formData);
    if (res.status === 200) {
      setIsLoading(false)
      navigate(`${process.env.PUBLIC_URL}/projects/category_list`);
    } else {
      setIsLoading(false)
      alert("Error");
    }
  };
  const handleUpdate = async (id) => {
    const formData = {
      category_name: catName,
      category_text: shortText
    }
    setIsLoading(true)
    const res = await updateProjectCategory(id, formData);
    if (res.status === 200) {
      setIsLoading(false)
      navigate(`${process.env.PUBLIC_URL}/projects/category_list`);
    } else {
      setIsLoading(false)
      alert("Error occured.");
    }
  };

  const populateData = () => {
    if (location?.state) {
      setCatName(location?.state?.dataObj?.category_name);
      setShortText(location?.state?.dataObj?.category_text);
    }
  };

  useEffect(() => {
    populateData();
  }, []);

  return (
    <>
      <Fragment>
        <Breadcrumbs
          parent="Projects"
          title="Add Category"
          mainTitle="Add Category"
        />
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardBody>
                  <Form method="POST">
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>{"Name"}</Label>
                          <Input
                            type="text"
                            required
                            value={catName}
                            className="form-control"
                            placeholder="Enter Category Name"
                            onChange={(e) => setCatName(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>{"Short Text"}</Label>
                          <Input
                            type="text"
                            required
                            value={shortText}
                            className="form-control"
                            placeholder="Enter Short Text"
                            onChange={(e) => setShortText(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup className="mb-0">
                          {location?.state ? (
                            <Button
                              onClick={() =>
                                handleUpdate(location?.state?.dataObj?.category_id)
                              }
                              className="me-3"
                              color="success"
                            >
                              {
                                isLoading ?
                                  <div class="spinner-border spinner-border-sm" role="status">
                                    <span class="sr-only">Loading...</span>
                                  </div>
                                  : "Update"
                              }
                            </Button>
                          ) : (
                            <Button
                              onClick={handleAdd}
                              className="me-3"
                              color="success"
                            >
                              {
                                isLoading ?
                                  <div class="spinner-border spinner-border-sm" role="status">
                                    <span class="sr-only">Loading...</span>
                                  </div>
                                  : "Submit"
                              }
                            </Button>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment>
    </>
  );
};

export default AddCategories;
