import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { Breadcrumbs } from "../../../AbstractElements";
import {
  getProductsCategory,
  insertProductSubCategory,
  updateProductSubCategory,
} from "../../../api/api";

const AntsProductSubCategory = () => {
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("");
  const [getCategory, setGetCategory] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const handleAdd = async () => {
    setLoading(true);
    const formData = {
      product_subcategory_name: title,
      product_category_id: category,
    };

    const res = await insertProductSubCategory(formData);
    setLoading(false);
    if (res.status === 200) {
      navigate(`${process.env.PUBLIC_URL}/subcategory/subcategory_list`);
    } else {
      alert("Error");
      setLoading(false);
    }
  };
  const handleUpdate = async (id) => {
    setLoading(true);
    const formData = {
      product_subcategory_name: title,
      product_category_id: category,
    };

    const res = await updateProductSubCategory(formData, id);
    setLoading(false);
    if (res.status === 200) {
      navigate(`${process.env.PUBLIC_URL}/subcategory/subcategory_list`);
    } else {
      alert("Error occured.");
      setLoading(false);
    }
  };

  const populateData = () => {
    if (location?.state) {
      setTitle(location?.state?.dataObj?.product_subcategory_name);
      setCategory(location?.state?.dataObj?.product_category_id);
    }
  };

  const fetchCategories = async () => {
    const res = await getProductsCategory();
    setGetCategory(res.data);
  };

  useEffect(() => {
    populateData();
    fetchCategories();
  }, []);

  return (
    <>
      <Fragment>
        <Breadcrumbs
          parent="Products Sub Category"
          title="Add Products Sub Category"
          mainTitle="Add Products Sub Category"
        />
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardBody>
                  <Form method="post">
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>{"Name"}</Label>
                          <input
                            placeholder="Enter Name"
                            className="form-control"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>{"Select Category"}</Label>
                          <Input
                            className="form-control"
                            value={category}
                            type="select"
                            onChange={(e) => setCategory(e.target.value)}
                          >
                            <option>Select Category</option>
                            {getCategory?.map((c, i) => (
                              <option key={i} value={c?.product_category_id}>{c?.product_category_name}</option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup className="mb-0">
                          {location?.state ? (
                            <Button
                              onClick={() =>
                                handleUpdate(
                                  location?.state?.dataObj
                                    ?.product_subcategory_id
                                )
                              }
                              className="me-3"
                              color="success"
                            >
                              {loading ? "Update..." : "Update"}
                            </Button>
                          ) : (
                            <Button
                              onClick={handleAdd}
                              className="me-3"
                              color="success"
                            >
                              {loading ? "Submitting..." : "Submit"}
                            </Button>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment>
    </>
  );
};

export default AntsProductSubCategory;
