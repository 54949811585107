import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Breadcrumbs } from "../../../AbstractElements";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { insertAntsTeam, updateAntsTeam } from "../../../api/api";

const AntsTeam = () => {
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [position, setPosition] = useState("");
  const [twitterLink, setTwitterLink] = useState("");
  const [facebookLink, setFacebookLink] = useState("");
  const [instagramLink, setInstagramLink] = useState("");
  const [linkedinLink, setLinkedinLink] = useState("");
  const [imgFile, setImageFile] = useState("");

  const navigate = useNavigate();
  const location = useLocation();

  const handleChangeStatus = ({ meta, file }, status) => {
    setImageFile(file);
  };

  const handleAdd = async () => {
    const formData = new FormData();
    formData.append("team_name", title);
    formData.append("team_text", text);
    formData.append("team_position", position);
    formData.append("team_twitter", twitterLink);
    formData.append("team_facebook", facebookLink);
    formData.append("team_linkedin", linkedinLink);
    formData.append("team_instagram", instagramLink);
    formData.append("team_image", imgFile);

    const res = await insertAntsTeam(formData);
    if (res.status === 200) {
      navigate(`${process.env.PUBLIC_URL}/team/team_list`);
    } else {
      alert("Error");
    }
  };
  const handleUpdate = async (id) => {
    const formData = new FormData();
    formData.append("team_name", title);
    formData.append("team_text", text);
    formData.append("team_position", position);
    formData.append("team_twitter", twitterLink);
    formData.append("team_facebook", facebookLink);
    formData.append("team_linkedin", linkedinLink);
    formData.append("team_instagram", instagramLink);
    formData.append("team_image", imgFile);

    const res = await updateAntsTeam(id, formData);
    if (res.status === 200) {
      navigate(`${process.env.PUBLIC_URL}/team/team_list`);
    } else {
      alert("Error occured.");
    }
  };

  const populateData = () => {
    if (location?.state) {
      setTitle(location?.state?.dataObj?.team_name);
      setText(location?.state?.dataObj?.team_text);
      setPosition(location?.state?.dataObj?.team_position);
      setTwitterLink(location?.state?.dataObj?.team_twitter);
      setFacebookLink(location?.state?.dataObj?.team_facebook);
      setLinkedinLink(location?.state?.dataObj?.team_linkedin);
      setInstagramLink(location?.state?.dataObj?.team_instagram);
      setImageFile(location?.state?.dataObj?.team_image);
    }
  };

  useEffect(() => {
    populateData();
  }, []);

  return (
    <Fragment>
      <Breadcrumbs
        parent="Ants Team"
        title="Add Ants Team"
        mainTitle="Add Ants Team"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Form method="POST">
                  <Row>
                    <Col lg={6}>
                      <FormGroup>
                        <Label>{"Name"}</Label>
                        <Input
                          onChange={(e) => setTitle(e.target.value)}
                          value={title}
                          type="text"
                          className="form-control"
                          required
                          placeholder="Enter Title"
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={6}>
                      <FormGroup>
                        <Label>{"Short Description"}</Label>
                        <Input
                          onChange={(e) => setText(e.target.value)}
                          value={text}
                          type="text"
                          className="form-control"
                          required
                          placeholder="Enter Text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <FormGroup>
                        <Label>{"Position"}</Label>
                        <Input
                          onChange={(e) => setPosition(e.target.value)}
                          value={position}
                          type="text"
                          className="form-control"
                          required
                          placeholder="Enter Position"
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={6}>
                      <FormGroup>
                        <Label>{"Twitter Link"}</Label>
                        <Input
                          onChange={(e) => setTwitterLink(e.target.value)}
                          value={twitterLink}
                          type="text"
                          className="form-control"
                          required
                          placeholder="Enter Twitter Link"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <FormGroup>
                        <Label>{"Facebook Link"}</Label>
                        <Input
                          onChange={(e) => setFacebookLink(e.target.value)}
                          value={facebookLink}
                          type="text"
                          className="form-control"
                          required
                          placeholder="Enter Facebook Link"
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={6}>
                      <FormGroup>
                        <Label>{"Linkedin Link"}</Label>
                        <Input
                          onChange={(e) => setLinkedinLink(e.target.value)}
                          value={linkedinLink}
                          type="text"
                          className="form-control"
                          required
                          placeholder="Enter Linkedin Link"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{"Instagram Link"}</Label>
                        <Input
                          onChange={(e) => setInstagramLink(e.target.value)}
                          value={instagramLink}
                          type="text"
                          className="form-control"
                          required
                          placeholder="Enter Instagram Link"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{"Image"}</Label>
                        <Dropzone
                          onChangeStatus={handleChangeStatus}
                          inputContent="Drop An Image"
                          multiple={false}
                          canCancel={true}
                          maxFiles={1}
                          styles={{
                            dropzone: { width: "100%", minHeight: 50 },
                            dropzoneActive: { borderColor: "green" },
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup className="mb-0">
                        {location?.state ? (
                          <Button
                            onClick={() =>
                              handleUpdate(location?.state?.dataObj?.team_id)
                            }
                            className="me-3"
                            color="success"
                          >
                            {"Update"}
                          </Button>
                        ) : (
                          <Button
                            onClick={handleAdd}
                            className="me-3"
                            color="success"
                          >
                            {"Submit"}
                          </Button>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default AntsTeam;
