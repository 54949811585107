import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import { Breadcrumbs } from "../../../AbstractElements";
import { insertAntsPartners, updateAntsPartners } from "../../../api/api";

const AntsPartners = () => {
  const [name, setName] = useState("");
  const [url, setUrl] = useState("");
  const [imgFile, setImageFile] = useState("");

  const navigate = useNavigate();
  const location = useLocation();

  const handleChangeStatus = ({ meta, file }, status) => {
    setImageFile(file);
  };

  const handleAdd = async () => {
    const formData = new FormData();
    formData.append("partners_name", name);
    formData.append("partners_url", url);
    formData.append("partners_image", imgFile);

    const res = await insertAntsPartners(formData);
    if (res.status === 200) {
      navigate(`${process.env.PUBLIC_URL}/partners/partners_list`);
    } else {
      alert("Error");
    }
  };
  const handleUpdate = async (id) => {
    const formData = new FormData();
    formData.append("partners_name", name);
    formData.append("partners_url", url);
    formData.append("partners_image", imgFile);

    const res = await updateAntsPartners(id, formData);
    if (res.status === 200) {
      navigate(`${process.env.PUBLIC_URL}/partners/partners_list`);
    } else {
      alert("Error occured.");
    }
  };

  const populateData = () => {
    if (location?.state) {
      setName(location?.state?.dataObj?.partners_name);
      setUrl(location?.state?.dataObj?.partners_url);
      setImageFile(location?.state?.dataObj?.partners_image);
    }
  };

  useEffect(() => {
    populateData();
  }, []);

  return (
    <>
      <Fragment>
        <Breadcrumbs
          parent="Ants Partners"
          title="Add Ants Partners"
          mainTitle="Add Ants Partners"
        />
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardBody>
                  <Form method="post">
                    <Row>
                      <Col lg={6}>
                        <FormGroup>
                          <Label>{"Name"}</Label>
                          <input
                            placeholder="Enter Name"
                            className="form-control"
                            value={name}
                            type="text"
                            onChange={(e) => setName(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg={6}>
                        <FormGroup>
                          <Label>{"Url"}</Label>
                          <input
                            placeholder="Enter Website Url"
                            className="form-control"
                            value={url}
                            type="text"
                            onChange={(e) => setUrl(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>{"Image"}</Label>
                          <Dropzone
                            onChangeStatus={handleChangeStatus}
                            inputContent="Drop An Image"
                            multiple={false}
                            canCancel={true}
                            maxFiles={1}
                            styles={{
                              dropzone: { width: "100%", minHeight: 50 },
                              dropzoneActive: { borderColor: "green" },
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup className="mb-0">
                          {location?.state ? (
                            <Button
                              onClick={() =>
                                handleUpdate(
                                  location?.state?.dataObj?.partners_id
                                )
                              }
                              className="me-3"
                              color="success"
                            >
                              {"Update"}
                            </Button>
                          ) : (
                            <Button
                              onClick={handleAdd}
                              className="me-3"
                              color="success"
                            >
                              {"Submit"}
                            </Button>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment>
    </>
  );
};

export default AntsPartners;
