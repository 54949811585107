import React, { Fragment, useEffect, useState } from "react";
import { Breadcrumbs } from "../../AbstractElements";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import {  insertProjectType, updateProjectType  } from "../../api/api";
import { useLocation, useNavigate } from "react-router-dom";

const AddProjectType = () => {
  const [typeName, settypeName] = useState('')
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false)

  const handleAdd = async () => {
    const formData = {
      type_name: typeName,
    }
    setIsLoading(true)
    const res = await insertProjectType(formData);
    if (res.status === 200) {
      setIsLoading(false)
      navigate(`${process.env.PUBLIC_URL}/project/typeslist`);
    } else {
      setIsLoading(false)
      alert("Error");
    }
  };
  const handleUpdate = async (id) => {
    const formData = {
        type_name: typeName,
    }
    setIsLoading(true)
    const res = await updateProjectType(id, formData);
    if (res.status === 200) {
      setIsLoading(false)
      navigate(`${process.env.PUBLIC_URL}/project/typeslist`);
    } else {
      setIsLoading(false)
      alert("Error occured.");
    }
  };

  const populateData = () => {
    if (location?.state) {
      settypeName(location?.state?.dataObj?.type_name);
    }
  };

  useEffect(() => {
    populateData();
  }, []);

  return (
    <>
      <Fragment>
        <Breadcrumbs
          parent="Projects"
          title="Add Project Type"
          mainTitle="Add Project Type"
        />
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardBody>
                  <Form method="POST">
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>{"Project Type"}</Label>
                          <Input
                            type="text"
                            required
                            value={typeName}
                            className="form-control"
                            placeholder="Enter Type Name"
                            onChange={(e) => settypeName(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup className="mb-0">
                          {location?.state ? (
                            <Button
                              onClick={() =>
                                handleUpdate(location?.state?.dataObj?.type_id)
                              }
                              className="me-3"
                              color="success"
                            >
                              {
                                isLoading ?
                                  <div class="spinner-border spinner-border-sm" role="status">
                                    <span class="sr-only">Loading...</span>
                                  </div>
                                  : "Update"
                              }
                            </Button>
                          ) : (
                            <Button
                              onClick={handleAdd}
                              className="me-3"
                              color="success"
                            >
                              {
                                isLoading ?
                                  <div class="spinner-border spinner-border-sm" role="status">
                                    <span class="sr-only">Loading...</span>
                                  </div>
                                  : "Submit"
                              }
                            </Button>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment>
    </>
  );
};

export default AddProjectType;
