import {
  Home,
  Airplay,
  Box,
  FolderPlus,
  Command,
  Cloud,
  BarChart,
  Users,
  ShoppingBag,
  List,
  Mail,
  MessageCircle,
  GitPullRequest,
  Monitor,
  Heart,
  Clock,
  Zap,
  CheckSquare,
  Calendar,
  Image,
  HelpCircle,
  Radio,
  Map,
  Edit,
  Sunrise,
  Package,
  Sliders,
  Layout,
  Server,
  Database,
  Search,
  File,
  Layers,
  UserCheck,
  Settings,
  PieChart,
  Aperture,
  Crosshair,
  CreditCard,
  Octagon,
  LifeBuoy,
  Coffee,
  Globe,
  Target,
  Codesandbox,
  DollarSign,
  Maximize,
  FileText,
  User,
  CheckCircle,
} from "react-feather";

export const MENUITEMS = [
  {
    menutitle: "Ants Protocol",

    Items: [
      {
        title: "Staking",
        icon: Maximize,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/staking/add_staking_pools`,
            title: "Add Pools",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/staking/staking_pools_list`,
            title: "Pools List",
            type: "link",
          },
        ],
      },
      {
        title: 'Airdrop Program',
        icon: Maximize,
        type: 'sub',
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/airdrop/add_airdrop_pools`,
            title: 'Add Airdrop',
            type: 'link',
          },
          {
            path: `${process.env.PUBLIC_URL}/airdrop/airdrop_pools_list`,
            title: 'Airdrop List',
            type: 'link',
          },
        ],
      },
      {
        title: 'Transactions',
        icon: Maximize,
        active: false,
        path: `${process.env.PUBLIC_URL}/transactions/transactionLists`,
        title: 'Transaction List',
        type: 'link',
      },
      {
        title: 'Whitelists',
        icon: Maximize,
        type: 'sub',
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/whitelist/add_whitelists`,
            title: 'Add whitelist',
            type: 'link',
          },
          {
            path: `${process.env.PUBLIC_URL}/whitelist/all_whitelists`,
            title: 'All whitelist',
            type: 'link',
          },
        ],
      },
      {
        title: 'Private Sale Info',
        icon: Maximize,
        type: 'sub',
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/privateSale/add_saleInfo`,
            title: 'Add Private Sale',
            type: 'link',
          },
          {
            path: `${process.env.PUBLIC_URL}/privateSale/get_saleInfo`,
            title: 'All Private Sale',
            type: 'link',
          },
        ],
      },
      {
        title: 'Private Sale Transaction',
        icon: Maximize,
        type: 'sub',
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/private/saleTransaction`,
            title: 'Private Sale',
            type: 'link',
          },
        ],
      },
      {
        title: "Staked Tokens ",
        icon: Maximize,
        active: false,
        path: `${process.env.PUBLIC_URL}/stakedtoken/staked_tokens_list`,
        title: "Tokens List",
        type: "link",
      },
      {
        title: "Additional Staking ",
        icon: Maximize,
        path: `${process.env.PUBLIC_URL}/additionalstaking/add_additional`,
        title: "Additional Staking ",
        type: "link",
        active: false,
      },
      {
        title: "Proposals",
        icon: Maximize,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/proposals/proposals_list`,
            title: "Proposals List",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/proposals/add_proposals`,
            title: "Add Proposals",
            type: "link",
          },
        ],
      },
      {
        title: "Notifications",
        icon: Maximize,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/notification/notifications_list`,
            title: "Notifications List",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/notification/add_notifications`,
            title: "Add Notifications",
            type: "link",
          },
        ],
      },
      {
        title: "Ants Brand",
        icon: Maximize,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/antsbrand/add_antsbrand`,
            title: "Add Ants Brand",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/antsbrand/antsbrand_list`,
            title: "Ants Brand List",
            type: "link",
          },
        ],
      },
      {
        title: "Hero Section",
        icon: Home,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/home/add_herosection`,
            title: "Add Hero Section",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/home/herosection_list`,
            title: "Hero Section List",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/home/howtovote`,
            title: "How to Vote",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/home/addWhitePaper`,
            title: "Add White Paper",
            type: "link",
          },
        ],
      },
      // {
      //   title: "Our Mission",
      //   icon: Aperture,
      //   type: "sub",
      //   active: false,
      //   children: [
      //     {
      //       path: `${process.env.PUBLIC_URL}/mission/add_missiontitle`,
      //       title: "Add Our Misison Title",
      //       type: "link",
      //     },
      //     {
      //       path: `${process.env.PUBLIC_URL}/mission/add_mission`,
      //       title: "Add Our Misison",
      //       type: "link",
      //     },
      //     {
      //       path: `${process.env.PUBLIC_URL}/mission/mission_list`,
      //       title: "Our Mission List",
      //       type: "link",
      //     },
      //   ],
      // },
      {
        title: "How Works",
        icon: Crosshair,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/works/add_howworkstitle`,
            title: "Add How Works Title",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/works/add_howworks`,
            title: "Add How Works",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/works/howworks_list`,
            title: "How Works List",
            type: "link",
          },
        ],
      },
      // {
      //   title: "Projects",
      //   icon: Package,
      //   type: "sub",
      //   active: false,
      //   children: [
      //     {
      //       path: `${process.env.PUBLIC_URL}/projects/add_category`,
      //       title: "Add Category",
      //       type: "link",
      //     },
      //     {
      //       path: `${process.env.PUBLIC_URL}/projects/category_list`,
      //       title: "Category List",
      //       type: "link",
      //     },
      //     {
      //       path: `${process.env.PUBLIC_URL}/projects/add_project`,
      //       title: "Add Project",
      //       type: "link",
      //     },
      //     {
      //       path: `${process.env.PUBLIC_URL}/projects/project_list`,
      //       title: "Project List",
      //       type: "link",
      //     },
      //   ],
      // },
      {
        title: "Projects",
        icon: Package,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/project/addCategory`,
            title: "Add Project Category",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/project/categoryList`,
            title: "Project Category List",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/project/userProjects`,
            title: "User Projects",
            type: "link",
          },
        ],
      },
      {
        title: "Project Types",
        icon: Layers,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/project/types`,
            title: "Add Project Type",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/project/typeslist`,
            title: "Project Types List",
            type: "link",
          },
        ],
      },
      {
        title: "Approved Projects",
        icon: CheckCircle,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/approved-projects`,
            title: "Approved Projects List",
            type: "link",
          },
        ],
      },
      {
        title: "Users (Companies)",
        icon: Server,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/user-list`,
            title: "Users List",
            type: "link",
          },
        ],
      },
      {
        title: "News Letter",
        icon: Mail,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/NewsLetter/addNews`,
            title: "Add News Letter",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/NewsLetter/viewNewsLetter`,
            title: "View News Letter",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/NewsLetter/emailList`,
            title: "Send News Letter",
            type: "link",
          },
        ],
      },
      {
        title: "BLOGS",
        icon: FileText,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/blogs/addBlogCategory`,
            title: "Add Category",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/blogs/blogCategoryList`,
            title: "Categories List",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/blogs/addBlog`,
            title: "Add Blogs",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/blogs/blogsList`,
            title: "Blogs List",
            type: "link",
          },
        ],
      },
      // {
      //   title: 'Create Plans',
      //   icon: Layers,
      //   type: 'sub',
      //   active: false,
      //   children: [
      //     {
      //       path: `${process.env.PUBLIC_URL}/plans/add_subscription_title`,
      //       title: 'Add Title',
      //       type: 'link',
      //     },
      //     {
      //       path: `${process.env.PUBLIC_URL}/plans/add_plans`,
      //       title: 'Add Plans',
      //       type: 'link',
      //     },
      //     {
      //       path: `${process.env.PUBLIC_URL}/plans/plans_list`,
      //       title: 'Plans List',
      //       type: 'link',
      //     },
      //   ],
      // },
      // {
      //   title: 'Cashbacks',
      //   icon: DollarSign,
      //   type: 'sub',
      //   active: false,
      //   children: [
      //     {
      //       path: `${process.env.PUBLIC_URL}/cashback/add_cashback`,
      //       title: 'Add Cashback Percentage',
      //       type: 'link',
      //     },
      //     {
      //       path: `${process.env.PUBLIC_URL}/cashback/cashback_list_percentage`,
      //       title: 'Cashback Percentage List',
      //       type: 'link',
      //     },
      //     {
      //       path: `${process.env.PUBLIC_URL}/cashback/cashbacks_list`,
      //       title: 'Cashback List',
      //       type: 'link',
      //     },
      //   ],
      // },
      // {
      //   title: 'Add Badge',
      //   icon: Codesandbox,
      //   type: 'sub',
      //   active: false,
      //   children: [
      //     {
      //       path: `${process.env.PUBLIC_URL}/badge/add_badge`,
      //       title: 'Add Badge',
      //       type: 'link',
      //     },
      //     {
      //       path: `${process.env.PUBLIC_URL}/badge/badge_list`,
      //       title: 'Badge List',
      //       type: 'link',
      //     },
      //   ],
      // },
      // {
      //   title: 'Ants Wallet',
      //   icon: CreditCard,
      //   type: 'sub',
      //   active: false,
      //   children: [
      //     {
      //       path: `${process.env.PUBLIC_URL}/wallet/add_antswallet`,
      //       title: 'Add Ants Wallet',
      //       type: 'link',
      //     },
      //     {
      //       path: `${process.env.PUBLIC_URL}/wallet/antswallet_list`,
      //       title: 'Ants Wallet List',
      //       type: 'link',
      //     },
      //   ],
      // },
      // {
      //   title: 'Ants Token',
      //   icon: CreditCard,
      //   type: 'sub',
      //   active: false,
      //   children: [
      //     {
      //       path: `${process.env.PUBLIC_URL}/token/add_antstoken`,
      //       title: 'Add Ants Token',
      //       type: 'link',
      //     },
      //     {
      //       path: `${process.env.PUBLIC_URL}/token/antstoken_list`,
      //       title: 'Ants Token List',
      //       type: 'link',
      //     },
      //   ],
      // },
      // {
      //   title: 'Ants Personal Donation',
      //   icon: CreditCard,
      //   type: 'sub',
      //   active: false,
      //   children: [
      //     {
      //       path: `${process.env.PUBLIC_URL}/app/donationSection`,
      //       title: 'Add Donation Section',
      //       type: 'link',
      //     },
      //     {
      //       path: `${process.env.PUBLIC_URL}/app/personalDonation`,
      //       title: 'Add Personal Donation',
      //       type: 'link',
      //     },
      //     {
      //       path: `${process.env.PUBLIC_URL}/app/listPersonalDonation`,
      //       title: 'List Personal Donation',
      //       type: 'link',
      //     },
      //   ],
      // },
      {
        title: "Benefits",
        icon: Octagon,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/benefits/add_benefitstitle`,
            title: "Add Benefits Title",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/benefits/add_benefits`,
            title: "Add Benefits",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/benefits/benefits_list`,
            title: "Benefits List",
            type: "link",
          },
        ],
      },
      {
        title: "Wallet Transactions",
        icon: CreditCard,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/wallet_transactions/wallet_transactions_list`,
            title: "Wallet Transactions List",
            type: "link",
          },
        ],
      },
      {
        title: "Wallet Association",
        icon: CreditCard,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/wallet_Association/add_return_percentage`,
            title: "Owners Percentage",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/wallet_Association/add_wallet_association`,
            title: "Wallet Association",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/wallet_Association/add_wallet_list`,
            title: "Wallet Association List",
            type: "link",
          },
        ],
      },
      {
        title: "Tickets",
        icon: Octagon,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/tickets/add_voting_power`,
            title: "Add Voting Power",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/tickets/add_ticket`,
            title: "Add Tickets",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/tickets/ticket_list`,
            title: "Tickets List",
            type: "link",
          },
        ],
      },
      // {
      //   title: 'Our Stats',
      //   icon: Target,
      //   type: 'sub',
      //   active: false,
      //   children: [
      //     {
      //       path: `${process.env.PUBLIC_URL}/stats/add_stats`,
      //       title: 'Add Stats',
      //       type: 'link',
      //     },
      //     {
      //       path: `${process.env.PUBLIC_URL}/stats/stats_list`,
      //       title: 'Stats List',
      //       type: 'link',
      //     },
      //   ],
      // },
      // {
      //   title: 'Our Team',
      //   icon: Coffee,
      //   type: 'sub',
      //   active: false,
      //   children: [
      //     {
      //       path: `${process.env.PUBLIC_URL}/team/add_team`,
      //       title: 'Add Team',
      //       type: 'link',
      //     },
      //     {
      //       path: `${process.env.PUBLIC_URL}/team/team_list`,
      //       title: 'Team List',
      //       type: 'link',
      //     },
      //   ],
      // },
      {
        title: "Our Partners",
        icon: Globe,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/partners/partners_title`,
            title: "Partners Title",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/partners/add_partners`,
            title: "Add Partners",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/partners/partners_list`,
            title: "Partners List",
            type: "link",
          },
        ],
      },
      {
        title: "Knowledge",
        icon: Layers,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/app/addknowledge`,
            title: "Add Knowledge",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/app/listknowledge`,
            title: "List Knowledge",
            type: "link",
          },
        ],
      },
      // {
      //   title: 'Products',
      //   icon: Layers,
      //   type: 'sub',
      //   active: false,
      //   children: [
      //     {
      //       path: `${process.env.PUBLIC_URL}/category/add_category`,
      //       title: 'Add Category',
      //       type: 'link',
      //     },
      //     {
      //       path: `${process.env.PUBLIC_URL}/category/category_list`,
      //       title: 'Category List',
      //       type: 'link',
      //     },
      //     {
      //       path: `${process.env.PUBLIC_URL}/subcategory/add_subcategory`,
      //       title: 'Add Sub Category',
      //       type: 'link',
      //     },
      //     {
      //       path: `${process.env.PUBLIC_URL}/subcategory/subcategory_list`,
      //       title: 'Sub Category List',
      //       type: 'link',
      //     },
      //     {
      //       path: `${process.env.PUBLIC_URL}/product/add_products`,
      //       title: 'Add Products',
      //       type: 'link',
      //     },
      //     {
      //       path: `${process.env.PUBLIC_URL}/product/product_list`,
      //       title: 'Product List',
      //       type: 'link',
      //     },
      //   ],
      // },
      {
        title: "SEO Information",
        icon: BarChart,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/Sco/homeSco`,
            title: "Home Page",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/Sco/blogSco`,
            title: "Blog Page",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/Sco/knowledgeSco`,
            title: "Knowledge Page",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/seo/how_it_works`,
            title: "How it works",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/seo/ngo_page`,
            title: "NGO Page",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/seo/profitable_page`,
            title: "Profitable Page",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/seo/launch_page`,
            title: "Launch Page",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/seo/governance_page`,
            title: "Governance Page",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/seo/profile_page`,
            title: "Profile Page",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/seo/create_page`,
            title: "Create Page",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/seo/explore_page`,
            title: "Explore Page",
            type: "link",
          },
        ],
      },
      {
        title: "Launch Page",
        icon: Octagon,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/launch_page/add_case_studies_statistics`,
            title: "Add Case Studies Stats",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/launch_page/case_studies_statistics_list`,
            title: "Case Studies Stats List",
            type: "link",
          },
        ],
      },
      {
        title: "Footer",
        icon: Octagon,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/footer/add_footer`,
            title: "Add Footer",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/footer/footer_list`,
            title: "Footer List",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/footer/description`,
            title: "Add Footer Description",
            type: "link",
          },
        ],
      },
      {
        title: "Email Templates",
        icon: Octagon,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/email_templates/add_email_templates`,
            title: "Add Templates",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/email_templates/email_templates_list`,
            title: "Templates List",
            type: "link",
          },
        ],
      },
    ],
  },

  {
    menutitle: "Settings",
    Items: [
      {
        title: "Ants Buttons",
        icon: Package,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/ants_button/add_ants_button`,
            title: "Button Background",
            type: "link",
          },
        ],
      },
      // {
      //   title: 'Users',
      //   icon: Users,
      //   type: 'sub',
      //   active: false,
      //   children: [
      //     {
      //       path: `${process.env.PUBLIC_URL}/users/users_list`,
      //       title: 'Users List',
      //       type: 'link',
      //     },
      //   ],
      // },
      // {
      //   title: 'Slider',
      //   icon: Image,
      //   type: 'sub',
      //   active: false,
      //   children: [
      //     {
      //       path: `${process.env.PUBLIC_URL}/slider/addSlider`,
      //       title: 'Add Slider',
      //       type: 'link',
      //     },
      //     {
      //       path: `${process.env.PUBLIC_URL}/slider/viewSlider`,
      //       title: 'View Slider',
      //       type: 'link',
      //     },
      //   ],
      // },
      {
        title: "Ants Stats",
        icon: PieChart,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/stats/addStats`,
            title: "Update Stats",
            type: "link",
          },
        ],
      },
    ],
  },
  /*
  {
    menutitle: "General",
    menucontent: "Dashboards,Widgets",
    Items: [
      {
        title: "Home Page",
        icon: Home,
        type: "sub",
        active: false,
        children: [
          { path: `/dashboard/slider`, title: "Slider Section", type: "link" },
          { path: `/dashboard/sliderlist`, title: "Slider List", type: "link" },
        ],
      },
      //Basic setting
      {
        title: "Basic Setting",
        icon: Home,
        type: "sub",
        active: false,
        children: [
          { path: `/dashboard/add-logo`, title: "Add Logo", type: "link" },
          {
            path: `/dashboard/add-social-links`,
            title: "Add SocialLinks",
            type: "link",
          },
          {
            path: `/dashboard/add-web-title`,
            title: "Add WebsiteTitle",
            type: "link",
          },
          {
            path: `/dashboard/add-fav-icon`,
            title: "Add FavIcon",
            type: "link",
          },
        ],
      },

      //Aboout us
      {
        title: "About Us",
        icon: Home,
        type: "sub",
        active: false,
        children: [
          {
            path: `/dashboard/aboutus-data`,
            title: "About Us Text",
            type: "link",
          },
          { path: `/dashboard/addcrew`, title: "Add Crew", type: "link" },
          { path: `/dashboard/crewlist`, title: " Crew List", type: "link" },
        ],
      },
      //Dao Form
      {
        title: "Dao",
        icon: Home,
        type: "sub",
        active: false,
        children: [
          {
            path: `/dashboard/deoform`,
            title: "Dao",
            type: "link",
          },
        ],
      },
      //FAQ
      {
        title: "FAQ",
        icon: HelpCircle,
        type: "sub",
        active: false,
        children: [
          {
            path: `/dashboard/faq`,
            title: "FAQ",
            type: "link",
          },
          {
            path: `/dashboard/faqlist`,
            title: "FAQList",
            type: "link",
          },
        ],
      },
      //CRM
      {
        title: "CRM",
        icon: Home,
        type: "sub",
        active: false,
        children: [
          {
            path: `/dashboard/crm-form`,
            title: "Add Campaigns",
            type: "link",
          },
          {
            path: `/dashboard/crm-list`,
            title: "CRM List",
            type: "link",
          },
          {
            path: `/dashboard/make-group`,
            title: "Make Groups",
            type: "link",
          },
          {
            path: `/dashboard/assign-email`,
            title: "Assign Email",
            type: "link",
          },
          {
            path: `/dashboard/view-email`,
            title: "View Email",
            type: "link",
          },
          {
            path: `/dashboard/run-compagin`,
            title: "Run Compagins",
            type: "link",
          },
          {
            path: `/dashboard/log-screen`,
            title: "Log Screen",
            type: "link",
          },
        ],
      },
      {
        title: "Dashboard",
        icon: Home,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/dashboard/default`,
            title: "Default",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/dashboard/ecommerce`,
            title: "Ecommerce",
            type: "link",
          },
        ],
      },
      {
        title: "Widgets",
        icon: Airplay,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/widgets/general`,
            title: "General",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/widgets/chart`,
            title: "Chart",
            type: "link",
          },
        ],
      },
      {
        title: "Page layout",
        icon: Layout,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/page-layout/footer-light`,
            title: "Footer Light",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/page-layout/footer-dark`,
            title: "Footer Dark",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/page-layout/footer-fixed`,
            title: "Footer Fixed",
            type: "link",
          },
        ],
      },
    ],
  },
  {
    menutitle: "Components",
    Items: [
      {
        title: "Ui Kits",
        icon: Box,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/ui-kits/typography`,
            type: "link",
            title: "Typography",
          },
          {
            path: `${process.env.PUBLIC_URL}/ui-kits/avatars`,
            type: "link",
            title: "Avatars",
          },
          {
            path: `${process.env.PUBLIC_URL}/ui-kits/helper-classes`,
            type: "link",
            title: "Helper Classes",
          },
          {
            path: `${process.env.PUBLIC_URL}/ui-kits/grid`,
            type: "link",
            title: "Grid",
          },
          {
            path: `${process.env.PUBLIC_URL}/ui-kits/tag-pills`,
            type: "link",
            title: "Tag & Pills",
          },
          {
            path: `${process.env.PUBLIC_URL}/ui-kits/progress`,
            type: "link",
            title: "Progress",
          },
          {
            path: `${process.env.PUBLIC_URL}/ui-kits/modal`,
            type: "link",
            title: "Modal",
          },
          {
            path: `${process.env.PUBLIC_URL}/ui-kits/alert`,
            type: "link",
            title: "Alert",
          },
          {
            path: `${process.env.PUBLIC_URL}/ui-kits/popover`,
            type: "link",
            title: "Popover",
          },
          {
            path: `${process.env.PUBLIC_URL}/ui-kits/tooltip`,
            type: "link",
            title: "Tooltip",
          },
          {
            path: `${process.env.PUBLIC_URL}/ui-kits/spinners`,
            type: "link",
            title: "Spinners",
          },
          {
            path: `${process.env.PUBLIC_URL}/ui-kits/dropdown`,
            type: "link",
            title: "Dropdown",
          },
          {
            path: `${process.env.PUBLIC_URL}/ui-kits/accordion`,
            type: "link",
            title: "Accordion",
          },
          {
            type: "sub",
            title: "Tabs",
            children: [
              {
                path: `${process.env.PUBLIC_URL}/ui-kits/tab-bootstrap`,
                type: "link",
                title: "Tab Bootstrap",
              },
              {
                path: `${process.env.PUBLIC_URL}/ui-kits/line-tabs`,
                type: "link",
                title: "Line Tabs",
              },
            ],
          },
          {
            path: `${process.env.PUBLIC_URL}/ui-kits/navs`,
            type: "link",
            title: "Navs",
          },
          {
            path: `${process.env.PUBLIC_URL}/ui-kits/shadow`,
            type: "link",
            title: "Shadow",
          },
          {
            path: `${process.env.PUBLIC_URL}/ui-kits/lists`,
            type: "link",
            title: "Lists",
          },
        ],
      },
      {
        title: "Bonus Ui",
        icon: FolderPlus,
        type: "sub",
        badge1: true,
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/bonus-ui/scrollable`,
            title: "Scrollable ",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/bonus-ui/tree-view`,
            title: "Tree View ",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/bonus-ui/bootstrap-notify`,
            title: "Bootstrap Notify ",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/bonus-ui/rating`,
            title: "Rating",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/bonus-ui/dropzone`,
            title: "Dropzone",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/bonus-ui/tour`,
            title: "Tour ",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/bonus-ui/sweet-alert`,
            title: "SweetAlert ",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/bonus-ui/carousel`,
            title: "Owl Carousel",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/bonus-ui/ribbons`,
            title: "Ribbons",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/bonus-ui/pagination`,
            title: "Pagination",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/bonus-ui/steps`,
            title: "Steps",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/bonus-ui/breadcrumb`,
            title: "Breadcrumb ",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/bonus-ui/rangeSlider`,
            title: "Range Slider ",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/bonus-ui/imageCropper`,
            title: "Image Cropper ",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/bonus-ui/stickyNotes`,
            title: "Sticky ",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/bonus-ui/dragNDropComp`,
            title: "Drag and Drop ",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/bonus-ui/image-upload`,
            title: "Upload",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/bonus-ui/card/basicCards`,
            title: "Basic Card ",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/bonus-ui/card/creativeCards`,
            title: "Creative Card ",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/bonus-ui/card/tabCard`,
            title: "Tabbed Card ",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/bonus-ui/card/draggingCards`,
            title: "Draggable Card",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/bonus-ui/timelines/timeline1`,
            title: "Timeline",
            type: "link",
          },
        ],
      },
      {
        title: "Icons",
        icon: Command,
        path: `${process.env.PUBLIC_URL}/icons/flagIcons`,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/icons/flagIcons`,
            title: "Flag Icon",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/icons/fontAwsomeIcon`,
            title: "Fontawesome Icon ",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/icons/icoIcons`,
            title: "Ico Icon ",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/icons/themifyIcons`,
            title: "Themify Icon ",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/icons/featherIcons`,
            title: "Feather Icon ",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/icons/weatherIcons`,
            title: "Whether Icon ",
            type: "link",
          },
        ],
      },
      {
        title: "Buttons",
        icon: Cloud,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/buttons/default-btn`,
            title: "Default Style ",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/buttons/flatBtn`,
            title: "Flat Style",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/buttons/edgeBtn`,
            title: "Edge Style",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/buttons/raisedBtn`,
            title: "Raised Style",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/buttons/groupBtn`,
            title: "Button Group",
            type: "link",
          },
        ],
      },
      {
        title: "Charts",
        icon: BarChart,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/charts/apexCharts`,
            type: "link",
            title: "Apex Chart",
          },
          {
            path: `${process.env.PUBLIC_URL}/charts/googleChart`,
            type: "link",
            title: "Google Chart",
          },
          {
            path: `${process.env.PUBLIC_URL}/charts/knobChart`,
            type: "link",
            title: "Knob Chart",
          },
          {
            path: `${process.env.PUBLIC_URL}/charts/chartJs`,
            type: "link",
            title: "Chartjs Chart",
          },
          {
            path: `${process.env.PUBLIC_URL}/charts/chartist`,
            type: "link",
            title: "Chartist Chart",
          },
        ],
      },
    ],
  },
  {
    menutitle: "Forms",
    Items: [
      {
        title: " Form Controls ",
        icon: Sliders,
        type: "sub",
        children: [
          {
            title: "Form Validation",
            type: "link",
            path: `${process.env.PUBLIC_URL}/forms/formValidation`,
          },
          {
            title: "Basic Input",
            type: "link",
            path: `${process.env.PUBLIC_URL}/forms/baseInput`,
          },
          {
            title: "Checkbox & Radio",
            type: "link",
            path: `${process.env.PUBLIC_URL}/forms/radio-checkbox`,
          },
          {
            title: "Input Groups",
            type: "link",
            path: `${process.env.PUBLIC_URL}/forms/inputGroup`,
          },
          {
            title: "Mega Option",
            type: "link",
            path: `${process.env.PUBLIC_URL}/forms/megaOptions`,
          },
        ],
      },
      {
        title: "Form Widgets",
        icon: Package,
        type: "sub",
        children: [
          {
            title: "Datepicker",
            type: "link",
            path: `${process.env.PUBLIC_URL}/form-widget/datepicker`,
          },
          {
            title: "Timepicker",
            type: "link",
            path: `${process.env.PUBLIC_URL}/form-widget/timepicker`,
          },
          {
            title: "Typeahead",
            type: "link",
            path: `${process.env.PUBLIC_URL}/form-widget/typeahead`,
          },
          {
            title: "Date Time Picker",
            type: "link",
            path: `${process.env.PUBLIC_URL}/form-widget/datetimepicker`,
          },
          {
            title: "Date Rangepicker",
            type: "link",
            path: `${process.env.PUBLIC_URL}/form-widget/rangepicker`,
          },
          {
            title: "TouchSpin",
            type: "link",
            path: `${process.env.PUBLIC_URL}/form-widget/touchspin`,
          },
          {
            title: "Select 2",
            type: "link",
            path: `${process.env.PUBLIC_URL}/form-widget/select2`,
          },
          {
            title: "Switch",
            type: "link",
            path: `${process.env.PUBLIC_URL}/form-widget/switch`,
          },
          {
            title: "ClipBoard",
            type: "link",
            path: `${process.env.PUBLIC_URL}/form-widget/clipBoard`,
          },
        ],
      },
      {
        title: "Form Layout",
        icon: Layout,
        type: "sub",
        children: [
          {
            path: `${process.env.PUBLIC_URL}/form-layout/formDefault`,
            title: "Form Default",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/form-layout/formWizard`,
            title: "Form Wizard",
            type: "link",
          },
        ],
      },
    ],
  },
  {
    menutitle: "Table",
    Items: [
      {
        title: "Bootstrap Table ",
        icon: Server,
        type: "sub",
        children: [
          {
            title: "Basic Table",
            type: "link",
            path: `${process.env.PUBLIC_URL}/table/basic`,
          },
          {
            title: "Sizing Table",
            type: "link",
            path: `${process.env.PUBLIC_URL}/table/sizing`,
          },
          {
            title: "Border Table",
            type: "link",
            path: `${process.env.PUBLIC_URL}/table/border`,
          },
          {
            title: "Styling Table",
            type: "link",
            path: `${process.env.PUBLIC_URL}/table/styling`,
          },
        ],
      },
      {
        title: "Data Tables",
        icon: Database,
        type: "link",
        path: `${process.env.PUBLIC_URL}/table/data-table`,
      },
    ],
  },
  {
    menutitle: "Applications",
    Items: [
      {
        title: "Project",
        icon: Box,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/app/project/project-list`,
            type: "link",
            title: "Project List",
          },
          {
            path: `${process.env.PUBLIC_URL}/app/project/new-project`,
            type: "link",
            title: "Create New",
          },
        ],
      },
      {
        title: "Ecommerce",
        icon: ShoppingBag,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/app/ecommerce/product`,
            title: "Product",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/app/ecommerce/product-page/1`,
            title: "Product Page",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/app/ecommerce/product-list`,
            title: "Product List",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/app/ecommerce/payment-details`,
            title: "Payment Detail",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/app/ecommerce/orderhistory`,
            title: "Order History",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/app/ecommerce/pricing`,
            title: "Pricing",
            type: "link",
          },
        ],
      },
      {
        title: "Users",
        icon: Users,
        path: `${process.env.PUBLIC_URL}/app/users/userProfile`,
        type: "sub",
        bookmark: true,
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/app/users/userProfile`,
            type: "link",
            title: "Users Profile ",
          },
          {
            path: `${process.env.PUBLIC_URL}/app/users/userEdit`,
            type: "link",
            title: "Users Edit",
          },
          {
            path: `${process.env.PUBLIC_URL}/app/users/userCards`,
            type: "link",
            title: "Users Cards",
          },
        ],
      },
      {
        title: "Calender",
        icon: Calendar,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/app/calendar/basic-calendar`,
            bookmark: true,
            type: "link",
            title: "Calender",
          },
          {
            path: `${process.env.PUBLIC_URL}/app/calendar/draggable-calendar`,
            type: "link",
            title: "Draggable",
          },
        ],
      },
      {
        icon: List,
        type: "sub",
        title: "Contacts",
        children: [
          {
            path: `${process.env.PUBLIC_URL}/app/contact-firebase`,
            type: "link",
            title: "Contact with Firebase",
          },
          {
            path: `${process.env.PUBLIC_URL}/app/contact`,
            type: "link",
            title: "Contact",
          },
        ],
      },
      {
        bookmark: true,
        icon: MessageCircle,
        title: "Chat",
        type: "sub",
        children: [
          {
            path: `${process.env.PUBLIC_URL}/app/chat-app`,
            type: "link",
            title: "Chat-app",
          },
          {
            path: `${process.env.PUBLIC_URL}/app/chat-video-app`,
            type: "link",
            title: "Chat-video-app",
          },
        ],
      },
      {
        icon: Mail,
        title: "Email-app",
        type: "sub",
        children: [
          {
            path: `${process.env.PUBLIC_URL}/app/email/mailbox`,
            type: "link",
            title: "Mail Box",
          },
          {
            path: `${process.env.PUBLIC_URL}/app/email/readmail`,
            type: "link",
            title: "Read Mail",
          },
          {
            path: `${process.env.PUBLIC_URL}/app/email/compose`,
            type: "link",
            title: "Compose",
          },
        ],
      },
      {
        path: `${process.env.PUBLIC_URL}/app/file-manager`,
        icon: GitPullRequest,
        title: "File Manager",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/app/kanban-board`,
        icon: Monitor,
        badge: "badge badge-info text-light mb-0",
        badgetxt: "latest",
        title: "Kanban Board",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/app/bookmark`,
        icon: Heart,
        type: "link",
        title: "Bookmark",
      },
      {
        path: `${process.env.PUBLIC_URL}/app/task`,
        icon: CheckSquare,
        type: "link",
        title: "Task",
      },
      {
        path: `${process.env.PUBLIC_URL}/app/social-app`,
        bookmark: true,
        icon: Zap,
        type: "link",
        title: "Social App",
      },
      {
        path: `${process.env.PUBLIC_URL}/app/todo`,
        icon: Clock,
        type: "link",
        title: "To-Do",
      },
      {
        path: `${process.env.PUBLIC_URL}/app/todo-firebase`,
        icon: Clock,
        type: "link",
        title: "To-Do-Firebase",
      },
      {
        path: `${process.env.PUBLIC_URL}/app/search-result`,
        icon: Search,
        type: "link",
        title: "Seach Result",
      },
    ],
  },
  {
    menutitle: "Pages",
    Items: [
      {
        title: "Sample Page",
        icon: File,
        type: "link",
        path: `${process.env.PUBLIC_URL}/pages/sample-page`,
      },
      {
        title: "Others",
        icon: Layers,
        type: "sub",
        children: [
          {
            title: "Error Pages",
            type: "sub",
            children: [
              {
                title: "Error Page 1",
                type: "link",
                path: `${process.env.PUBLIC_URL}/pages/error/error-page1`,
              },
              {
                title: "Error Page 2",
                type: "link",
                path: `${process.env.PUBLIC_URL}/pages/error/error-page2`,
              },
              {
                title: "Error Page 3",
                type: "link",
                path: `${process.env.PUBLIC_URL}/pages/error/error-page3`,
              },
              {
                title: "Error Page 4",
                type: "link",
                path: `${process.env.PUBLIC_URL}/pages/error/error-page4`,
              },
            ],
          },
          {
            title: "Authentication",
            type: "sub",
            children: [
              {
                title: "Login Simple",
                type: "link",
                path: `${process.env.PUBLIC_URL}/pages/authentication/login-simple`,
              },
              {
                title: "Login with bg image",
                type: "link",
                path: `${process.env.PUBLIC_URL}/pages/authentication/login-bg-img`,
              },
              {
                title: "Login with image two",
                type: "link",
                path: `${process.env.PUBLIC_URL}/pages/authentication/login-img`,
              },
              {
                title: "Login with validation",
                type: "link",
                path: `${process.env.PUBLIC_URL}/pages/authentication/login-validation`,
              },
              {
                title: "Login with tooltip",
                type: "link",
                path: `${process.env.PUBLIC_URL}/pages/authentication/login-tooltip`,
              },
              {
                title: "Login with sweetalert",
                type: "link",
                path: `${process.env.PUBLIC_URL}/pages/authentication/login-sweetalert`,
              },
              {
                title: "Register Simple",
                type: "link",
                path: `${process.env.PUBLIC_URL}/pages/authentication/register-simple`,
              },
              {
                title: "Register with Bg Image",
                type: "link",
                path: `${process.env.PUBLIC_URL}/pages/authentication/register-bg-img`,
              },
              {
                title: "Register with Bg Video",
                type: "link",
                path: `${process.env.PUBLIC_URL}/pages/authentication/register-video`,
              },
              {
                title: "Unloack User",
                type: "link",
                path: `${process.env.PUBLIC_URL}/pages/authentication/unlock-user`,
              },
              {
                title: "Forget Password",
                type: "link",
                path: `${process.env.PUBLIC_URL}/pages/authentication/forget-pwd`,
              },
              {
                title: "Creat Password",
                type: "link",
                path: `${process.env.PUBLIC_URL}/pages/authentication/create-pwd`,
              },
              {
                title: "Maintenance",
                type: "link",
                path: `${process.env.PUBLIC_URL}/pages/authentication/maintenance`,
              },
            ],
          },
          {
            title: "Coming Soon",
            type: "sub",
            children: [
              {
                title: "Coming Simple",
                type: "link",
                path: `${process.env.PUBLIC_URL}/pages/coming/coming-simple`,
              },
              {
                title: "Coming with Bg Video",
                type: "link",
                path: `${process.env.PUBLIC_URL}/pages/coming/coming-bg-video`,
              },
              {
                title: "Coming with bg Image",
                type: "link",
                path: `${process.env.PUBLIC_URL}/pages/coming/coming-bg-img`,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    menutitle: "Miscellaneous",
    Items: [
      {
        title: "Gallery",
        icon: Image,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/app/gallery/imageGallery`,
            title: "Gallery Grid ",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/app/gallery/imageWithDesc`,
            title: "Gallery Grid  Desc ",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/app/gallery/mesonryGallery`,
            title: "Masonry Gallery",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/app/gallery/mesonryDesc`,
            title: "Masonry With Desc",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/app/gallery/imageHover`,
            title: "Hover Effect",
            type: "link",
          },
        ],
      },
      {
        title: "Blog",
        icon: Edit,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/app/blog/blogDetail`,
            title: "Blog Details",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/app/blog/blogSingle`,
            title: "Blog Single",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/app/blog/blogPost`,
            title: "Add Post",
            type: "link",
          },
        ],
      },
      {
        title: "Job Search",
        icon: UserCheck,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/app/jobSearch/cardView`,
            title: "Cards View",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/app/jobSearch/job-list`,
            title: "List View",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/app/jobSearch/job-detail`,
            title: "Job Details",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/app/jobSearch/job-apply`,
            title: "Apply",
            type: "link",
          },
        ],
      },
      {
        title: "Learning",
        icon: Radio,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/app/learning/learning-list`,
            title: "Learning List",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/app/learning/learning-detail`,
            title: "Detailed Course",
            type: "link",
          },
        ],
      },
      {
        title: "Maps",
        icon: Map,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/app/map/googleMap`,
            type: "link",
            title: "Google Maps ",
          },
          {
            path: `${process.env.PUBLIC_URL}/app/map/leaflet-map`,
            type: "link",
            title: "Leaflet Maps",
          },
        ],
      },
      {
        title: "Editor",
        icon: Edit,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/editor/ckEditor`,
            type: "link",
            title: "CK  Editor",
          },
          {
            path: `${process.env.PUBLIC_URL}/editor/mdeEditor`,
            type: "link",
            title: "MDE Editor",
          },
          {
            path: `${process.env.PUBLIC_URL}/editor/ace-code-editor`,
            type: "link",
            title: "ACE code Editor ",
          },
        ],
      },

      {
        path: `${process.env.PUBLIC_URL}/app/faq`,
        icon: HelpCircle,
        type: "link",
        active: false,
        title: "FAQ",
      },
      {
        icon: Sunrise,
        type: "sub",
        active: false,
        title: "Knowledgebase",
        children: [
          {
            path: `${process.env.PUBLIC_URL}/app/knowledgebase`,
            title: "Knowledgebase",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/app/knowledgebase-category`,
            title: "Knowledgebase Category",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/app/knowledgebase-detail`,
            title: "Knowledgebase Detail",
            type: "link",
          },
        ],
      },
      {
        path: `${process.env.PUBLIC_URL}/app/support-ticket`,
        icon: Users,
        type: "link",
        active: false,
        title: "Support Ticket",
      },
    ],
  },
  */
];
