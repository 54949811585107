import React, { Fragment, useState, useEffect } from 'react'
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
} from "reactstrap";
import { Breadcrumbs } from '../../../../AbstractElements';
import CKEditors from 'react-ckeditor-component';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import { useNavigate,useParams } from 'react-router-dom';
export default function UpdateNewsLetter() {
    const {id} = useParams();
    const navigate = useNavigate();
    const [templateName, settemplateName] = useState('')
    const [subject, setsubject] = useState('')
    const [description, setdescription] = useState('')
    const [isLoading, setisLoading] = useState(false)

    const handleUpdate =()=>{
        if(!templateName){
            return showError("enter the Template Name");
        }
        if(!subject){
            return showError("enter the Subject");
        }
        if(!description){
            return showError("enter the News");
        }
        function showError(fieldName) {
            toast.error(`Please ${fieldName}`, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        setisLoading(true)
        const formData = {template_Name:templateName, subject:subject, message:description}

        axios.put(`${global.backendUrl}/newsLetterTemplate/update/${id}`,formData)
            .then((response) => {
                toast.success(`News Letter Updated Successfully`, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setisLoading(false)
                navigate("/NewsLetter/viewNewsLetter")
            }).catch((err) => {
                console.log(err)
                setisLoading(false)
            })
    }

    useEffect(() => {
        axios.get(`${global.backendUrl}/newsLetterTemplate/get`)
            .then((response) => {
                const filteredData = response.data.filter((t)=>t.id == id)
                settemplateName(filteredData[0].template_Name)
                setsubject(filteredData[0].subject)
                setdescription(filteredData[0].message)
            }).catch((err) => {
                console.log(err)
            })
    }, [])
    return (
        <>
            <Fragment>
                <Breadcrumbs
                    parent="Projects"
                    title="Update News Letter"
                    mainTitle="Update News Letter"
                />
                <Container fluid={true}>
                    <Row>
                        <Col sm="12">
                            <Card>
                                <CardBody>
                                    <Form method="POST">
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label>{"Template Name"}</Label>
                                                    <Input
                                                        type="text"
                                                        required
                                                        value={templateName}
                                                        className="form-control"
                                                        placeholder="Enter the Template Name"
                                                        onChange={(e) => settemplateName(e.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label>{"Subject"}</Label>
                                                    <Input
                                                        type="text"
                                                        required
                                                        value={subject}
                                                        className="form-control"
                                                        placeholder="Enter the Subject"
                                                        onChange={(e) => setsubject(e.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label>{"Email Message"}</Label>
                                                    <CKEditors
                                                        activeclassName="p10"
                                                        content={description}
                                                        events={{
                                                            'change': e => setdescription(e.editor.getData())
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup className="mb-0">
                                                    <Button
                                                        onClick={handleUpdate}
                                                        className="me-3"
                                                        color="success"
                                                        disabled={isLoading}
                                                    >
                                                        {"Update"}
                                                    </Button>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Form>
                                </CardBody>
                            </Card>

                        </Col>
                    </Row>
                </Container>
            </Fragment>
        </>
    )
}
