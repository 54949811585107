import React, { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Breadcrumbs } from '../../../AbstractElements';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { insertAntsFooter, updateAntsFooter } from '../../../api/api';
import Dropzone from 'react-dropzone-uploader';

const AntsFooter = () => {
  const [footerlink, setFooterLink] = useState('');
  const [linkname, setLinkName] = useState('');

  const navigate = useNavigate();
  const location = useLocation();

  const handleAdd = async () => {
    // const formData = new FormData();
    // formData.append('linkName', linkname);
    // formData.append('footerLink', footerlink);
    const formData = {
      footer_link_name: linkname,
      footer_link: footerlink,
    };

    const res = await insertAntsFooter(formData);
    if (res.status === 200) {
      navigate(`${process.env.PUBLIC_URL}/footer/footer_list`);
    } else {
      alert('Error');
    }
  };
  const handleUpdate = async (id) => {
    // const formData = new FormData();
    // formData.append('linkName', linkname);
    // formData.append('footerLink', footerlink);
    const formData = {
      footer_link_name: linkname,
      footer_link: footerlink,
    };

    const res = await updateAntsFooter(id, formData);
    if (res.status === 200) {
      navigate(`${process.env.PUBLIC_URL}/footer/footer_list`);
    } else {
      alert('Error occured.');
    }
  };

  const populateData = () => {
    if (location?.state) {
      setLinkName(location?.state?.dataObj?.footer_link_name);
      setFooterLink(location?.state?.dataObj?.footer_link);
    }
  };

  useEffect(() => {
    populateData();
  }, []);

  return (
    <Fragment>
      <Breadcrumbs
        parent="Ants Footer Socials"
        title="Add Footer Socials"
        mainTitle="Add Footer Socials"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Form method="POST">
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{'Social Name'}</Label>
                        <Input
                          onChange={(e) => setLinkName(e.target.value)}
                          value={linkname}
                          type="text"
                          className="form-control"
                          required
                          placeholder="Enter Name"
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>{'Social Url'}</Label>
                        <Input
                          onChange={(e) => setFooterLink(e.target.value)}
                          value={footerlink}
                          type="text"
                          className="form-control"
                          required
                          placeholder="Enter Url"
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <FormGroup className="mb-0">
                        {location?.state ? (
                          <Button
                            onClick={() =>
                              handleUpdate(location?.state?.dataObj?.footer_id)
                            }
                            className="me-3"
                            color="success"
                          >
                            {'Update'}
                          </Button>
                        ) : (
                          <Button
                            onClick={handleAdd}
                            className="me-3"
                            color="success"
                          >
                            {'Submit'}
                          </Button>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default AntsFooter;
