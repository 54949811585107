import React, { Fragment, useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  Form,
  FormGroup,
  Label,
} from "reactstrap";
import { Breadcrumbs } from "../../../AbstractElements";
import { getRoadmapTitle, updateRoadmapTitle } from "../../../api/api";

const AntsRoadmapTitle = () => {
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [idState, setIdState] = useState([]);

  useEffect(() => {
    getRoadmapTitle()
      .then((res) => {
        setTitle(res?.data[0].howworks_title);
        setText(res?.data[0].howworks_tag);
        setIdState(res?.data[0].howworks_id);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const updateData = async (e) => {
    e.preventDefault();
    const formData = {
      howworks_title: title,
      howworks_tag: text,
    };

    await updateRoadmapTitle(idState, formData);
    alert(`Services Title Updated!`);
  };

  return (
    <>
      <Fragment>
        <Breadcrumbs
          parent="How Works"
          title="How Works Section"
          mainTitle="How Works Section"
        />
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardBody>
                  <Form method="post">
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>{"Title"}</Label>
                          <input
                            placeholder="Title"
                            className="form-control"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>{"Tag Line"}</Label>
                          <input
                            placeholder="Enter Tag Line"
                            className="form-control"
                            value={text}
                            onChange={(e) => setText(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup className="mb-0">
                          <Button
                            type="submit"
                            onClick={updateData}
                            color="success"
                          >
                            Update
                          </Button>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment>
    </>
  );
};

export default AntsRoadmapTitle;
