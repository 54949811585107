import React, { Fragment, useEffect, useState } from "react";
import "react-dropzone-uploader/dist/styles.css";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { Breadcrumbs } from "../../../../AbstractElements";
import { insertStaking } from "../../../../api/api";
import { editStaking } from "../../../../api/api";
import toast from 'react-hot-toast';

const AddStakingPools = () => {
  const [title,setTitle]=useState('')
  const [period, setLockPeriod] = useState("");
  const [APY, setAPY] = useState("");
  const [maxPoolSize, setMaxPoolSize] = useState("");
  const [status, setStatus] = useState("Active");
  const [desc,setDesc]=useState('')

  const navigate = useNavigate();
  const location = useLocation();

  const handleAdd = async () => {
    if(!title ||!period || !APY || !maxPoolSize || !desc ){
      toast.error("Please enter all fields")
return
    }
    const formData = {
      staking_title: title,
      staking_description: desc,
      staking_period: period,
      staking_apy: APY,
      staking_pool_size: maxPoolSize,
      staking_status: status,
    };

    const res = await insertStaking(formData);
    
    if (res.status === 200) {
      navigate(`${process.env.PUBLIC_URL}/staking/staking_pools_list`);
    
    } else {
      toast.error("Error occure in adding data")

    }
  };



  const handleUpdate = async (id) => {
    const formData = {
      staking_title: title,
      staking_description: desc,
      staking_period: period,
      staking_apy: APY,
      staking_pool_size: maxPoolSize,
      staking_status: status,
    };

    const res = await editStaking(id, formData);
    if (res.status === 200) {
      navigate(`${process.env.PUBLIC_URL}/staking/staking_pools_list`);
    } else {
      toast.error("Error occure in updating data")
    }
  };

  const populateData = () => {
    if (location?.state?.dataObj) {
      const { staking_title,staking_description,staking_period, staking_apy, staking_pool_size, staking_status } = location.state.dataObj;
      setLockPeriod(staking_period);
      setAPY(staking_apy);
      setMaxPoolSize(staking_pool_size);
      setStatus(staking_status);
      setTitle(staking_title);
      setDesc(staking_description)
    }
  };
  

  useEffect(() => {
    populateData();
  }, []);

  return (
    <Fragment>
      <Breadcrumbs parent="Home" title="Add Pools" mainTitle="Add Pools" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Form method="POST">
                  <Row>
                    <Col lg={6}>
                      <FormGroup>
                        <Label>{"Title"}</Label>
                        <Input
                          onChange={(e) => setTitle(e.target.value)}
                          value={title}
                          type="text"
                          className="form-control"
                          required
                          placeholder="Enter Title"
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={6}>
                      <FormGroup>
                        <Label>{"Lock Period (Days)"}</Label>
                        <Input
                          onChange={(e) => setLockPeriod(e.target.value)}
                          value={period}
                          type="number"
                          className="form-control"
                          required
                          placeholder="Enter Lock Period"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <FormGroup>
                        <Label>{"APY"}</Label>
                        <Input
                          onChange={(e) => setAPY(e.target.value)}
                          value={APY}
                          type="number"
                          className="form-control"
                          required
                          placeholder="Enter APY"
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={6}>
                      <FormGroup>
                        <Label>
                          {"Pool Size (Maximum tokens can be staked?)"}
                        </Label>
                        <Input
                          onChange={(e) => setMaxPoolSize(e.target.value)}
                          value={maxPoolSize}
                          type="number"
                          className="form-control"
                          required
                          placeholder="Enter Pool Size"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <FormGroup>
                        <Label>{"Description"}</Label>
                        <Input
                          type="text"
                          onChange={(e) => setDesc(e.target.value)}
                          value={desc}
                          className="form-control"
                       />
                      </FormGroup>
                    </Col>
                    <Col lg={6}>
                      <FormGroup>
                        <Label>{"Status"}</Label>
                        <Input
                          type="select"
                          onChange={(e) => setStatus(e.target.value)}
                          value={status}
                          className="form-control"
                        >
                          <option value="Active">Active</option>
                          <option value="Inactive">Inactive</option>
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup className="mb-0">
                        {location?.state ? (
                          <Button
                          onClick={() =>
                            handleUpdate(location?.state?.dataObj?.staking_id)
                          }
                            className="me-3 poin"
                            color="success"
                          >
                            {"Update"}
                          </Button>
                        ) : (
                          <Button
                            onClick={handleAdd}
                            className="me-3"
                            color="success"
                          >
                            {"Submit"}
                          </Button>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default AddStakingPools;
