// dashbaord
import Slidersection from "../Components/Application/Homepage/Slidersection";
import SliderList from "../Components/Application/Homepage/SliderList/SliderList";
import Default from "../Components/Dashboard/Default";
import Ecommerce from "../Components/Dashboard/Ecommerce";
import Addlogo from "../Components/Application/BasicSetting/Addlogo";
import AddSocial_Links from "../Components/Application/BasicSetting/AddSocial_Links";
import AddwebsiteTitle from "../Components/Application/BasicSetting/AddwebsiteTitle";
import AddFavicon from "../Components/Application/BasicSetting/AddFavicon";
import AboutUs_page from "../Components/Application/AboutUsPage/AboutUs_page";
import AddCrew from "../Components/Application/AboutUsPage/Add Crew";
import Daoform from "../Components/Application/Dao/Daoform";
import Faq from "../Components/Application/FrequentlyAskedQ/Faq";
import FaqList from "../Components/Application/FrequentlyAskedQ/FaqList";
import AddCrmForm from "../Components/Application/CRM/AddCrmForm";
import CrmList from "../Components/Application/CRM/CrmList";
import MakeGroups from "../Components/Application/CRM/MakeGroup/MakeGroups";
import AssignEmailToGroups from "../Components/Application/CRM/MakeGroup/AssignEmailGroups/AssignEmailToGroups";
import ViewEmails from "../Components/Application/CRM/ViewEmails/ViewEmails";
import RunCompagins from "../Components/Application/CRM/RunCompagins/RunCompagins";
import LogScreen from "../Components/Application/CRM/LogScreen/LogScreen";

//widgets
import Chart from "../Components/Widgets/Chart";
import General from "../Components/Widgets/General";

//page-layout
import FooterLight from "../Components/PageLayout/FooterLight";
import FooterFixed from "../Components/PageLayout/FooterFixed";
import FooterDark from "../Components/PageLayout/FooterDark";
//ui-kits
import Typography from "../Components/UiKits/Typography";
import Avatars from "../Components/UiKits/Avtars";
import HelperClasses from "../Components/UiKits/HelperClass";
import Grid from "../Components/UiKits/Grid";
import TagAndPills from "../Components/UiKits/TagAndPills";
import Progressbars from "../Components/UiKits/Progress";
import Modals from "../Components/UiKits/Modals";
import Alert from "../Components/UiKits/Alert";
import Popover from "../Components/UiKits/Popover";
import Tooltips from "../Components/UiKits/Tooltip";
import Spinners from "../Components/UiKits/Spinners";
import Dropdowns from "../Components/UiKits/Dropdown";
import Accordian from "../Components/UiKits/Accordian";
import TabBootstrap from "../Components/UiKits/Tabs/BootstrapTab";
import TabLine from "../Components/UiKits/Tabs/LineTab";
import Navs from "../Components/UiKits/Navs";
import Shadow from "../Components/UiKits/Shadows";
import List from "../Components/UiKits/Lists";

//Bonus-ui
import Scrollable from "../Components/Bonus-Ui/Scrollable";
import TreeView from "../Components/Bonus-Ui/TreeView";
import BootstrapNotify from "../Components/Bonus-Ui/BootstrapNotify";
import RatingClass from "../Components/Bonus-Ui/Rating";
import Dropzones from "../Components/Bonus-Ui/Dropzones";
import Tours from "../Components/Bonus-Ui/Tour";
import SweetAlerts from "../Components/Bonus-Ui/SweetAlert";
import Carousels from "../Components/Bonus-Ui/Carousel";
import Ribbons from "../Components/Bonus-Ui/Ribbons";
import Paginations from "../Components/Bonus-Ui/Pagination";
import Steps from "../Components/Bonus-Ui/Steps";
import BreadcrumbClass from "../Components/Bonus-Ui/Breadcrumb";
import RangeSlider from "../Components/Bonus-Ui/RangeSlider";
import Imagecropper from "../Components/Bonus-Ui/ImageCropper";
import Sticky from "../Components/Bonus-Ui/Sticky";
import DragAndDrop from "../Components/Bonus-Ui/DragAndDrop";
import UploadImage from "../Components/Bonus-Ui/ImageUpload";
import BasicCards from "../Components/Bonus-Ui/Cards/BasicCards/index";
import CreativeCards from "../Components/Bonus-Ui/Cards/CreativeCards";
import TabCard from "../Components/Bonus-Ui/Cards/TabCard";
import DraggingCards from "../Components/Bonus-Ui/Cards/DragCards/index";
import Timeline1 from "../Components/Bonus-Ui/Timeline/Timeline1";
// Icons
import WeatherIcon from "../Components/Icons/WeatherIcon";
import FeatherIcon from "../Components/Icons/FeatherIcon";
import ThemifyIcon from "../Components/Icons/ThemifyIcon";
import IcoIcon from "../Components/Icons/IcoIcon";
import FontawesomeIcon from "../Components/Icons/FontawesomeIcon";
import FlagIcon from "../Components/Icons/FlagIcon";

// Buttons
import DefaultButton from "../Components/Buttons/Default";
import FlatButton from "../Components/Buttons/FlatBtn/index";
import EdgeButton from "../Components/Buttons/Edge/index";
import RaisedButton from "../Components/Buttons/RaisedBtn/index";
import GroupButton from "../Components/Buttons/GroupBtn/index";

//chart
import ApexCharts from "../Components/Charts/ApexCharts";
import Chartist from "../Components/Charts/ChartistCharts";
import ChartJs from "../Components/Charts/ChartsJs";
import GoogleCharts from "../Components/Charts/GoogleCharts";
import KnobChart from "../Components/Charts/KnobChart";

//forms
import FormValidation from "../Components/Forms/FormControl/FormValidation";
import BaseInput from "../Components/Forms/FormControl/BaseInput";
import CheckboxandRadio from "../Components/Forms/FormControl/CheckRadio";
import InputGroups from "../Components/Forms/FormControl/InputGroups";
import MegaOption from "../Components/Forms/FormControl/MegaOption";
import Datepicker from "../Components/Forms/FormWidget/DatePicker";
import Timepicker from "../Components/Forms/FormWidget/TimepickerComponent/TimePicker";
import TypeaheadComp from "../Components/Forms/FormWidget/TypeaheadComponent/Typeahead";
import FormDefault from "../Components/Forms/FormLayout/Default";
import FormWizard from "../Components/Forms/FormLayout/FormWizard1/FormWizard1";
import BasicTables from "../Components/Tables/Reactstrap/BasicTable";
import BorderTable from "../Components/Tables/Reactstrap/BorderTable";
import SizingTable from "../Components/Tables/Reactstrap/SizingTable";
import StylingTable from "../Components/Tables/Reactstrap/StylingTable";
import DataTables from "../Components/Tables/DataTable";

//Application
import Newproject from "../Components/Application/Project/Newproject";
import Project from "../Components/Application/Project/Project";
import Chat from "../Components/Application/Chat/ChatApp";
import VideoChat from "../Components/Application/Chat/VideoChat";
import ContactFirebase from "../Components/Application/Contact/ContactFirebase";
import Contact from "../Components/Application/Contact/ContactApp";
import Task from "../Components/Application/Task";
import TodoContain from "../Components/Application/Todo";

//Gallary
import ImageGallery from "../Components/Gallery/ImageGallery";
import MesonryDesc from "../Components/Gallery/MesonryDesc";
import MasonryGallery from "../Components/Gallery/MesonryGallery";
import ImageWithDesc from "../Components/Gallery/ImageWithDesc";
import ImageHover from "../Components/Gallery/ImageHover";

//Blog
import BlogDetail from "../Components/Blog/BlogDetail";
import BlogPost from "../Components/Blog/BlogPost";
import BlogSingle from "../Components/Blog/BlogSingle";

//Pages
import SamplePage from "../Components/Pages/Sample";

//Book mark
import BookmarksContain from "../Components/Application/BookMarks";

// To-do app
import TodoFirebaseContain from "../Components/Application/TodoFirebase";

//User app
import UsersCardssContain from "../Components/Application/Users/UsersCards";
import UsersEditContain from "../Components/Application/Users/UsersEdit";
import UsersProfileContain from "../Components/Application/Users/UsersProfile";

//social app
import SocialAppContain from "../Components/Application/SocialApp";

//calender
import CalenderContain from "../Components/Application/Calender/Index";

//Email
import MailInboxContain from "../Components/Application/Email/MailInbox";
import ComposeContain from "../Components/Application/Email/Compose";
import ReadMailContain from "../Components/Application/Email/ReadMail";

//File Manager
import FileManagerContain from "../Components/Application/FileManager";
import SearchResultContain from "../Components/Application/SearchResult";

//E-commerce
import Products from "../Components/Application/Ecommerce/Products";
import ProductCart from "../Components/Application/Ecommerce/ProductCart";
import WishList from "../Components/Application/Ecommerce/Wishlist";
import CheckOut from "../Components/Application/Ecommerce/CheckOut";
import Invoice from "../Components/Application/Ecommerce/Invoice";
import OrderHistory from "../Components/Application/Ecommerce/OrderHistory";
import ProductPage from "../Components/Application/Ecommerce/ProductPage";
import PricingMemberShip from "../Components/Application/Ecommerce/PricingMemberShip";
import PaymentDetails from "../Components/Application/Ecommerce/PaymentDetails";
import ProductListContain from "../Components/Application/Ecommerce/ProductList";
import KanbanBoardContain from "../Components/Application/KanbanBoard";
import DraggableContain from "../Components/Application/Calender/DraggableCalender";

//Job Search
import JobCards from "../Components/JobSearch/JobCardView";
import ListView from "../Components/JobSearch/ListView";
import JobDetails from "../Components/JobSearch/JobDetail";
import Apply from "../Components/JobSearch/Apply";

//Learning
import LearningList from "../Components/Learning/LearningList";
import DetailCourse from "../Components/Learning/DetailCourse";

//Map
import GoogleMaps from "../Components/Map/GoogleMap";
import LeafletMapContain from "../Components/Map/LeafletMap";

//Editor
import CkEditorContain from "../Components/Editor/CkEditor";
import MdeEDitorContain from "../Components/Editor/MDEEditor/MDEEditor";
import ACEEditorContain from "../Components/Editor/AceCodeEditor/ACECodeEditor";

import KnowledgeBaseContain from "../Components/Knowledgebase/KnowledgeBase";
import KnowledgeCategoryContain from "../Components/Knowledgebase/KnowledgeCategory";
import KnowledgeDetailContain from "../Components/Knowledgebase/KnowledgeDetails";
import SupportTickitContain from "../Components/SupportTicket";
import DateTimeContain from "../Components/Forms/FormWidget/DateandTime";
import FormDateRangepickerContain from "../Components/Forms/FormWidget/FormDateRangePicker";
import FormSelectContain from "../Components/Forms/FormWidget/FormSelect2";
import FormSwitchContain from "../Components/Forms/FormWidget/FormSwitch";
import FormTouchspinContain from "../Components/Forms/FormWidget/FormTouchspin";
import FormClipboardContain from "../Components/Forms/FormWidget/FormClipboard";
import FAQContent from "../Components/FAQ";
import CrewList from "../Components/Application/AboutUsPage/CrewList/CrewList";

//Book mark
import WelcomeMessage from "../Components/AdminPages/WelcomeMessage";

/****** Anst Protocols * ****/
import AddCategories from "../Components/AntsProtocols/Categories/AddCategories";
import CategoriesList from "../Components/AntsProtocols/Categories/CategoriesList";
import AddProjects from "../Components/AntsProtocols/Projects/AddProjects";
import ProjectList from "../Components/AntsProtocols/Projects/ProjectList";
import AntsHero from "../Components/AntsProtocols/AntsSection/AntsHero";
import AntsHero_list from "../Components/AntsProtocols/AntsSection/AntsHero_list";
import AntsMission from "../Components/AntsProtocols/AntsMission/AntsMission";
import AntsMissionList from "../Components/AntsProtocols/AntsMission/AntsMissionList";
import AntsRoadmap from "../Components/AntsProtocols/AntsRoadmap/AntsRoadmap";
import AntsRoadmapList from "../Components/AntsProtocols/AntsRoadmap/AntsRoadmapList";
import AntsWallet from "../Components/AntsProtocols/AntsWallet/AntsWallet";
import AntsWalletList from "../Components/AntsProtocols/AntsWallet/AntsWalletList";
import AntsToken from "../Components/AntsProtocols/AntsWallet/AntsToken";
import AntsTokenList from "../Components/AntsProtocols/AntsWallet/AntsTokenList";
import AntsBenefits from "../Components/AntsProtocols/AntsBenefits/AntsBenefits";
import AntsBenefitsList from "../Components/AntsProtocols/AntsBenefits/AntsBenefitsList";
import WalletTransactionsList from "../Components/AntsProtocols/WalletTransactions/WalletTransactionsList";
import WalletTransactionsListDetails from "../Components/AntsProtocols/WalletTransactions/WalletTransactionsListDetails";
import CashbacksList from "../Components/AntsProtocols/CashbacksList/CashbacksList";
import AddStats from "../Components/AntsProtocols/AntsStats/AddStats";
import StatsList from "../Components/AntsProtocols/AntsStats/StatsList";
import AntsTeam from "../Components/AntsProtocols/AntsTeam/AntsTeam";
import AntsTeamList from "../Components/AntsProtocols/AntsTeam/AntsTeamList";
import AntsPartners from "../Components/AntsProtocols/AntsPartners/AntsPartners";
import AntsPartnersList from "../Components/AntsProtocols/AntsPartners/AntsPartnersList";
import AntsMissionTItle from "../Components/AntsProtocols/AntsMission/AntsMissionTItle";
import AntsRoadmapTitle from "../Components/AntsProtocols/AntsRoadmap/AntsRoadmapTitle";
import AntsBenefitsTitle from "../Components/AntsProtocols/AntsBenefits/AntsBenefitsTitle";
import CreatePlans from "../Components/AntsProtocols/CreatePlans/CreatePlans";
import CreatePlansList from "../Components/AntsProtocols/CreatePlans/CreatePlansList";
import AddBadge from "../Components/AntsProtocols/AddBadges/AddBadge";
import BadgeList from "../Components/AntsProtocols/AddBadges/BadgeList";
import AntsSuscriptionTitle from "../Components/AntsProtocols/CreatePlans/AntsSuscriptionTitle";
import AddCashback from "../Components/AntsProtocols/AntsCashback/AddCashback";
import WalletAssociation from "../Components/AntsProtocols/WalletAssociation/WalletAssociation";
import WalletAssociationList from "../Components/AntsProtocols/WalletAssociation/WalletAssociationList";
import AntsButtons from "../Components/AntsProtocols/AntsButtons/AntsButtons";
import AntsPartnersTitle from "../Components/AntsProtocols/AntsPartners/AntsPartnersTitle";
import AntsBrand from "../Components/AntsProtocols/AntsBrand/AntsBrand";
import AntsBrandList from "../Components/AntsProtocols/AntsBrand/AntsBrandList";
import AntsFooter from "../Components/AntsProtocols/AntsFooter/AntsFooter";
import AntsFooterList from "../Components/AntsProtocols/AntsFooter/AntsFooterList";
import AntsFooterDescription from "../Components/AntsProtocols/AntsFooter/AntsFooterDescription";
import Knowledge from "../Components/AntsProtocols/Knowledge/AddKnowledge";
import AddKnowledge from "../Components/AntsProtocols/Knowledge/AddKnowledge";
import ListKnowledge from "../Components/AntsProtocols/Knowledge/ListKnowledge";
import UpdateKnowledge from "../Components/AntsProtocols/Knowledge/UpdateKnowledge";
import HowToVote from "../Components/AntsProtocols/AntsSection/HowToVote";
import AddWhitePaper from "../Components/AntsProtocols/AntsSection/AddWhitePaper";
import DonationSection from "../Components/AntsPersonalDonation/DonationSection";
import AddPersonalDonation from "../Components/AntsPersonalDonation/AddPersonalDonation";
import ListPersonalDonation from "../Components/AntsPersonalDonation/ListPersonalDonation";
import UpdatePerDon from "../Components/AntsPersonalDonation/UpdatePerDon";

import AddBlog from '../Components/NewBlogs/AddBlog'
import BlogsList from '../Components/NewBlogs/BlogsList'
import UpdateBlog from '../Components/NewBlogs/UpdateBlog'
import AddBlogCategory from '../Components/NewBlogs/AddBlogCategory'
import BlogCategoryList from '../Components/NewBlogs/BlogCategoryList'
import UpdateBlogCate from '../Components/NewBlogs/UpdateBlogCate'
import UpdateHomeSco from "../Components/SeoInformation/UpdateHomeSco";
import UpdateBlogSeo from "../Components/SeoInformation/UpdateBlogSeo";
import UpdateKnowledgeSeo from "../Components/SeoInformation/UpdateKnowledgeSeo";
import CashbackPercentageList from "../Components/AntsProtocols/AntsCashback/CashbackPercentageList";
import AntsUsers from "../Components/AntsProtocols/AntsUsers/AntsUsers";
import AddProducts from "../Components/AntsProtocols/AntsProducts/AddProducts";
import ProductLists from "../Components/AntsProtocols/AntsProducts/ProductLists";
import AntsProductCategory from "../Components/AntsProtocols/AntsProducts/AntsProductCategory";
import AntsProductCategoryList from "../Components/AntsProtocols/AntsProducts/AntsProductCategoryList";
import AntsProductSubCategory from "../Components/AntsProtocols/AntsProducts/AntsProductSubCategory";
import AntsProductSubCategoryList from "../Components/AntsProtocols/AntsProducts/AntsProductSubCategoryList";
import AddSlider from "../Components/Slider/AddSlider";
import ViewSlider from "../Components/Slider/ViewSlider";
import AddProjectType from "../Components/ProjectType/AddProjectType";
import ProjectTypeList from "../Components/ProjectType/ProjectTypeList";
import AddProjectCate from "../Components/ProjectCate/AddProjectCate";
import ProjectCateList from "../Components/ProjectCate/ProjectCateList";
import UserProjectsList from "../Components/UserProjects/UserProjectsList";
import UpdateStatus from "../Components/UserProjects/UpdateStatus";
import AddStat from "../Components/Stats/AddStat";
import ApproveProjectsList from "../Components/Dashboard/ApproveProjects/ApproveProjectsList";
import UsersList from "../Components/Dashboard/UsersList/UsersList";
import ViewUser from "../Components/Dashboard/UsersList/ViewUser";
import AddNewsLetter from "../Components/Pages/FrontendPages/NewsLetter/AddNewsLetter";
import ViewNewsLetter from "../Components/Pages/FrontendPages/NewsLetter/ViewNewsLetter";
import UpdateNewsLetter from "../Components/Pages/FrontendPages/NewsLetter/UpdateNewsLetter";
import EmailList from "../Components/Pages/FrontendPages/NewsLetter/EmailList";
import AntsTickets from "../Components/AntsProtocols/AntsTickets/AntsTickets";
import AntsTicketsList from "../Components/AntsProtocols/AntsTickets/AntsTicketsList";
import EmailTemplates from "../Components/AntsProtocols/EmailTemplates/EmailTemplates";
import EmailTemplatesList from "../Components/AntsProtocols/EmailTemplates/EmailTemplatesList";
import SeoHowItWorks from "../Components/AntsProtocols/SEO/SeoHowItWorks";
import SeoNgoPage from "../Components/AntsProtocols/SEO/SeoNgoPage";
import SeoProfitablePage from "../Components/AntsProtocols/SEO/SeoProfitablePage";
import SeoLaunchPage from "../Components/AntsProtocols/SEO/SeoLaunchPage";
import SeoGovernancePage from "../Components/AntsProtocols/SEO/SeoGovernancePage";
import SeoProfilePage from "../Components/AntsProtocols/SEO/SeoProfilePage";
import SeoCreatePage from "../Components/AntsProtocols/SEO/SeoCreatePage";
import SeoExplorePage from "../Components/AntsProtocols/SEO/SeoExplorePage";
import AntsReturnPercentageOwner from "../Components/AntsProtocols/WalletAssociation/AntsReturnPercentageOwner";
import AntsVotingPowerCriteria from "../Components/AntsProtocols/AntsTickets/AntsVotingPowerCriteria";
import CaseStudiesStatisticsList from "../Components/AntsProtocols/CaseStudiesStatistics/CaseStudiesStatisticsList";
import AddCaseStudiesStatistics from "../Components/AntsProtocols/CaseStudiesStatistics/AddCaseStudiesStatistics";
import AddStakingPools from "../Components/AntsProtocols/Staking/StakingPools/AddStakingPools";
import StakingPoolsList from "../Components/AntsProtocols/Staking/StakingPools/StakingPoolsList";
import AddAirDrop from "../Components/AntsProtocols/Airdrop/AirdropPools/AddAirDrop";
import AirDropPools from "../Components/AntsProtocols/Airdrop/AirdropPools/AirDropPools";
import Donations from "../Components/AntsProtocols/Transactions/transactionlist/Donations";
import AddWhiteList from "../Components/AntsProtocols/whitelist/whitelist/AddWhiteLists";
import AllWhitelist from "../Components/AntsProtocols/whitelist/whitelist/AllWhiteLists";
import PrivateSaleTansaction from "../Components/AntsProtocols/PrivateSaleTransaction/PrivateSale/PrivateSaleTransaction";
import AddPrivateSaleInfo from "../Components/AntsProtocols/privateSaleInfo/PrivateSaleInfo/AddPrivateSaleInfo";
import AllPrivateSaleInfo from "../Components/AntsProtocols/privateSaleInfo/PrivateSaleInfo/AllPrivateSaleInfo";
import StakedTokensList from "../Components/AntsProtocols/StakedTokens/StakedTokensList";
import ProposalsList from "../Components/AntsProtocols/Proposals/ProposalsList";
import AddProposals from "../Components/AntsProtocols/Proposals/AddProposals";
import AddAdditionalStaking from "../Components/AntsProtocols/AdditionalStaking/AddAdditionalStaking";
import AddNotifications from "../Components/AntsProtocols/Notifications/AddNotifications";
import NotificationsList from "../Components/AntsProtocols/Notifications/NotificationsList";


export const routes = [
  /****** Anst Protocols * ****/
  {
    path: `${process.env.PUBLIC_URL}/home/add_herosection`,
    Component: <AntsHero />,
  },
  {
    path: `${process.env.PUBLIC_URL}/home/herosection_list`,
    Component: <AntsHero_list />,
  },
  {
    path: `${process.env.PUBLIC_URL}/home/howtovote`,
    Component: <HowToVote />,
  },
  {
    path: `${process.env.PUBLIC_URL}/home/addWhitePaper`,
    Component: <AddWhitePaper />,
  },
  {
    path: `${process.env.PUBLIC_URL}/mission/add_mission`,
    Component: <AntsMission />,
  },
  {
    path: `${process.env.PUBLIC_URL}/mission/mission_list`,
    Component: <AntsMissionList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/mission/add_missiontitle`,
    Component: <AntsMissionTItle />,
  },
  {
    path: `${process.env.PUBLIC_URL}/works/add_howworkstitle`,
    Component: <AntsRoadmapTitle />,
  },
  {
    path: `${process.env.PUBLIC_URL}/works/add_howworks`,
    Component: <AntsRoadmap />,
  },
  {
    path: `${process.env.PUBLIC_URL}/works/howworks_list`,
    Component: <AntsRoadmapList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/wallet/add_antswallet`,
    Component: <AntsWallet />,
  },
  {
    path: `${process.env.PUBLIC_URL}/wallet/antswallet_list`,
    Component: <AntsWalletList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/token/add_antstoken`,
    Component: <AntsToken />,
  },
  {
    path: `${process.env.PUBLIC_URL}/token/antstoken_list`,
    Component: <AntsTokenList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/benefits/add_benefitstitle`,
    Component: <AntsBenefitsTitle />,
  },
  {
    path: `${process.env.PUBLIC_URL}/benefits/add_benefits`,
    Component: <AntsBenefits />,
  },
  {
    path: `${process.env.PUBLIC_URL}/benefits/benefits_list`,
    Component: <AntsBenefitsList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/plans/add_subscription_title`,
    Component: <AntsSuscriptionTitle />,
  },
  {
    path: `${process.env.PUBLIC_URL}/wallet_transactions/wallet_transactions_list`,
    Component: <WalletTransactionsList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/cashback/cashbacks_list`,
    Component: <CashbacksList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/wallet_transactions/wallet_transactions_list_details`,
    Component: <WalletTransactionsListDetails />,
  },
  {
    path: `${process.env.PUBLIC_URL}/stats/add_stats`,
    Component: <AddStats />,
  },
  {
    path: `${process.env.PUBLIC_URL}/stats/stats_list`,
    Component: <StatsList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/team/add_team`,
    Component: <AntsTeam />,
  },
  {
    path: `${process.env.PUBLIC_URL}/team/team_list`,
    Component: <AntsTeamList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/partners/partners_title`,
    Component: <AntsPartnersTitle />,
  },
  {
    path: `${process.env.PUBLIC_URL}/partners/add_partners`,
    Component: <AntsPartners />,
  },
  {
    path: `${process.env.PUBLIC_URL}/partners/partners_list`,
    Component: <AntsPartnersList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/projects/add_category`,
    Component: <AddCategories />,
  },
  {
    path: `${process.env.PUBLIC_URL}/projects/category_list`,
    Component: <CategoriesList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/projects/add_project`,
    Component: <AddProjects />,
  },
  {
    path: `${process.env.PUBLIC_URL}/projects/project_list`,
    Component: <ProjectList />,
  },

  {
    path: `${process.env.PUBLIC_URL}/plans/add_plans`,
    Component: <CreatePlans />,
  },
  {
    path: `${process.env.PUBLIC_URL}/plans/plans_list`,
    Component: <CreatePlansList />,
  },

  {
    path: `${process.env.PUBLIC_URL}/badge/add_badge`,
    Component: <AddBadge />,
  },
  {
    path: `${process.env.PUBLIC_URL}/badge/badge_list`,
    Component: <BadgeList />,
  },

  {
    path: `${process.env.PUBLIC_URL}/cashback/add_cashback`,
    Component: <AddCashback />,
  },
  {
    path: `${process.env.PUBLIC_URL}/cashback/cashback_list_percentage`,
    Component: <CashbackPercentageList />,
  },

  {
    path: `${process.env.PUBLIC_URL}/wallet_Association/add_return_percentage`,
    Component: <AntsReturnPercentageOwner />,
  },
  {
    path: `${process.env.PUBLIC_URL}/wallet_Association/add_wallet_association`,
    Component: <WalletAssociation />,
  },

  {
    path: `${process.env.PUBLIC_URL}/wallet_Association/add_wallet_list`,
    Component: <WalletAssociationList />,
  },
  // Ants Products
  {
    path: `${process.env.PUBLIC_URL}/category/add_category`,
    Component: <AntsProductCategory />,
  },
  {
    path: `${process.env.PUBLIC_URL}/category/category_list`,
    Component: <AntsProductCategoryList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/subcategory/add_subcategory`,
    Component: <AntsProductSubCategory />,
  },
  {
    path: `${process.env.PUBLIC_URL}/subcategory/subcategory_list`,
    Component: <AntsProductSubCategoryList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/product/add_products`,
    Component: <AddProducts />,
  },
  {
    path: `${process.env.PUBLIC_URL}/product/product_list`,
    Component: <ProductLists />,
  },
  //..
  {
    path: `${process.env.PUBLIC_URL}/ants_button/add_ants_button`,
    Component: <AntsButtons />,
  },
  {
    path: `${process.env.PUBLIC_URL}/users/users_list`,
    Component: <AntsUsers />,
  },
  // Staking Pools
  {
    path: `${process.env.PUBLIC_URL}/staking/add_staking_pools`,
    Component: <AddStakingPools />,
  },
  {
    path: `${process.env.PUBLIC_URL}/staking/staking_pools_list`,
    Component: <StakingPoolsList />,
  },
  // Airdrop Pools
  {
    path: `${process.env.PUBLIC_URL}/airdrop/add_airdrop_pools`,
    Component: <AddAirDrop />,
  },
  {
    path: `${process.env.PUBLIC_URL}/airdrop/airdrop_pools_list`,
    Component: <AirDropPools />,
  },
  // Transactions
  {
    path: `${process.env.PUBLIC_URL}/transactions/transactionLists`,
    Component: <Donations/>,
  },
  // Private Sale Info
  {
    path: `${process.env.PUBLIC_URL}/privateSale/add_saleInfo`,
    Component: <AddPrivateSaleInfo/>,
  },
  {
    path: `${process.env.PUBLIC_URL}/privateSale/get_saleInfo`,
    Component: <AllPrivateSaleInfo/>,
  },
  // Whitelists
  {
    path: `${process.env.PUBLIC_URL}/whitelist/add_whitelists`,
    Component: <AddWhiteList/>,
  },
  {
    path: `${process.env.PUBLIC_URL}/whitelist/all_whitelists`,
    Component: <AllWhitelist/>,
  },
  // Private sale transation
  {
    path: `${process.env.PUBLIC_URL}/private/saleTransaction`,
    Component: <PrivateSaleTansaction/>,
  },
  {
    path: `${process.env.PUBLIC_URL}/stakedtoken/staked_tokens_list`,
    Component: <StakedTokensList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/additionalstaking/add_additional`,
    Component: <AddAdditionalStaking />,
  },
  {
    path: `${process.env.PUBLIC_URL}/proposals/proposals_list`,
    Component: <ProposalsList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/proposals/add_proposals`,
    Component: <AddProposals />,
  },
  {
    path: `${process.env.PUBLIC_URL}/notification/add_notifications`,
    Component: <AddNotifications />,
  },
  {
    path: `${process.env.PUBLIC_URL}/notification/notifications_list`,
    Component: <NotificationsList />,
  },
  // Ants Brand
  {
    path: `${process.env.PUBLIC_URL}/antsbrand/add_antsbrand`,
    Component: <AntsBrand />,
  },
  {
    path: `${process.env.PUBLIC_URL}/antsbrand/antsbrand_list`,
    Component: <AntsBrandList />,
  },
  // Ants Launch Page Case Studies Statisitcs
  {
    path: `${process.env.PUBLIC_URL}/launch_page/add_case_studies_statistics`,
    Component: <AddCaseStudiesStatistics />,
  },
  {
    path: `${process.env.PUBLIC_URL}/launch_page/case_studies_statistics_list`,
    Component: <CaseStudiesStatisticsList />,
  },
  // Ants Footer
  {
    path: `${process.env.PUBLIC_URL}/footer/add_footer`,
    Component: <AntsFooter />,
  },
  {
    path: `${process.env.PUBLIC_URL}/footer/footer_list`,
    Component: <AntsFooterList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/footer/description`,
    Component: <AntsFooterDescription />,
  },
  // Ants Tickets
  {
    path: `${process.env.PUBLIC_URL}/tickets/add_voting_power`,
    Component: <AntsVotingPowerCriteria />,
  },
  {
    path: `${process.env.PUBLIC_URL}/tickets/add_ticket`,
    Component: <AntsTickets />,
  },
  {
    path: `${process.env.PUBLIC_URL}/tickets/ticket_list`,
    Component: <AntsTicketsList />,
  },

  // Email Templates
  {
    path: `${process.env.PUBLIC_URL}/email_templates/add_email_templates`,
    Component: <EmailTemplates />,
  },
  {
    path: `${process.env.PUBLIC_URL}/email_templates/email_templates_list`,
    Component: <EmailTemplatesList />,
  },

  /****** Anst Protocols * ****/

  //!Settings End
  // welcome-message
  {
    path: `${process.env.PUBLIC_URL}/admin-pages/welcome-message`,
    Component: <WelcomeMessage />,
  },
  //dashboard
  {
    path: `${process.env.PUBLIC_URL}/dashboard/default`,
    Component: <Default />,
  },
  {
    path: `${process.env.PUBLIC_URL}/dashboard/ecommerce`,
    Component: <Ecommerce />,
  },

  {
    path: `/dashboard/slider`,
    Component: <Slidersection />,
  },
  {
    path: `/dashboard/sliderlist`,
    Component: <SliderList />,
  },
  {
    path: `/dashboard/add-logo`,
    Component: <Addlogo />,
  },
  { path: `/dashboard/add-social-links`, Component: <AddSocial_Links /> },
  { path: `/dashboard/add-web-title`, Component: <AddwebsiteTitle /> },
  { path: `/dashboard/add-fav-icon`, Component: <AddFavicon /> },
  { path: `/dashboard/aboutus-data`, Component: <AboutUs_page /> },
  { path: `/dashboard/addcrew`, Component: <AddCrew /> },
  { path: `/dashboard/crewlist`, Component: <CrewList /> },
  { path: `/dashboard/deoform`, Component: <Daoform /> },
  { path: `/dashboard/faq`, Component: <Faq /> },
  { path: `/dashboard/faqlist`, Component: <FaqList /> },
  { path: `/dashboard/crm-form`, Component: <AddCrmForm /> },
  { path: `/dashboard/crm-list`, Component: <CrmList /> },
  { path: `/dashboard/make-group`, Component: <MakeGroups /> },
  { path: `/dashboard/assign-email`, Component: <AssignEmailToGroups /> },
  { path: `/dashboard/view-email`, Component: <ViewEmails /> },
  { path: `/dashboard/run-compagin`, Component: <RunCompagins /> },
  { path: `/dashboard/log-screen`, Component: <LogScreen /> },

  // //widgets
  { path: `${process.env.PUBLIC_URL}/widgets/general`, Component: <General /> },
  { path: `${process.env.PUBLIC_URL}/widgets/chart`, Component: <Chart /> },

  // //page-layout
  {
    path: `${process.env.PUBLIC_URL}/page-layout/footer-light`,
    Component: <FooterLight />,
  },
  {
    path: `${process.env.PUBLIC_URL}/page-layout/footer-fixed`,
    Component: <FooterFixed />,
  },
  {
    path: `${process.env.PUBLIC_URL}/page-layout/footer-dark`,
    Component: <FooterDark />,
  },

  //ui-kits
  {
    path: `${process.env.PUBLIC_URL}/ui-kits/typography`,
    Component: <Typography />,
  },
  { path: `${process.env.PUBLIC_URL}/ui-kits/avatars`, Component: <Avatars /> },
  {
    path: `${process.env.PUBLIC_URL}/ui-kits/helper-classes`,
    Component: <HelperClasses />,
  },
  { path: `${process.env.PUBLIC_URL}/ui-kits/grid`, Component: <Grid /> },
  {
    path: `${process.env.PUBLIC_URL}/ui-kits/tag-pills`,
    Component: <TagAndPills />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ui-kits/progress`,
    Component: <Progressbars />,
  },
  { path: `${process.env.PUBLIC_URL}/ui-kits/modal`, Component: <Modals /> },
  { path: `${process.env.PUBLIC_URL}/ui-kits/alert`, Component: <Alert /> },
  { path: `${process.env.PUBLIC_URL}/ui-kits/popover`, Component: <Popover /> },
  {
    path: `${process.env.PUBLIC_URL}/ui-kits/tooltip`,
    Component: <Tooltips />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ui-kits/spinners`,
    Component: <Spinners />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ui-kits/dropdown`,
    Component: <Dropdowns />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ui-kits/accordion`,
    Component: <Accordian />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ui-kits/tab-bootstrap`,
    Component: <TabBootstrap />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ui-kits/line-tabs`,
    Component: <TabLine />,
  },
  { path: `${process.env.PUBLIC_URL}/ui-kits/navs`, Component: <Navs /> },
  { path: `${process.env.PUBLIC_URL}/ui-kits/shadow`, Component: <Shadow /> },
  { path: `${process.env.PUBLIC_URL}/ui-kits/lists`, Component: <List /> },

  // //Bonus Ui
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/scrollable`,
    Component: <Scrollable />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/tree-view`,
    Component: <TreeView />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/bootstrap-notify`,
    Component: <BootstrapNotify />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/rating`,
    Component: <RatingClass />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/dropzone`,
    Component: <Dropzones />,
  },
  { path: `${process.env.PUBLIC_URL}/bonus-ui/tour`, Component: <Tours /> },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/sweet-alert`,
    Component: <SweetAlerts />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/carousel`,
    Component: <Carousels />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/ribbons`,
    Component: <Ribbons />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/pagination`,
    Component: <Paginations />,
  },
  { path: `${process.env.PUBLIC_URL}/bonus-ui/steps`, Component: <Steps /> },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/breadcrumb`,
    Component: <BreadcrumbClass />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/rangeSlider`,
    Component: <RangeSlider />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/imageCropper`,
    Component: <Imagecropper />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/stickyNotes`,
    Component: <Sticky />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/dragNDropComp`,
    Component: <DragAndDrop />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/image-upload`,
    Component: <UploadImage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/card/basicCards`,
    Component: <BasicCards />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/card/creativeCards`,
    Component: <CreativeCards />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/card/tabCard`,
    Component: <TabCard />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/card/draggingCards`,
    Component: <DraggingCards />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/timelines/timeline1`,
    Component: <Timeline1 />,
  },

  // //Icons
  {
    path: `${process.env.PUBLIC_URL}/icons/flagIcons`,
    Component: <FlagIcon />,
  },
  {
    path: `${process.env.PUBLIC_URL}/icons/fontAwsomeIcon`,
    Component: <FontawesomeIcon />,
  },
  { path: `${process.env.PUBLIC_URL}/icons/icoIcons`, Component: <IcoIcon /> },
  {
    path: `${process.env.PUBLIC_URL}/icons/themifyIcons`,
    Component: <ThemifyIcon />,
  },
  {
    path: `${process.env.PUBLIC_URL}/icons/featherIcons`,
    Component: <FeatherIcon />,
  },
  {
    path: `${process.env.PUBLIC_URL}/icons/weatherIcons`,
    Component: <WeatherIcon />,
  },

  // //Buttons
  {
    path: `${process.env.PUBLIC_URL}/buttons/default-btn`,
    Component: <DefaultButton />,
  },
  {
    path: `${process.env.PUBLIC_URL}/buttons/flatBtn`,
    Component: <FlatButton />,
  },
  {
    path: `${process.env.PUBLIC_URL}/buttons/edgeBtn`,
    Component: <EdgeButton />,
  },
  {
    path: `${process.env.PUBLIC_URL}/buttons/raisedBtn`,
    Component: <RaisedButton />,
  },
  {
    path: `${process.env.PUBLIC_URL}/buttons/groupBtn`,
    Component: <GroupButton />,
  },

  // //Charts
  {
    path: `${process.env.PUBLIC_URL}/charts/apexCharts`,
    Component: <ApexCharts />,
  },
  {
    path: `${process.env.PUBLIC_URL}/charts/chartist`,
    Component: <Chartist />,
  },
  { path: `${process.env.PUBLIC_URL}/charts/chartJs`, Component: <ChartJs /> },
  {
    path: `${process.env.PUBLIC_URL}/charts/googleChart`,
    Component: <GoogleCharts />,
  },
  {
    path: `${process.env.PUBLIC_URL}/charts/knobChart`,
    Component: <KnobChart />,
  },

  // // Forms
  {
    path: `${process.env.PUBLIC_URL}/forms/formValidation`,
    Component: <FormValidation />,
  },
  {
    path: `${process.env.PUBLIC_URL}/forms/baseInput`,
    Component: <BaseInput />,
  },
  {
    path: `${process.env.PUBLIC_URL}/forms/radio-checkbox`,
    Component: <CheckboxandRadio />,
  },
  {
    path: `${process.env.PUBLIC_URL}/forms/inputGroup`,
    Component: <InputGroups />,
  },
  {
    path: `${process.env.PUBLIC_URL}/forms/megaOptions`,
    Component: <MegaOption />,
  },
  {
    path: `${process.env.PUBLIC_URL}/form-widget/datepicker`,
    Component: <Datepicker />,
  },
  {
    path: `${process.env.PUBLIC_URL}/form-widget/timepicker`,
    Component: <Timepicker />,
  },
  {
    path: `${process.env.PUBLIC_URL}/form-widget/datetimepicker`,
    Component: <DateTimeContain />,
  },
  {
    path: `${process.env.PUBLIC_URL}/form-widget/typeahead`,
    Component: <TypeaheadComp />,
  },
  {
    path: `${process.env.PUBLIC_URL}/form-widget/rangepicker`,
    Component: <FormDateRangepickerContain />,
  },
  {
    path: `${process.env.PUBLIC_URL}/form-widget/select2`,
    Component: <FormSelectContain />,
  },
  {
    path: `${process.env.PUBLIC_URL}/form-widget/switch`,
    Component: <FormSwitchContain />,
  },
  {
    path: `${process.env.PUBLIC_URL}/form-widget/touchspin`,
    Component: <FormTouchspinContain />,
  },
  {
    path: `${process.env.PUBLIC_URL}/form-widget/clipboard`,
    Component: <FormClipboardContain />,
  },

  {
    path: `${process.env.PUBLIC_URL}/form-layout/formDefault`,
    Component: <FormDefault />,
  },
  {
    path: `${process.env.PUBLIC_URL}/form-layout/formWizard`,
    Component: <FormWizard />,
  },

  //Tables
  { path: `${process.env.PUBLIC_URL}/table/basic`, Component: <BasicTables /> },
  {
    path: `${process.env.PUBLIC_URL}/table/sizing`,
    Component: <SizingTable />,
  },
  {
    path: `${process.env.PUBLIC_URL}/table/border`,
    Component: <BorderTable />,
  },
  {
    path: `${process.env.PUBLIC_URL}/table/styling`,
    Component: <StylingTable />,
  },
  {
    path: `${process.env.PUBLIC_URL}/table/data-table`,
    Component: <DataTables />,
  },

  //Applicatiion
  {
    path: `${process.env.PUBLIC_URL}/app/project/project-list`,
    Component: <Project />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/project/new-project`,
    Component: <Newproject />,
  },
  { path: `${process.env.PUBLIC_URL}/app/chat-app`, Component: <Chat /> },
  {
    path: `${process.env.PUBLIC_URL}/app/chat-video-app`,
    Component: <VideoChat />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/contact-firebase`,
    Component: <ContactFirebase />,
  },
  { path: `${process.env.PUBLIC_URL}/app/contact`, Component: <Contact /> },
  { path: `${process.env.PUBLIC_URL}/app/task`, Component: <Task /> },
  {
    path: `${process.env.PUBLIC_URL}/app/bookmark`,
    Component: <BookmarksContain />,
  },
  { path: `${process.env.PUBLIC_URL}/app/todo`, Component: <TodoContain /> },
  {
    path: `${process.env.PUBLIC_URL}/app/todo-firebase`,
    Component: <TodoFirebaseContain />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/users/userProfile`,
    Component: <UsersProfileContain />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/users/userEdit`,
    Component: <UsersEditContain />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/users/userCards`,
    Component: <UsersCardssContain />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/social-app`,
    Component: <SocialAppContain />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/calendar/basic-calendar`,
    Component: <CalenderContain />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/calendar/draggable-calendar`,
    Component: <DraggableContain />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/email/mailbox`,
    Component: <MailInboxContain />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/email/readmail`,
    Component: <ReadMailContain />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/email/compose`,
    Component: <ComposeContain />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/file-manager`,
    Component: <FileManagerContain />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/search-result`,
    Component: <SearchResultContain />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/ecommerce/product`,
    Component: <Products />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/ecommerce/product-page/:id`,
    Component: <ProductPage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/ecommerce/payment-details`,
    Component: <PaymentDetails />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/ecommerce/orderhistory`,
    Component: <OrderHistory />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/ecommerce/invoice`,
    Component: <Invoice />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/ecommerce/cart`,
    Component: <ProductCart />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/ecommerce/wishlist`,
    Component: <WishList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/ecommerce/checkout`,
    Component: <CheckOut />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/ecommerce/pricing`,
    Component: <PricingMemberShip />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/ecommerce/product-list`,
    Component: <ProductListContain />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/kanban-board`,
    Component: <KanbanBoardContain />,
  },

  //Gallery
  {
    path: `${process.env.PUBLIC_URL}/app/gallery/imageGallery`,
    Component: <ImageGallery />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/gallery/imageWithDesc`,
    Component: <ImageWithDesc />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/gallery/mesonryGallery`,
    Component: <MasonryGallery />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/gallery/mesonryDesc`,
    Component: <MesonryDesc />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/gallery/imageHover`,
    Component: <ImageHover />,
  },

  //page
  {
    path: `${process.env.PUBLIC_URL}/pages/sample-page`,
    Component: <SamplePage />,
  },

  //Blog
  {
    path: `${process.env.PUBLIC_URL}/app/blog/blogDetail`,
    Component: <BlogDetail />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/blog/blogSingle`,
    Component: <BlogSingle />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/blog/blogPost`,
    Component: <BlogPost />,
  },

  //Job Search
  {
    path: `${process.env.PUBLIC_URL}/app/jobSearch/cardView`,
    Component: <JobCards />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/jobSearch/job-list`,
    Component: <ListView />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/jobSearch/job-detail`,
    Component: <JobDetails />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/jobSearch/job-apply`,
    Component: <Apply />,
  },

  //Learning
  {
    path: `${process.env.PUBLIC_URL}/app/learning/learning-list`,
    Component: <LearningList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/learning/learning-detail`,
    Component: <DetailCourse />,
  },

  //Map
  {
    path: `${process.env.PUBLIC_URL}/app/map/googleMap`,
    Component: <GoogleMaps />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/map/leaflet-map`,
    Component: <LeafletMapContain />,
  },

  //Editor
  {
    path: `${process.env.PUBLIC_URL}/editor/ckEditor`,
    Component: <CkEditorContain />,
  },
  {
    path: `${process.env.PUBLIC_URL}/editor/mdeEditor`,
    Component: <MdeEDitorContain />,
  },
  {
    path: `${process.env.PUBLIC_URL}/editor/ace-code-editor`,
    Component: <ACEEditorContain />,
  },

  //Knowledge
  {
    path: `${process.env.PUBLIC_URL}/app/knowledgebase`,
    Component: <KnowledgeBaseContain />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/knowledgebase-category`,
    Component: <KnowledgeCategoryContain />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/knowledgebase-detail`,
    Component: <KnowledgeDetailContain />,
  },

  //Support Ticket
  {
    path: `${process.env.PUBLIC_URL}/app/support-ticket`,
    Component: <SupportTickitContain />,
  },

  //Faq
  { path: `${process.env.PUBLIC_URL}/app/faq`, Component: <FAQContent /> },


  //knowledge
  { path: `${process.env.PUBLIC_URL}/app/addknowledge`, Component: <AddKnowledge /> },
  { path: `${process.env.PUBLIC_URL}/app/listknowledge`, Component: <ListKnowledge /> },
  { path: `${process.env.PUBLIC_URL}/app/updateknowledge/:id`, Component: <UpdateKnowledge /> },

  { path: `${process.env.PUBLIC_URL}/app/donationSection`, Component: <DonationSection /> },
  { path: `${process.env.PUBLIC_URL}/app/personalDonation`, Component: <AddPersonalDonation /> },
  { path: `${process.env.PUBLIC_URL}/app/listPersonalDonation`, Component: <ListPersonalDonation /> },
  { path: `${process.env.PUBLIC_URL}/app/updatePersonalDonation/:id`, Component: <UpdatePerDon /> },

  //Blog
  { path: `${process.env.PUBLIC_URL}/blogs/addBlog`, Component: <AddBlog /> },
  { path: `${process.env.PUBLIC_URL}/blogs/blogsList`, Component: <BlogsList /> },
  { path: `${process.env.PUBLIC_URL}/blogs/updateBlog/:id`, Component: <UpdateBlog /> },
  { path: `${process.env.PUBLIC_URL}/blogs/addBlogCategory`, Component: <AddBlogCategory /> },
  { path: `${process.env.PUBLIC_URL}/blogs/blogCategoryList`, Component: <BlogCategoryList /> },
  { path: `${process.env.PUBLIC_URL}/blogs/updateBlogCate/:id`, Component: <UpdateBlogCate /> },

  //Seo Information

  { path: `${process.env.PUBLIC_URL}/Sco/homeSco`, Component: <UpdateHomeSco /> },
  { path: `${process.env.PUBLIC_URL}/Sco/blogSco`, Component: <UpdateBlogSeo /> },
  { path: `${process.env.PUBLIC_URL}/Sco/knowledgeSco`, Component: <UpdateKnowledgeSeo /> },
  { path: `${process.env.PUBLIC_URL}/seo/how_it_works`, Component: <SeoHowItWorks /> },
  { path: `${process.env.PUBLIC_URL}/seo/ngo_page`, Component: <SeoNgoPage /> },
  { path: `${process.env.PUBLIC_URL}/seo/profitable_page`, Component: <SeoProfitablePage /> },
  { path: `${process.env.PUBLIC_URL}/seo/launch_page`, Component: <SeoLaunchPage /> },
  { path: `${process.env.PUBLIC_URL}/seo/governance_page`, Component: <SeoGovernancePage /> },
  { path: `${process.env.PUBLIC_URL}/seo/profile_page`, Component: <SeoProfilePage /> },
  { path: `${process.env.PUBLIC_URL}/seo/create_page`, Component: <SeoCreatePage /> },
  { path: `${process.env.PUBLIC_URL}/seo/explore_page`, Component: <SeoExplorePage /> },

  { path: `${process.env.PUBLIC_URL}/slider/addSlider`, Component: <AddSlider /> },
  { path: `${process.env.PUBLIC_URL}/slider/viewSlider`, Component: <ViewSlider /> },

  //Project Type
  { path: `${process.env.PUBLIC_URL}/project/types`, Component: <AddProjectType /> },
  { path: `${process.env.PUBLIC_URL}/project/typeslist`, Component: <ProjectTypeList /> },

  //New Project Cate
  { path: `${process.env.PUBLIC_URL}/project/addCategory`, Component: <AddProjectCate /> },
  { path: `${process.env.PUBLIC_URL}/project/categoryList`, Component: <ProjectCateList /> },


  { path: `${process.env.PUBLIC_URL}/project/userProjects`, Component: <UserProjectsList /> },
  { path: `${process.env.PUBLIC_URL}/project/updateStatus/:id`, Component: <UpdateStatus /> },

  //STATS
  { path: `${process.env.PUBLIC_URL}/stats/addStats`, Component: <AddStat /> },

  //Approved Projects
  { path: `${process.env.PUBLIC_URL}/approved-projects`, Component: <ApproveProjectsList /> },

  // Users List
  { path: `${process.env.PUBLIC_URL}/user-list`, Component: <UsersList /> },
  { path: `${process.env.PUBLIC_URL}/user/:id`, Component: <ViewUser /> },

  // New Letter
  { path: `${process.env.PUBLIC_URL}/NewsLetter/emailList`, Component: <EmailList /> },
  { path: `${process.env.PUBLIC_URL}/NewsLetter/addNews`, Component: <AddNewsLetter /> },
  { path: `${process.env.PUBLIC_URL}/NewsLetter/viewNewsLetter`, Component: <ViewNewsLetter /> },
  { path: `${process.env.PUBLIC_URL}/NewsLetter/updateNewsLetter/:id`, Component: <UpdateNewsLetter /> },


];
