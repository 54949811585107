import React, { Fragment, useState, useEffect } from "react";
import { Breadcrumbs, H5, Image } from "../../../../AbstractElements";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { getStaking } from "../../../../api/api";
import { deleteStaking } from "../../../../api/api";

export const style2 = { width: 60, fontSize: 14, padding: 4 };

function StakingPoolsList() {
  const navigate = useNavigate();
  const [dataAPI, setDataAPI] = useState([]);

  const populateData = async () => {
    const res = await getStaking();
    setDataAPI([...res.data]);
  };

  const deleteDataItem = async (id) => {
    const res = await deleteStaking(id);
    if (res.status === 200) {
      const freshArray = dataAPI.filter((val) => val.staking_id !== id);
      setDataAPI(freshArray);
    }
  };

  useEffect(() => {
    populateData();
  }, []);


  const addDataAPI = dataAPI?.map((elem) => {
    return {
      title:elem.staking_title,
      desc:elem.staking_description,
      lockPeriod: elem.staking_period,
      ARY: elem.staking_apy,
      maxPoolSize: elem.staking_pool_size,
      status: elem.staking_status,

      action: (
        <div>
          <span>
            <Button  onClick={() => deleteDataItem(elem.staking_id)} className="btn btn-danger btn-xs" style={style2}>
              Delete
            </Button>
          </span>{" "}
          &nbsp;&nbsp;
          <span>
            <Button  onClick={() => {
                navigate(`${process.env.PUBLIC_URL}/staking/add_staking_pools`, {
                  state: { dataObj: elem },
                });
              }}
               className="btn btn-success btn-xs" style={style2}>
              Edit{" "}
            </Button>
          </span>
        </div>
      ),
    };
  });

  const addColumns = [
    {
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
      center: true,
    },
    {
      name: "Lock Period",
      selector: (row) => row.lockPeriod,
      sortable: true,
      center: true,
    },
    {
      name: "APY",
      sortable: true,
      selector: (row) => row.ARY,
      center: true,
    },
    {
      name: "Max Pool Size",
      selector: (row) => row.maxPoolSize,
      sortable: true,
      center: true,
    },
    {
      name: "Description",
      selector: (row) => row.desc,
      sortable: true,
      center: true,
    },

    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      center: true,
    },
    {
      name: "Action",
      selector: (row) => row.action,
      sortable: true,
      center: true,
    },
  ];

  return (
    <Fragment>
      <Breadcrumbs
        parent="Home"
        title="Hero Section List"
        mainTitle="Staking Pool"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="pb-0">
                <H5>{"Staking Pool List"}</H5>
              </CardHeader>
              <CardBody>
                <div className="table-responsive product-table">
                  <DataTable
                    noHeader
                    pagination
                    paginationServer
                    columns={addColumns}
                    data={addDataAPI}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default StakingPoolsList;
