import React, { Fragment, useState, useEffect } from "react";
import { Breadcrumbs, H5 } from "../../../AbstractElements";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap";
import DataTable from "react-data-table-component";
import { useNavigate, useLocation } from "react-router-dom";
import axios from 'axios'
import Web3 from 'web3'
import { BigNumber } from 'bignumber.js';
// const BN = require("bn.js");
export const style = {
  width: 40,
  height: 40,
};
export const style2 = { width: 60, fontSize: 14, padding: 4 };

function WalletTransactionsListDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  const [dataAPI, setDataAPI] = useState([]);
  const [totalAmountSpent, setTotalAmountSpent] = useState("");

  useEffect(() => {
    console.log(location)
    if (location?.state) {
      setDataAPI(location?.state.dataObj.transactions);
      let totalValue = 0
      location?.state.dataObj.transactions.forEach(element => {
        if (element.from.toLowerCase() == location?.state.dataObj.user_walletaddress.toLowerCase()) {
          totalValue += Number(element.value)
        }
      });
      // const web3 = new Web3();
      // const etherValue = web3.utils.fromWei(totalValue, 'ether');
      console.log("totalValue", totalValue)
      const weiValue = new BigNumber(totalValue);

      // Convert Wei to Ether
      const ether = weiValue.dividedBy(1e18).toString(); // Divide by 10^18 to convert to Ether
      setTotalAmountSpent(ether)
    }

  }, []);

  const addDataAPI = dataAPI?.map((elem) => {
    return {
      blockNumber: elem.blockNumber,
      from: elem.from,
      to: elem.to,
      value: elem.value,
      gasUsed: elem.gasUsed,

    };
  });

  const addColumns = [
    {
      name: "Block",
      selector: (row) => row.blockNumber,
      sortable: true,
      center: true,
    },
    {
      name: "From",
      selector: (row) => row.from,
      sortable: true,
      center: true,
    },
    {
      name: "To",
      selector: (row) => row.to,
      sortable: true,
      center: true,
    },
    {
      name: "Value",
      selector: (row) => row.value,
      sortable: true,
      center: true,
    },
    {
      name: "Gas Used",
      selector: (row) => row.gasUsed,
      sortable: true,
      center: true,
    },
  ];

  return (
    <Fragment>
      <Breadcrumbs
        parent="Wallet Transactions"
        title="Wallet Transactions List"
        mainTitle="Wallet Transactions"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="pb-0">
                <H5>{"Wallet Transactions List"}</H5>
                <H5>{"Total Spent: " + totalAmountSpent} ETH</H5>
              </CardHeader>
              <CardBody>
                <div className="table-responsive product-table">
                  <DataTable
                    noHeader
                    pagination
                    paginationServer
                    columns={addColumns}
                    data={addDataAPI}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default WalletTransactionsListDetails;
