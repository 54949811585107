import React, { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Breadcrumbs } from '../../../AbstractElements';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { insertEmailTemplates, updateEmailTemplates } from '../../../api/api';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { toast } from 'react-toastify';

const EmailTemplates = () => {
  const [typesList, setTypesList] = useState([
    "Signup",
    "Signin",
    "Launch Project Apply",
    "Approve Email",
    "Reject Email",
    "Shares Received",
    "Request of Shares",
    "Donation Received",
    "Donation Send",
    "Investment Received",
    "Investment Send",
  ]);
  const [note, setNote] = useState('');
  const [type, setType] = useState('');
  const [subject, setSubject] = useState('');
  const [content, setContent] = useState('');


  const navigate = useNavigate();
  const location = useLocation();

  const handleAdd = async () => {
    if (type === "") {
      toast.error("Please select a type!");
      return;
    }
    const formData = {
      email_template_type: type,
      email_template_subject: subject,
      email_template_content: content
    };

    const res = await insertEmailTemplates(formData);
    if (res.status === 200) {
      navigate(`${process.env.PUBLIC_URL}/email_templates/email_templates_list`);
    } else {
      alert('Error');
    }
  };
  const handleUpdate = async (id) => {
    if (type === "") {
      toast.error("Please select a type!");
      return;
    }
    const formData = {
      email_template_type: type,
      email_template_subject: subject,
      email_template_content: content
    };

    const res = await updateEmailTemplates(id, formData);
    if (res.status === 200) {
      navigate(`${process.env.PUBLIC_URL}/email_templates/email_templates_list`);
    } else {
      alert('Error occured.');
    }
  };

  const populateData = () => {
    if (location?.state) {
      setType(location?.state?.dataObj?.email_template_type);
      setSubject(location?.state?.dataObj?.email_template_subject);
      setContent(location?.state?.dataObj?.email_template_content);
    }
  };

  useEffect(() => {
    populateData();
  }, []);
  function handleChangeContent(value) {
    setContent(value);
  }
  return (
    <Fragment>
      <Breadcrumbs
        parent="Email Templates"
        title="Add Email Templates"
        mainTitle="Add Email Templates"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Form method="POST">
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{"Select Type"}</Label>
                        <Input
                          className="form-control"
                          value={type}
                          type="select"
                          onChange={(e) => {
                            if (e.target.value === "Signin") {
                              setNote("")
                            } else if (e.target.value === "Signup") {
                              setNote("")
                            } else if (e.target.value === "Launch Project Apply") {
                              setNote("Note: use @project_name or @amount_to_raise keywords to access the corresponding values.")
                            } else if (e.target.value === "Approve Email") {
                              setNote("Note: use @project_name or @amount_to_raise keywords to access the corresponding values.")
                            } else if (e.target.value === "Reject Email") {
                              setNote("Note: use @project_name or @amount_to_raise keywords to access the corresponding values.")
                            } else if (e.target.value === "Shares Received") {
                              setNote("")
                            } else if (e.target.value === "Request of Shares") {
                              setNote("")
                            } else if (e.target.value === "Donation Received") {
                              setNote("Note: use @project_name, @donation_amount or @txn_hash keywords to access the corresponding values.")
                            } else if (e.target.value === "Donation Send") {
                              setNote("Note: use @project_name, @donation_amount or @txn_hash keywords to access the corresponding values.")
                            } else if (e.target.value === "Investment Received") {
                              setNote("Note: use @project_name, @investment_amount or @txn_hash keywords to access the corresponding values.")
                            } else if (e.target.value === "Investment Send") {
                              setNote("Note: use @project_name, @investment_amount or @txn_hash keywords to access the corresponding values.")
                            }
                            setType(e.target.value)
                          }}
                        >
                          <option>Select Type</option>
                          {typesList?.map((item, i) => (
                            <option key={i} value={item}>{item}</option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>{"Subject"}</Label>
                        <Input
                          className="form-control"
                          value={subject}
                          type="text"
                          onChange={(e) => {
                            setSubject(e.target.value)
                          }}
                        >
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{'Email Template Content'}</Label>
                        <br />
                        <label>{note}</label>
                        <ReactQuill
                          value={content}
                          onChange={handleChangeContent}
                          placeholder="Type something here..."
                          modules={{
                            toolbar: [
                              [{ header: [1, 2, 3, 4, false] }],
                              ["bold", "italic", "underline", "strike"],
                              [{ color: [] }, { background: [] }],
                              [{ align: [] }],
                              [{ list: "ordered" }, { list: "bullet" }],
                              ["link", "image", "video"],
                              ["clean"],
                            ],
                          }}
                          formats={[
                            "header",
                            "bold",
                            "italic",
                            "underline",
                            "strike",
                            "color",
                            "background",
                            "align",
                            "list",
                            "bullet",
                            "link",
                            "image",
                            "video",
                          ]}
                          style={{ height: "35vh" }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <FormGroup className="mb-0 mt-5">
                        {location?.state ? (
                          <Button
                            onClick={() =>
                              handleUpdate(location?.state?.dataObj?.email_template_id)
                            }
                            className="me-3"
                            color="success"
                          >
                            {'Update'}
                          </Button>
                        ) : (
                          <Button
                            onClick={handleAdd}
                            className="me-3"
                            color="success"
                          >
                            {'Submit'}
                          </Button>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default EmailTemplates;
