import React, { Fragment, useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  Form,
  FormGroup,
  Label,
} from "reactstrap";
import { Breadcrumbs } from "../../../AbstractElements";
import { getVotingPowerCriteria, updateVotingPowerCriteria } from "../../../api/api";
import { toast } from "react-toastify";

const AntsVotingPowerCriteria = () => {
  const [token, setToken] = useState("");

  const [idState, setIdState] = useState([]);

  useEffect(() => {
    getVotingPowerCriteria()
      .then((res) => {
        console.log("res", res)
        setIdState(res?.data[0].voting_power_id);
        setToken(res?.data[0].voting_power_tokens);

      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const updateData = async (e) => {
    e.preventDefault();
    const formData = {
      voting_power_tokens: token
    }

    await updateVotingPowerCriteria(idState, formData);
    toast.success(`Criteria Updated!`);
  };


  return (
    <>
      <Fragment>
        <Breadcrumbs
          parent="Voting Power Criteria"
          title="Voting Power Criteria Section"
          mainTitle="Voting Power Criteria Section"
        />
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardBody>
                  <Form>
                    <Row>
                      <Col lg={6}>
                        <FormGroup>
                          <Label>{"Tokens"}</Label>
                          <p>How many tokens should each vote cost?</p>
                          <input
                            type="number"
                            placeholder="Token"
                            className="form-control"
                            value={token}
                            onChange={(e) => setToken(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup className="mb-0">
                          <Button
                            onClick={updateData}
                            color="success"
                          >
                            Update
                          </Button>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment>
    </>
  );
};

export default AntsVotingPowerCriteria;
