import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { Btn, H4, P } from "../AbstractElements";

import {
  EmailAddress,
  Password,
} from "../Constant";
import SocialAuth from "./Tabs/LoginTab/SocialAuth";
import axios from "axios";
import { authUrl } from "../api/api";

const Signup = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [togglePassword, setTogglePassword] = useState(false);
  const navigate = useNavigate();

  const onSignUp = (e) => {
    e.preventDefault();
    setLoading(true)
    
    const formData = {
      user_name: name,
      user_email: email,
      user_password: password
    }

    axios.post(`${authUrl}/signup`, formData).then((res) => {
    //   console.log(res.data);
      setLoading(false)
      navigate(`${process.env.PUBLIC_URL}/login`);
    }).catch((error) => console.log(error));
  };
  return (
    <>
      <Container fluid={true} className="p-0">
        <Row>
          <Col xs="12">
            <div className="login-card">
              <div className="login-main login-tab">
                <Fragment>
                  <Form className="theme-form">
                    <H4>Sign Up</H4>
                    <P>{"Enter Your Credentials"}</P>
                    <FormGroup>
                      <Label className="col-form-label">{"Name"}</Label>
                      <Input
                        className="form-control"
                        type="text"
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                        required
                        placeholder="Enter Name"
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label className="col-form-label">{EmailAddress}</Label>
                      <Input
                        className="form-control"
                        type="email"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        required
                        placeholder="Enter Email"
                      />
                    </FormGroup>
                    <FormGroup className="position-relative">
                      <Label className="col-form-label">{Password}</Label>
                      <Input
                        className="form-control"
                        type={togglePassword ? "text" : "password"}
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                        required
                        placeholder="Enter Password"
                      />
                      <div
                        className="show-hide"
                        onClick={() => setTogglePassword(!togglePassword)}
                      >
                        <span className={togglePassword ? "" : "show"}></span>
                      </div>
                    </FormGroup>
                    <div className="form-group mb-0 mt-5">
                      <Btn
                        attrBtn={{
                          color: "primary",
                          className: "btn-block w-100",
                          disabled: loading ? loading : loading,
                          onClick: (e) => onSignUp(e),
                        }}
                      >
                        {loading ? "LOADING..." : "Sign Up"}
                      </Btn>
                    </div>
                    <SocialAuth link={`${process.env.PUBLIC_URL}/login`} linktext={'Sign In'} text={'Already Have An Account?'} />
                  </Form>
                </Fragment>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Signup;