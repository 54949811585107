import React, { Fragment, useState } from 'react';
import { Breadcrumbs } from '../../../AbstractElements';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';
import { insertKnowledge } from '../../../api/api';
export default function AddKnowledge() {
  const navigate = useNavigate();

  const [title, settitle] = useState('');
  const [link, setlink] = useState('');

  const handleAdd = async () => {
    if (!title) {
      return alert('Please Enter the Title');
    }
    if (!link) {
      return alert('Please Paste the Youtube Link');
    }
    const formData = { title, link };

    const res = await insertKnowledge(formData);

    if (res.status === 200) {
      navigate(`${process.env.PUBLIC_URL}/app/listknowledge`);
      console.log('added Successfully');
    } else {
      alert('Error');
    }
  };
  return (
    <Fragment>
      <Breadcrumbs
        parent="Knowledge"
        title="Add Knowledge"
        mainTitle="Add Knowledge"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Form method="POST">
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{'Title of Video'}</Label>
                        <Input
                          onChange={(e) => settitle(e.target.value)}
                          value={title}
                          type="text"
                          className="form-control"
                          required
                          placeholder="Enter Title"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{'Youtube Link'}</Label>
                        <Input
                          onChange={(e) => setlink(e.target.value)}
                          value={link}
                          type="text"
                          className="form-control"
                          required
                          placeholder="Paste Youtube Link"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup className="mb-0">
                        <Button
                          onClick={handleAdd}
                          className="me-3"
                          color="success"
                        >
                          {'Submit'}
                        </Button>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}
