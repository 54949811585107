import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Breadcrumbs } from "../../../AbstractElements";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import toast from "react-hot-toast";
import Web3 from "web3";
import ABI_MyContract from "../../../api/abi-mycontract";
import { editproposal, insertproposal } from "../../../api/api";

const AddProposals = () => {
  const [title, setTitle] = useState("");
  const [dec, setDec] = useState("");
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [executed, setExecuted] = useState("");
  const [createdBy, setCreatedBy] = useState("");

  const navigate = useNavigate();
  const location = useLocation();

  const handleAdd = async () => {
    if (!title || !dec || !start || !end) {
      toast.error("Please enter all fields");

      return;
    }
    const formData = {
      proposal_title: title,
      proposal_description: dec,
      proposal_start: start,
      proposal_end: end,
      proposal_created: "admin",
    };
    console.log("adding");

    const res = await insertproposal(formData);

    if (res.status === 200) {
      navigate(`${process.env.PUBLIC_URL}/proposals/proposals_list`);
    } else {
      toast.error("Error occure in adding data");
    }
  };
  const handleUpdate = async (id) => {
    const formData = {
      proposal_title: title,
      proposal_description: dec,
      proposal_start: start,
      proposal_end: end,
      proposal_created: "admin",
    };

    const res = await editproposal(id, formData);
    if (res.status === 200) {
      navigate(`${process.env.PUBLIC_URL}/proposals/proposals_list`);
    } else {
      toast.error("Error occure in updating data");
    }
  };

  const populateData = () => {
    if (location?.state?.dataObj) {
      const {
        proposal_title,
        proposal_description,
        proposal_start,
        proposal_end,
      } = location.state.dataObj;
      setTitle(proposal_title);
      setDec(proposal_description);
      setStart(proposal_start);
      setEnd(proposal_end);
    }
  };

  useEffect(() => {
    populateData();
  }, []);

  return (
    <Fragment>
      <Breadcrumbs parent="Home" title="Add Pools" mainTitle="Add Pools" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Form method="POST">
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{"Title"}</Label>
                        <Input
                          onChange={(e) => setTitle(e.target.value)}
                          value={title}
                          type="text"
                          className="form-control"
                          required
                          placeholder="Title"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <FormGroup>
                        <Label>{"Description"}</Label>
                        <Input
                          onChange={(e) => setDec(e.target.value)}
                          value={dec}
                          type="text"
                          className="form-control"
                          required
                          placeholder="Description"
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={6}>
                      <FormGroup>
                        <Label>{"Start (Date and Time)"}</Label>
                        <Input
                          onChange={(e) => setStart(e.target.value)}
                          value={start}
                          type="datetime-local"
                          className="form-control"
                          required
                          placeholder="Start (Date and Time)"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <FormGroup>
                        <Label>{"End (Date and Time)"}</Label>
                        <Input
                          onChange={(e) => setEnd(e.target.value)}
                          value={end}
                          type="datetime-local"
                          className="form-control"
                          required
                          placeholder="End (Date and Time)"
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={6}>
                      <FormGroup>
                        <Label>{"Proposal Created"}</Label>
                        <Input
                          value={"admin"}
                          type="text"
                          className="form-control"
                          required
                          placeholder="Created"
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <FormGroup className="mb-0">
                        {location?.state ? (
                          <Button
                            onClick={() =>
                              handleUpdate(
                                location?.state?.dataObj?.proposal_id
                              )
                            }
                            className="me-3"
                            color="success"
                          >
                            {"Update"}
                          </Button>
                        ) : (
                          <Button
                            className="me-3"
                            color="success"
                            onClick={handleAdd}
                          >
                            {"Submit"}
                          </Button>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default AddProposals;
