import React, { Fragment, useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  Form,
  FormGroup,
  Label,
} from "reactstrap";
import { Breadcrumbs } from "../../../AbstractElements";
import { getNgoPage, getProfitablePage, updateNgoPage, updateProfitablePage } from "../../../api/api";
import { toast } from "react-toastify";
import { TagsInput } from 'react-tag-input-component';
import Dropzone from 'react-dropzone-uploader';

const SeoProfitablePage = () => {
  const [metaTitle, setMetaTitle] = useState("");
  const [metaTags, setMetaTags] = useState([]);
  const [metaDescription, setMetaDescription] = useState("");
  const [metaImage, setMetaImage] = useState("");
  const [metaOnlineImage, setMetaOnlineImage] = useState("");

  const [idState, setIdState] = useState([]);

  useEffect(() => {
    getProfitablePage()
      .then((res) => {
        console.log("res", res)
        setIdState(res?.data[0].profitable_id);
        setMetaTitle(res?.data[0].profitable_meta_title);
        if (res?.data[0].profitable_meta_tags) {
          setMetaTags(JSON.parse(res?.data[0].profitable_meta_tags))
        } 
        if (res?.data[0].profitable_meta_image) {
          setMetaOnlineImage(res?.data[0].profitable_meta_image)
        }
        setMetaDescription(res?.data[0].profitable_meta_description);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const updateData = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("profitable_meta_title", metaTitle)
    formData.append("profitable_meta_tags", JSON.stringify(metaTags))
    formData.append("profitable_meta_description", metaDescription)
    formData.append("profitable_meta_image", metaImage)

    await updateProfitablePage(idState, formData);
    toast.success(`SEO Updated!`);
  };

  const handleChangeStatus = ({ meta, file }, status) => {
    setMetaOnlineImage("")
    setMetaImage(file);
  };

  return (
    <>
      <Fragment>
        <Breadcrumbs
          parent="Profitable Page"
          title="Profitable Page Section"
          mainTitle="Profitable Page Section"
        />
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardBody>
                  <Form>
                    <Row>
                      <Col lg={6}>
                        <FormGroup>
                          <Label>{"Meta Title"}</Label>
                          <input
                            placeholder="Meta Title"
                            className="form-control"
                            value={metaTitle}
                            onChange={(e) => setMetaTitle(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg={6}>
                        <FormGroup>
                          <Label>{'Meta Tags'}</Label>
                          <TagsInput
                            value={metaTags}
                            onChange={setMetaTags}
                            placeHolder="Enter Keywords"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg={6}>
                        <FormGroup>
                          <Label>{'Meta Image'}</Label>
                          <Dropzone
                            onChangeStatus={handleChangeStatus}
                            inputContent="Drop An Image"
                            multiple={false}
                            canCancel={true}
                            maxFiles={1}
                            styles={{
                              dropzone: { width: '100%', minHeight: 50 },
                              dropzoneActive: { borderColor: 'green' },
                            }}
                          />
                          {metaOnlineImage !== "" ?
                            <img style={{height:65, width:65, marginTop:10}} className='img-fluid' src={`${global.backendUrl}/nodeassets/${metaOnlineImage}`} />
                            : null}
                        </FormGroup>
                      </Col>
                      <Col lg={6}>
                        <FormGroup>
                          <Label>{"Meta Description"}</Label>
                          <textarea
                            placeholder="Meta Description"
                            className="form-control"
                            value={metaDescription}
                            onChange={(e) => setMetaDescription(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup className="mb-0">
                          <Button
                            onClick={updateData}
                            color="success"
                          >
                            Update
                          </Button>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment>
    </>
  );
};

export default SeoProfitablePage;
