import React, { Fragment, useState, useEffect } from "react";
import { Breadcrumbs, H5, Image } from "../../../../AbstractElements";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { getprivateSale, deleteprivateSale } from "../../../../api/api";
export const style = {
  width: 40,
  height: 40,
};
export const style2 = { width: 60, fontSize: 14, padding: 4 };
function AllPrivateSaleInfo() {
    const navigate = useNavigate();
    const [dataAPI, setDataAPI] = useState([]);
  
    const populateData = async () => {
      const res = await getprivateSale();
      setDataAPI([...res.data]);
    };
  
    // console.log(`dataAPI`, dataAPI);
    const deleteDataItem = async (id) => {
      const res = await deleteprivateSale(id);
      if (res.status === 200) {
        const freshArray = dataAPI.filter((val) => val.private_sale_id !== id);
        setDataAPI(freshArray);
      }
    };
  
    useEffect(() => {
      populateData();
    }, []);
  
    const addDataAPI = dataAPI?.map((elem) => {
      return {
 
       startTime: elem.private_sale_start_time,
        endTime: elem.private_sale_end_time,
        tokenPrice: elem.private_sale_token_price,
        status: elem.private_sale_status,
        totalToken: elem.private_total_token,
         
  
        action: (
          <div>
            <span>
              <Button
                onClick={() => deleteDataItem(elem.private_sale_id)}
                className="btn btn-danger btn-xs"
                style={style2}
              >
                Delete
              </Button>
            </span>{" "}
            &nbsp;&nbsp;
            <span>
              <Button
                onClick={() => {
                  navigate(`${process.env.PUBLIC_URL}/privateSale/add_saleInfo`, {
                    state: { dataObj: elem },
                  });
                }}
                className="btn btn-success btn-xs"
                style={style2}
              >
                Edit{" "}
              </Button>
            </span>
          </div>
        ),
      };
    });
  
    const addColumns = [
 
      {
        name: "Start Time",
        selector: (row) => row.startTime,
        sortable: true,
        center: true,
      },
      {
        name: "End Time",
        selector: (row) => row.endTime,
        sortable: true,
        center: true,
      },
      {
        name: "Token Price",
        selector: (row) => row.tokenPrice,
        sortable: true,
        center: true,
      },
      {
        name: "Total Token",
        selector: (row) => row.totalToken,
        sortable: true,
        center: true,
      },
      {
        name: "Status",
        selector: (row) => row.status,
        sortable: true,
        center: true,
      },
 
      {
        name: "Action",
        selector: (row) => row.action,
        sortable: true,
        center: true,
      },
    ];
  return (
    <Fragment>
      <Breadcrumbs
        parent="Home"
        title="Private Sale Info"
        mainTitle="Private Sale"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="pb-0">
                <H5>{"Private Sale Info"}</H5>
              </CardHeader>
              <CardBody>
                <div className="table-responsive product-table">
                  <DataTable
                    noHeader
                    pagination
                    paginationServer
                    columns={addColumns}
                    data={addDataAPI}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}

export default AllPrivateSaleInfo
