import React, { Fragment, useState, useEffect } from "react";
import { Breadcrumbs, H5, Image } from "../../../AbstractElements";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Row,
} from "reactstrap";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import './UsersList.css'
// import { deleteProjectCategory, getProjectCategory } from "../../../api/api";
import { deleteUserById, getProject, getProjectCate } from "../../../api/api";
import axios from "axios";
import Select from 'react-select';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export const style = {
    width: 40,
    height: 40,
};
export const style2 = { width: 60, fontSize: 14, padding: 4 };

function UsersList() {
    const navigate = useNavigate();
    const [dataAPI, setDataAPI] = useState([]);
    // const [data, setdata] = useState([])

    const populateData = () => {
        axios.get(`${global.backendUrl}/signup/get`)
            .then((response) => {
                setDataAPI(response.data)
            }).catch((err) => {
                console.log(err)
            })
    };


    useEffect(() => {
        populateData();
    }, []);
    const deleteUser = async(id) => {
        const res = await deleteUserById(id);
        if (res.status === 200) {
            populateData()   
        }
    }

    const option = [
        { value: 'hot pick', label: 'Hot Pick' },
        { value: 'top projects', label: 'Top Projects' },
        { value: 'feature', label: 'Feature' },
    ]

    const addDataAPI = dataAPI?.map((elem) => {

        return {
            Name: `${elem.name}`,
            Email: `${elem.email}`,

            Status: (
                <select defaultValue={elem.status} onChange={(e) => handleChange(elem, e.target.value)}>
                    <option>Select</option>
                    <option value='Feature'>Feature</option>
                    <option value=''>Un Feature</option>
                </select>
            ),
            action: (
                <div>
                    <span>
                        <Button
                            onClick={() => navigate(`/user/${elem.uid}`)}
                            className="btn btn-success btn-xs"
                            style={style2}
                        >
                            View
                        </Button>
                        <Button
                            onClick={() => {
                                deleteUser(elem.uid)
                            }}
                            className="btn btn-danger btn-xs ms-2"
                            style={style2}
                        >
                            Delete
                        </Button>
                    </span>
                </div>
            ),
        };
    });

    const addColumns = [
        {
            name: "User Name",
            selector: (row) => row.Name,
            sortable: true,
            center: true,
        },
        {
            name: "Email",
            selector: (row) => row.Email,
            sortable: true,
            center: true,
        },
        {
            name: "Status",
            selector: (row) => row.Status,
            sortable: true,
            center: true,
        },
        {
            name: "Action",
            selector: (row) => row.action,
            sortable: true,
            center: true,
        },
    ];

    const handleChange = (elem, value) => {
        if (value == 'Select')
            return
        if (elem.status == value)
            return
        if (elem.status == null && !value)
            return

        const filterOne = dataAPI.filter((t) => t.status == "Feature")
        if (filterOne.length < 8) {
            handleOkay(elem, value)
        } else {
            toast.error(`Feature Users length is Full`, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    const handleOkay = (elem, value) => {
        const formData = { status: value }
        axios.put(`${global.backendUrl}/signup/status/${elem.uid}`, formData)
            .then((response) => {
                toast.success(`Updated Successfully`, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                populateData();
            }).catch((err) => {
                console.log(err)
            })
    }



    return (
        <Fragment>
            <Breadcrumbs
                parent="Users"
                title="Users List"
                mainTitle="Users List"
            />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader className="pb-0">
                                <H5>{"Users List"}</H5>
                            </CardHeader>
                            <CardBody>
                                <div className="table-responsive product-table">
                                    <DataTable
                                        noHeader
                                        pagination
                                        paginationServer
                                        columns={addColumns}
                                        data={addDataAPI}
                                    />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}

export default UsersList;
