import React, { Fragment, useState, useEffect } from "react";
import { Breadcrumbs, H5, Image } from "../../../../AbstractElements";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { deleteHeroSection, getHeroSection, serverImageUrl } from "../../../../api/api";
export const style = {
  width: 40,
  height: 40,
};
export const style2 = { width: 60, fontSize: 14, padding: 4 };

function PrivateSaleTansaction() {
  const navigate = useNavigate();
  const [dataAPI, setDataAPI] = useState([]);

  const populateData = async () => {
    const res = await getHeroSection();
    setDataAPI([...res.data]);
  };

  // console.log(`dataAPI`, dataAPI);
  const deleteDataItem = async (id) => {
    const res = await deleteHeroSection(id);
    if (res.status === 200) {
      const freshArray = dataAPI.filter((val) => val.hero_id !== id);
      setDataAPI(freshArray);
    }
  };

  useEffect(() => {
    populateData();
  }, []);

  const addDataAPI = dataAPI?.map((elem) => {
    return {
      // image: (
      //   <Image
      //     attrImage={{
      //       src: serverImageUrl + elem.hero_image,
      //       style: style,
      //       alt: "Unavailable",
      //     }}
      //   />
      // ),
      WalletAddress: elem.hero_title,
      TransactionType: elem.hero_subtitle,
      NumberOfTokens: elem.hero_text,
      DateAndTimeofTransactions: elem.hero_text,
      // BtnText: elem.hero_btnText,

    
    };
  });

  const addColumns = [
 
    {
      name: "User",
      selector: (row) => row.Title,
      sortable: true,
      center: true,
    },
    {
      name: "Purchanse Token",
      selector: (row) => row.SubTitle,
      sortable: true,
      center: true,
    },
    {
      name: "Price",
      selector: (row) => row.Text,
      sortable: true,
      center: true,
    },
   
   
  ];
  return (
    <Fragment>
    <Breadcrumbs
      parent="Home"
      title="Private Sale Transactions"
      mainTitle="Private Sale Transactions"
    />
    <Container fluid={true}>
      <Row>
        <Col sm="12">
          <Card>
            <CardHeader className="pb-0">
              <H5>{"Private Sale Transaction List"}</H5>
            </CardHeader>
            <CardBody>
              <div className="table-responsive product-table">
                <DataTable
                  noHeader
                  pagination
                  paginationServer
                  columns={addColumns}
                  data={addDataAPI}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  </Fragment>
  )
}

export default PrivateSaleTansaction
