import React, { Fragment, useState, useEffect } from 'react';
import { Breadcrumbs, H5, Image } from '../../AbstractElements';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from 'reactstrap';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
// import { deleteProjectCategory, getProjectCategory } from "../../../api/api";
import {
  getProjectType,
  deleteProjectType,
  getProjectCate,
  deleteProjectCate,
} from '../../api/api';

export const style = {
  width: 40,
  height: 40,
};
export const style2 = { width: 60, fontSize: 14, padding: 4 };

function ProjectCateList() {
  const navigate = useNavigate();
  const [dataAPI, setDataAPI] = useState([]);

  const populateData = async () => {
    const res = await getProjectCate();
    setDataAPI([...res.data]);
  };

  const deleteDataItem = async (id) => {
    const res = await deleteProjectCate(id);
    if (res.status === 200) {
      const freshArray = dataAPI.filter((val) => val.cate_id !== id);
      setDataAPI(freshArray);
    }
  };

  useEffect(() => {
    populateData();
  }, []);

  const addDataAPI = dataAPI?.map((elem) => {
    return {
      Image: (
        <Image
          attrImage={{
            src: `${global.backendUrl}/nodeassets/${elem.cate_img}`,
            style: style,
            alt: '',
          }}
        />
      ),
      Name: `${elem.cate_name}`,
      Type: `${elem.project_type}`,

      action: (
        <div>
          <span>
            <Button
              onClick={() => deleteDataItem(elem.cate_id)}
              className="btn btn-danger btn-xs"
              style={style2}
            >
              Delete
            </Button>
          </span>{' '}
          &nbsp;&nbsp;
          <span>
            <Button
              onClick={() => {
                navigate(`${process.env.PUBLIC_URL}/project/addCategory`, {
                  state: { dataObj: elem },
                });
              }}
              className="btn btn-success btn-xs"
              style={style2}
            >
              Edit{' '}
            </Button>
          </span>
        </div>
      ),
    };
  });

  const addColumns = [
    {
      name: 'Image',
      selector: (row) => row.Image,
      sortable: true,
      center: true,
    },
    {
      name: 'Name',
      selector: (row) => row.Name,
      sortable: true,
      center: true,
    },
    {
      name: 'Project Type',
      selector: (row) => row.Type,
      sortable: true,
      center: true,
    },
    {
      name: 'Action',
      selector: (row) => row.action,
      sortable: true,
      center: true,
    },
  ];

  return (
    <Fragment>
      <Breadcrumbs
        parent="Types"
        title="Project Types List"
        mainTitle="Project Types List"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="pb-0">
                <H5>{'Project Types List'}</H5>
              </CardHeader>
              <CardBody>
                <div className="table-responsive product-table">
                  <DataTable
                    noHeader
                    pagination
                    paginationServer
                    columns={addColumns}
                    data={addDataAPI}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default ProjectCateList;
