import React, { Fragment, useEffect, useState } from "react";
import { Breadcrumbs } from "../../../AbstractElements";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { getAdditionalStaking } from "../../../api/api";

const AddAdditionalStaking = () => {
  const [burnToken, setBurnToken] = useState("");
  const [voting, setVoting] = useState("");
  const [tokens, setTokens] = useState("");
  const [isDataExist, setIsDataExist] = useState(false);


  const populateData = async () => {
    try {
      const res = await getAdditionalStaking();
      if (res?.data?.data) {
        const stakingData = res.data.data;
        setBurnToken(stakingData.burn_token || "");
        setVoting(stakingData.voting_quorum || "");
        setTokens(stakingData.minimum_token || "");
        setIsDataExist(true);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    populateData();
  }, []);

  const handleAdd = async () => {
    if(!burnToken || !voting || !tokens    ){
      return alert('all fields required')

    }
    try {
      const response = await fetch(`${global.backendUrl}/api/additional-staking/post`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          burn_token: burnToken,
          voting_quorum: voting,
          minimum_token: tokens,
        }),
      });
      if (response.ok) {
        alert("Data added successfully");
      } else {
        alert("Error adding data");
      }
    } catch (error) {
      console.error("Error adding data:", error);
    }
  };

  const handleUpdate = async () => {
    if(!burnToken || !voting || !tokens    ){
      return alert('all fields required')
    }
    try {
      const response = await fetch(`${global.backendUrl}/api/additional-staking/put`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          burn_token: burnToken,
          voting_quorum: voting,
          minimum_token: tokens,
        }),
      });
      if (response.ok) {
        alert("Data updated successfully");
      } else {
        alert("Error updating data");
      }
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  return (
    <Fragment>
      <Breadcrumbs
        parent="Home"
        title="Additional Staking"
        mainTitle="Additional Staking"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Form method="POST">
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{"Update Burn Tokens"}</Label>
                        <Input
                          onChange={(e) => setBurnToken(e.target.value)}
                          value={burnToken}
                          type="number"
                          className="form-control"
                          required
                          placeholder="Enter Burn Tokens"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <FormGroup>
                        <Label>{"Update Voting Quorum"}</Label>
                        <Input
                          onChange={(e) => setVoting(e.target.value)}
                          value={voting}
                          type="number"
                          className="form-control"
                          required
                          placeholder="Enter Voting Quorum"
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={6}>
                      <FormGroup>
                        <Label>{"Minimum Tokens"}</Label>
                        <Input
                          onChange={(e) => setTokens(e.target.value)}
                          value={tokens}
                          type="number"
                          className="form-control"
                          required
                          placeholder="Enter Minimum Tokens"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup className="mb-0">
                        {isDataExist ? (
                          <Button
                            onClick={handleUpdate}
                            className="me-3"
                            color="success"
                          >
                            {"Update"}
                          </Button>
                        ) : (
                          <Button
                            onClick={handleAdd}
                            className="me-3"
                            color="success"
                          >
                            {"Add"}
                          </Button>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default AddAdditionalStaking;
