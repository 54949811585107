import React, { Fragment, useState, useEffect } from "react";
import { Breadcrumbs, H5 } from "../../../AbstractElements";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { deleteWalletAssocaite, getWalletAssocaite } from "../../../api/api";

export const style = {
  width: 40,
  height: 40,
};
export const style2 = { width: 60, fontSize: 14, padding: 4 };

function WalletAssociationList() {
  const navigate = useNavigate();
  const [dataAPI, setDataAPI] = useState([]);

  const populateData = async () => {
    const res = await getWalletAssocaite();
    setDataAPI([...res.data]);
  };

  // console.log(`dataAPI`, dataAPI);
  const deleteDataItem = async (id) => {
    const res = await deleteWalletAssocaite(id);
    if (res.status === 200) {
      const freshArray = dataAPI.filter((val) => val.wallet_id !== id);
      setDataAPI(freshArray);
    }
  };

  useEffect(() => {
    populateData();
  }, []);

  const addDataAPI = dataAPI?.map((elem) => {
    return {
      WalletName: elem.wallet_name,
      Address: elem.wallet_address,
      Percentage: elem.wallet_percentage,

      action: (
        <div>
          <span>
            <Button
              onClick={() => deleteDataItem(elem.wallet_id)}
              className="btn btn-danger btn-xs"
              style={style2}
            >
              Delete
            </Button>
          </span>
          &nbsp;&nbsp;
          <span>
            <Button
              onClick={() => {
                navigate(
                  `${process.env.PUBLIC_URL}/wallet_Association/add_wallet_association`,
                  {
                    state: { dataObj: elem },
                  }
                );
              }}
              className="btn btn-success btn-xs"
              style={style2}
            >
              Edit
            </Button>
          </span>
        </div>
      ),
    };
  });

  const addColumns = [
    {
      name: "Name",
      selector: (row) => row.WalletName,
      sortable: true,
      center: true,
    },
    {
      name: "Address",
      selector: (row) => row.Address,
      sortable: true,
      center: true,
    },
    {
      name: "Percentage(%)",
      selector: (row) => row.Percentage,
      sortable: true,
      center: true,
    },
    {
      name: "Action",
      selector: (row) => row.action,
      sortable: true,
      center: true,
    },
  ];

  return (
    <Fragment>
      <Breadcrumbs
        parent="Wallet Association List"
        title="Wallet Association List"
        mainTitle="Wallet Association List"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <div className="table-responsive product-table">
                  <DataTable
                    noHeader
                    pagination
                    paginationServer
                    columns={addColumns}
                    data={addDataAPI}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default WalletAssociationList;
