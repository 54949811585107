import React, { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Breadcrumbs } from '../../../AbstractElements';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { insertCaseStudiesStatistics, updateCaseStudiesStatistics } from '../../../api/api';

const AddCaseStudiesStatistics = () => {
  const [card, setCard] = useState('');

  const [cardsList, setCardsList] = useState([
    "Card 1",
    "Card 2",
    "Card 3"
  ]);
  const [pointsList, setPointsList] = useState([
    {
      key: "", value: ""
    }
  ]);

  const navigate = useNavigate();
  const location = useLocation();

  const handleAdd = async () => {
    const formData = {
      case_studies_statistics_card: card,
      case_studies_statistics_points: JSON.stringify(pointsList),
    };

    const res = await insertCaseStudiesStatistics(formData);
    if (res.status === 200) {
      navigate(`${process.env.PUBLIC_URL}/launch_page/case_studies_statistics_list`);
    } else {
      alert('Error');
    }
  };
  const handleUpdate = async (id) => {
    const formData = {
      case_studies_statistics_card: card,
      case_studies_statistics_points: JSON.stringify(pointsList),
    };

    const res = await updateCaseStudiesStatistics(id, formData);
    if (res.status === 200) {
      navigate(`${process.env.PUBLIC_URL}/launch_page/case_studies_statistics_list`);
    } else {
      alert('Error occured.');
    }
  };

  const populateData = () => {
    if (location?.state) {
      setCard(location?.state?.dataObj?.case_studies_statistics_card);
      const points = JSON.parse(location?.state?.dataObj?.case_studies_statistics_points)
      setPointsList(points);
    }
  };
  const addPointsRow = () => {
    let tempArray = [...pointsList];
    tempArray.push(
      {
        key: "", value: ""
      }
    )
    setPointsList(tempArray)
  };
  const removePointsRow = (index) => {
    let tempArray = [...pointsList];
    tempArray.splice(index, 1)
    setPointsList(tempArray)
  };
  const updatePointsKey = (value, index) => {
    let tempArray = [...pointsList];
    tempArray[index].key = value;
    setPointsList(tempArray)
  };
  const updatePointsValue = (value, index) => {
    let tempArray = [...pointsList];
    tempArray[index].value = value;
    setPointsList(tempArray)
  };

  useEffect(() => {
    populateData();
  }, []);

  return (
    <Fragment>
      <Breadcrumbs
        parent="Case Studies Statistics"
        title="Add Case Studies Statistics"
        mainTitle="Add Case Studies Statistics"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Form method="POST">
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{"Select Card"}</Label>
                        <Input
                          className="form-control"
                          value={card}
                          type="select"
                          onChange={(e) => {
                            setCard(e.target.value)
                          }}
                        >
                          <option>Select Card</option>
                          {cardsList?.map((item, i) => (
                            <option key={i} value={item}>{item}</option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <h5>Properties</h5>
                    {pointsList.map((item, index) => {
                      return (
                        <>
                          <Col md={pointsList.length > 1 ? 5 : 6}>
                            <FormGroup>
                              <Label>{"Key"}</Label>
                              <Input
                                className="form-control"
                                value={item.key}
                                type="text"
                                onChange={(e) => {
                                  updatePointsKey(e.target.value, index)
                                }}
                              >
                              </Input>
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              <Label>{"Value"}</Label>
                              <Input
                                className="form-control"
                                value={item.value}
                                type="text"
                                onChange={(e) => {
                                  updatePointsValue(e.target.value, index)
                                }}
                              >
                              </Input>
                            </FormGroup>
                          </Col>
                          {pointsList.length > 1 &&
                            <Col md={1}>
                              <p className="mt-2">{" "}</p>
                              <label>{" "}</label>
                              <Button
                                onClick={() =>
                                  removePointsRow(index)
                                }
                                className="me-3"
                                color="danger"
                              >
                                {'-'}
                              </Button>
                            </Col>
                          }
                        </>
                      )
                    })}
                  </Row>
                  <Button
                    onClick={() =>
                      addPointsRow()
                    }
                    className="me-3"
                    color="success"
                  >
                    {'Add More Points'}
                  </Button>
                  <Row>
                    <Col>
                      <FormGroup className="mb-0 mt-2">
                        {location?.state ? (
                          <Button
                            onClick={() =>
                              handleUpdate(location?.state?.dataObj?.case_studies_statistics_id)
                            }
                            className="me-3"
                            color="success"
                          >
                            {'Update'}
                          </Button>
                        ) : (
                          <Button
                            onClick={handleAdd}
                            className="me-3"
                            color="success"
                          >
                            {'Submit'}
                          </Button>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default AddCaseStudiesStatistics;
