import React, { Fragment, useState, useEffect } from "react";
import { Breadcrumbs, H5, Image } from "../../../AbstractElements";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { deleteProjectCategory, getProjectCategory } from "../../../api/api";

export const style = {
  width: 40,
  height: 40,
};
export const style2 = { width: 60, fontSize: 14, padding: 4 };

function CategoriesList() {
  const navigate = useNavigate();
  const [dataAPI, setDataAPI] = useState([]);

  const populateData = async () => {
    const res = await getProjectCategory();
    setDataAPI([...res.data]);
  };

  const deleteDataItem = async (id) => {
    const res = await deleteProjectCategory(id);
    if (res.status === 200) {
      const freshArray = dataAPI.filter((val) => val.category_id !== id);
      setDataAPI(freshArray);
    }
  };

  useEffect(() => {
    populateData();
  }, []);

  const addDataAPI = dataAPI?.map((elem) => {
    return {
      Name: `${elem.category_name}`,
      Text: `${elem.category_text}`,

      action: (
        <div>
          <span>
            <Button
              onClick={() => deleteDataItem(elem.category_id)}
              className="btn btn-danger btn-xs"
              style={style2}
            >
              Delete
            </Button>
          </span>{" "}
          &nbsp;&nbsp;
          <span>
            <Button
              onClick={() => {
                navigate(`${process.env.PUBLIC_URL}/projects/add_category`, {
                  state: { dataObj: elem },
                });
              }}
              className="btn btn-success btn-xs"
              style={style2}
            >
              Edit{" "}
            </Button>
          </span>
        </div>
      ),
    };
  });

  const addColumns = [
    {
      name: "Name",
      selector: (row) => row.Name,
      sortable: true,
      center: true,
    },

    {
      name: "Text",
      selector: (row) => row.Text,
      sortable: true,
      center: true,
    },
    {
      name: "Action",
      selector: (row) => row.action,
      sortable: true,
      center: true,
    },
  ];

  return (
    <Fragment>
      <Breadcrumbs
        parent="Category"
        title="Category List"
        mainTitle="Category List"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="pb-0">
                <H5>{"Category List"}</H5>
              </CardHeader>
              <CardBody>
                <div className="table-responsive product-table">
                  <DataTable
                    noHeader
                    pagination
                    paginationServer
                    columns={addColumns}
                    data={addDataAPI}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default CategoriesList;
