import React, { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Breadcrumbs } from '../../../../AbstractElements';
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import toast from 'react-hot-toast';
import Web3 from "web3";
import ABI_MyContract from '../../../../api/abi-mycontract';
import { insertwhitelist, editwhitelist} from '../../../../api/api'

function AddWhiteList() {
  const [walletAddress, setWalletAddress] = useState('');
 
  

  const navigate = useNavigate();
  const location = useLocation();


  const handleAdd = async () => {
    if(!walletAddress){
      toast.error("Please enter wallet address fields")
return
    }
    const formData = {
      wallet_address: walletAddress,
 
    };

    const res = await insertwhitelist(formData);
    
    if (res.status === 200) {
      navigate(`${process.env.PUBLIC_URL}/whitelist/all_whitelists`);
    } else {
      toast.error("Error occure in adding address")

    }
  };



  const handleUpdate = async (id) => {
    const formData = {
      wallet_address: walletAddress,
 
    };

    const res = await editwhitelist(id, formData);
    if (res.status === 200) {
      navigate(`${process.env.PUBLIC_URL}/whitelist/all_whitelists`);
    } else {
      toast.error("Error occure in updating address")
    }
  };

  const populateData = () => {
    if (location?.state?.dataObj) {
     const {wallet_address}=location.state.dataObj;
     setWalletAddress(wallet_address)
  
    }
  };
  

  useEffect(() => {
    populateData();
  }, []);
  return (
    <Fragment>
    <Breadcrumbs
      parent="Home"
      title="Whitelist"
      mainTitle="Whitelist"
    />
    <Container fluid={true}>
      <Row>
        <Col sm="12">
          <Card>
            <CardBody>
              <Form method="POST">
                <Row>
                  <Col>
                    <FormGroup>
                      <Label>{'Wallet address'}</Label>
                      <Input
                        onChange={(e) => setWalletAddress(e.target.value)}
                        value={walletAddress}
                        type="text"
                        className="form-control"
                        required
                        placeholder="wallet address"
                      />
                    </FormGroup>
                  </Col>
                </Row>
              
                <Row>
                  <Col>
                    <FormGroup className="mb-0">
                      {location?.state ? (
                        <Button
                          onClick={() =>
                            handleUpdate(location?.state?.dataObj?.whitelist_id)
                          }
                          className="me-3"
                          color="success"
                        >
                          {'Update'}
                        </Button>
                      ) : (
                        <Button
                          onClick={handleAdd}
                          className="me-3"
                          color="success"
                        >
                          {'Submit'}
                        </Button>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  </Fragment>
  )
}

export default AddWhiteList
