import React, { Fragment, useState, useEffect } from 'react'
import { Breadcrumbs, Image } from '../../../AbstractElements'
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import DataTable from "react-data-table-component";
import { updateWhitePaper, getWhitePaper } from '../../../api/api';
import { serverImageUrl } from '../../../api/api';
export const style = {
  width: 40,
  height: 40,
};
export default function AddWhitePaper() {
  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState(false)

  const [id, setid] = useState()
  const [pdf, setpdf] = useState('')
  const [data, setdata] = useState([])

  const handleUpdate = async () => {
    if (!pdf) {
      return alert("Please Select the Pdf")
    }
    setisLoading(true)


    const formData = new FormData();
    formData.append("pdfFile", pdf);

    const res = await updateWhitePaper(formData, id);
    if (res.status === 200) {
      alert('Update Successfully')
      navigate('/home/addWhitePaper')
      setisLoading(false)
    } else {
      alert("Error");
      setisLoading(false)
    }

  };

  const populateData = async () => {
    const res = await getWhitePaper();
    setdata(res.data)
    setid(res.data[0].wp_id)
  };


  useEffect(() => {
    populateData();
  }, []);

  const handleChangeStatus = ({ meta, file }, status) => {
    setpdf(file);
  };

  const addDataAPI = data?.map((elem) => {
    return {
      Name: <a href={serverImageUrl + elem.wp_pdf} target="_blank">{elem.wp_pdf}</a>,
      // image: (
      //   <Image
      //     attrImage={{
      //       src: serverImageUrl + elem.wp_pdf,
      //       style: style,
      //       alt: "Unavailable",
      //     }}
      //   />
      // ),
    };
  });

  const addColumns = [
    {
      name: "Name",
      selector: (row) => row.Name,
      sortable: true,
      center: true,
    },
    // {
    //   name: "Image",
    //   selector: (row) => row.image,
    //   sortable: true,
    //   center: true,
    // },
  ];
  return (
    <Fragment>
      <Breadcrumbs parent="Add White Paper" title="Add White Paper" mainTitle="Add White Paper" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Form method="POST">
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{"Current Pdf"}</Label>
                      </FormGroup>
                    </Col>
                    <Row>
                      <Col sm="12">
                        <Card>
                          <CardBody>
                            <div className="table-responsive product-table">
                              <DataTable
                                noHeader
                                pagination
                                paginationServer
                                columns={addColumns}
                                data={addDataAPI}
                              />
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Form method="POST">
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{"White Paper (Pdf)"}</Label>
                        <Dropzone
                          onChangeStatus={handleChangeStatus}
                          inputContent="Drop An Pdf"
                          accept='application/pdf'
                          multiple={false}
                          canCancel={true}
                          maxFiles={1}
                          styles={{
                            dropzone: { width: "100%", minHeight: 50 },
                            dropzoneActive: { borderColor: "green" },
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup className="mb-0">
                        <Button
                          onClick={handleUpdate}
                          className="me-3"
                          color="success"
                        >
                          {isLoading ?
                            <div class="spinner-border spinner-border-sm pt-2" role="status">
                              <span class="sr-only">Loading...</span>
                            </div>
                            : "Update"}
                        </Button>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}
