import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { Breadcrumbs } from "../../../AbstractElements";
import { insertSignupBenefits, updateSignupBenefits } from "../../../api/api";
import Dropzone from 'react-dropzone-uploader';
import ReactQuill from 'react-quill';
const AntsBenefits = () => {
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [image, setImage] = useState("");
  const [imageOnline, setImageOnline] = useState("");

  const navigate = useNavigate();
  const location = useLocation();

  const handleAdd = async () => {
    const formData = new FormData();
    formData.append("benefits_title", title);
    formData.append("benefits_text", text);
    formData.append("benefits_image", image);

    const res = await insertSignupBenefits(formData);
    if (res.status === 200) {
      navigate(`${process.env.PUBLIC_URL}/benefits/benefits_list`);
    } else {
      alert("Error");
    }
  };
  const handleUpdate = async (id) => {
    const formData = new FormData();
    formData.append("benefits_title", title);
    formData.append("benefits_text", text);
    formData.append("benefits_image", image);

    const res = await updateSignupBenefits(id, formData);
    if (res.status === 200) {
      navigate(`${process.env.PUBLIC_URL}/benefits/benefits_list`);
    } else {
      alert("Error occured.");
    }
  };

  const populateData = () => {
    if (location?.state) {
      setTitle(location?.state?.dataObj?.benefits_title);
      setText(location?.state?.dataObj?.benefits_text);
      setImageOnline(location?.state?.dataObj?.benefits_image)
    }
  };
  const handleChangeImage = ({ meta, file }, status) => {
    setImageOnline("")
    setImage(file);
  };

  useEffect(() => {
    populateData();
  }, []);
  function handleChangeShortText(value) {
    setText(value);
  }
  return (
    <>
      <Fragment>
        <Breadcrumbs
          parent="Signup Benefits"
          title="Add Signup Benefits"
          mainTitle="Add Signup Benefits"
        />
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardBody>
                  <Form method="post">
                    <Row>
                      <Col lg={6}>
                        <FormGroup>
                          <Label>{"Title"}</Label>
                          <input
                            placeholder="Title"
                            className="form-control"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label>{'Short Text'}</Label>
                          <ReactQuill
                            value={text}
                            onChange={handleChangeShortText}
                            placeholder="Type here..."
                            modules={{
                              toolbar: [
                                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                                ['bold', 'italic', 'underline', 'strike'],
                                [{ color: [] }, { background: [] }],
                                [{ align: [] }],
                                [{ list: 'ordered' }, { list: 'bullet' }],
                                ['link', 'image', 'video'],
                                ['clean'],
                              ],
                            }}
                            formats={[
                              'header',
                              'bold',
                              'italic',
                              'underline',
                              'strike',
                              'color',
                              'background',
                              'align',
                              'list',
                              'bullet',
                              'link',
                              'image',
                              'video',
                              'script',
                              'indent',
                              'direction',
                              'font',
                              'size',
                              'blockquote',
                              'code-block',
                            ]}
                            style={{ height: '35vh' }}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg={6}>
                        <FormGroup>
                          <Label>{'Image'}</Label>
                          <Dropzone
                            onChangeStatus={handleChangeImage}
                            inputContent="Drop An Image"
                            multiple={false}
                            canCancel={true}
                            maxFiles={1}
                            styles={{
                              dropzone: { width: '100%', minHeight: 50 },
                              dropzoneActive: { borderColor: 'green' },
                            }}
                          />
                          {imageOnline !== "" ?
                            <img style={{ height: 65, width: 65, marginTop: 10 }} className='img-fluid' src={`${global.backendUrl}/nodeassets/${imageOnline}`} />
                            : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup className="mb-0">
                          {location?.state ? (
                            <Button
                              onClick={() =>
                                handleUpdate(
                                  location?.state?.dataObj?.benefits_id
                                )
                              }
                              className="me-3"
                              color="success"
                            >
                              {"Update"}
                            </Button>
                          ) : (
                            <Button
                              onClick={handleAdd}
                              className="me-3"
                              color="success"
                            >
                              {"Submit"}
                            </Button>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment>
    </>
  );
};

export default AntsBenefits;
