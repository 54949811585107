import axios from 'axios';

export const tokenSmartContract = "0xD6daA0f614d33C753d42646382A60938C5137256";
export const stakingSmartContract = ""

// const baseUrl = 'http://localhost:5000';
const baseUrl = 'https://antsprotocoladminapi.unialsolutions.com';
export const authUrl = 'https://antsprotocoladminapi.unialsolutions.com';
// export const serverImageUrl =
//   'https://antsprotocolapi.unialsolutions.com/nodeassets/';
export const serverImageUrl =
  'https://antsprotocoladminapi.unialsolutions.com/nodeassets/';

// Hero Section
export const getHeroSection = () => axios.get(`${baseUrl}/herosection`);
export const insertHeroSection = (data) =>
  axios.post(`${baseUrl}/herosection/insert`, data);
export const updateHeroSection = (id, data) =>
  axios.put(`${baseUrl}/herosection/${id}`, data);
export const deleteHeroSection = (id) =>
  axios.delete(`${baseUrl}/herosection/${id}`);

// donation stake
export const getDonationStake = () => axios.get(`${baseUrl}/stakeDonate/get`);

// donation stake list
export const getDonationStakeList = () => axios.get(`${baseUrl}/stake-donation-details/get`);

// proposal with votes
export const getProposalWithVotes = () => axios.get(`${baseUrl}/api/proposals-with-votes`);

// get additional_staking
export const getAdditionalStaking = () => axios.get(`${baseUrl}/api/additional-staking/get`);


// Our Section
export const getOurMissionTitle = () => axios.get(`${baseUrl}/missiontitle`);
export const updateOurMissionTitle = (id, data) =>
  axios.put(`${baseUrl}/missiontitle/${id}`, data);

export const getOurMission = () => axios.get(`${baseUrl}/missionsection`);
export const insertOurMission = (data) =>
  axios.post(`${baseUrl}/missionsection/insert`, data);
export const updateOurMission = (id, data) =>
  axios.put(`${baseUrl}/missionsection/${id}`, data);
export const deleteOurMission = (id) =>
  axios.delete(`${baseUrl}/missionsection/${id}`);

// Our Roadmap
export const getRoadmapTitle = () => axios.get(`${baseUrl}/howworkstitle`);
export const updateRoadmapTitle = (id, data) =>
  axios.put(`${baseUrl}/howworkstitle/${id}`, data);

export const getRoadmap = () => axios.get(`${baseUrl}/howworkssection`);
export const insertRoadmap = (data) =>
  axios.post(`${baseUrl}/howworkssection/insert`, data);
export const updateRoadmap = (id, data) =>
  axios.put(`${baseUrl}/howworkssection/${id}`, data);
export const deleteRoadmap = (id) =>
  axios.delete(`${baseUrl}/howworkssection/${id}`);

//antswallet
export const getAntsWallet = () => axios.get(`${baseUrl}/antswallet`);
export const insertAntsWallet = (data) =>
  axios.post(`${baseUrl}/antswallet/insert`, data);
export const updateAntsWallet = (id, data) =>
  axios.put(`${baseUrl}/antswallet/${id}`, data);
export const deleteAntsWallet = (id) =>
  axios.delete(`${baseUrl}/antswallet/${id}`);

export const getAntsToken = () => axios.get(`${baseUrl}/antstoken`);
export const insertAntsToken = (data) =>
  axios.post(`${baseUrl}/antstoken/insert`, data);
export const updateAntsToken = (id, data) =>
  axios.put(`${baseUrl}/antstoken/${id}`, data);
export const deleteAntsToken = (id) =>
  axios.delete(`${baseUrl}/antstoken/${id}`);

// get users
export const getSignupUsers = () =>
  axios.get(`${baseUrl}/signup/get`);


//AntsBenefits
export const getSignupBenefitsTitle = () =>
  axios.get(`${baseUrl}/benefitstitle`);
export const updateSignupBenefitsTitle = (id, data) =>
  axios.put(`${baseUrl}/benefitstitle/${id}`, data);

export const getSignupBenefits = () => axios.get(`${baseUrl}/signupbenefits`);
export const insertSignupBenefits = (data) =>
  axios.post(`${baseUrl}/signupbenefits/insert`, data);
export const updateSignupBenefits = (id, data) =>
  axios.put(`${baseUrl}/signupbenefits/${id}`, data);
export const deleteSignupBenefits = (id) =>
  axios.delete(`${baseUrl}/signupbenefits/${id}`);

// Subscriptions
export const getSubscriptionsTitle = () =>
  axios.get(`${baseUrl}/subscriptiontitle`);
export const updateSubscriptionsTitle = (id, data) =>
  axios.get(`${baseUrl}/subscriptiontitle/${id}`, data);
// Ants Team
export const getAntsTeam = () => axios.get(`${baseUrl}/meetteam`);
export const insertAntsTeam = (data) =>
  axios.post(`${baseUrl}/meetteam/insert`, data);
export const updateAntsTeam = (id, data) =>
  axios.put(`${baseUrl}/meetteam/${id}`, data);
export const deleteAntsTeam = (id) => axios.delete(`${baseUrl}/meetteam/${id}`);

// Project Category
export const getProjectCategory = () => axios.get(`${baseUrl}/projectcategory`);
export const insertProjectCategory = (data) =>
  axios.post(`${baseUrl}/projectcategory/insert`, data);
export const updateProjectCategory = (id, data) =>
  axios.put(`${baseUrl}/projectcategory/${id}`, data);
export const deleteProjectCategory = (id) =>
  axios.delete(`${baseUrl}/projectcategory/${id}`);

// Projects
export const getProjectsVote = () => axios.get(`${baseUrl}/projectsVote`);
export const insertProjectsVote = (data) =>
  axios.post(`${baseUrl}/projectsVote/insert`, data);
export const updateProjectsVote = (id, data) =>
  axios.put(`${baseUrl}/projectsVote/${id}`, data);
export const deleteProjectsVote = (id) =>
  axios.delete(`${baseUrl}/projectsVote/${id}`);

// Create Plans
export const getCreatePlans = () => axios.get(`${baseUrl}/createplans`);
export const insertCreatePlans = (data) =>
  axios.post(`${baseUrl}/createplans/insert`, data);
export const updateCreatePlans = (id, data) =>
  axios.put(`${baseUrl}/createplans/${id}`, data);
export const deleteCreatePlans = (id) =>
  axios.delete(`${baseUrl}/createplans/${id}`);

// ANTS Users
export const getAntsUsers = () => axios.get(`${baseUrl}/antsusers`);

// Badge
export const getBadges = () => axios.get(`${baseUrl}/badges`);
export const insertBadges = (data) =>
  axios.post(`${baseUrl}/badges/insert`, data);
export const updateBadges = (id, data) =>
  axios.put(`${baseUrl}/badges/${id}`, data);
export const deleteBadges = (id) => axios.delete(`${baseUrl}/badges/${id}`);

// Cash Distribution
export const getCashback = () => axios.get(`${baseUrl}/cashback`);
export const insertCashback = (data) =>
  axios.post(`${baseUrl}/cashback/insert`, data);
export const updateCashback = (id, data) =>
  axios.put(`${baseUrl}/cashback/${id}`, data);
export const deleteCashback = (id) => axios.delete(`${baseUrl}/cashback/${id}`);

// Cash Distribution
export const getAntsStats = () => axios.get(`${baseUrl}/antsstats`);
export const insertAntsStats = (data) =>
  axios.post(`${baseUrl}/antsstats/insert`, data);
export const updateAntsStats = (id, data) =>
  axios.put(`${baseUrl}/antsstats/${id}`, data);
export const deleteAntsStats = (id) =>
  axios.delete(`${baseUrl}/antsstats/${id}`);

//

// Cashback
export const getCashbackUsers = () =>
  axios.get(`${baseUrl}/cashback_list_users`);
export const updateCashbackList = (data) =>
  axios.post(`${baseUrl}/cashback_list/update`, data);



export const getWalletAssocaite = () => axios.get(`${baseUrl}/walletassocaite`);
export const insertWalletAssocaite = (data) =>
  axios.post(`${baseUrl}/walletassocaite/insert`, data);
export const updateWalletAssocaite = (id, data) =>
  axios.put(`${baseUrl}/walletassocaite/${id}`, data);
export const deleteWalletAssocaite = (id) =>
  axios.delete(`${baseUrl}/walletassocaite/${id}`);

// staking
export const insertStaking = (data) =>
  axios.post(`${baseUrl}/staking/staking_list`, data);
export const getStaking = () => axios.get(`${baseUrl}/staking/get`);
export const deleteStaking = (id) =>
  axios.delete(`${baseUrl}/stakingDelete/${id}`);
export const editStaking= (id, data) =>
  axios.put(`${baseUrl}/stakingEdit/${id}`, data);
//


// airdrop
export const insertairdrop = (data) =>
  axios.post(`${baseUrl}/airdrop/airdrop_list`, data);
export const getairdrop = () => axios.get(`${baseUrl}/airdrop/get`);
export const deleteairdrop = (id) =>
  axios.delete(`${baseUrl}/airdropDelete/${id}`);
export const editairdrop= (id, data) =>
  axios.put(`${baseUrl}/airdropEdit/${id}`, data);


// whitelist
export const insertwhitelist = (data) =>
  axios.post(`${baseUrl}/whitelist/whitelist_list`, data);
export const getwhitelist = () => axios.get(`${baseUrl}/whitelist/get`);
export const deletewhitelist = (id) =>
  axios.delete(`${baseUrl}/whitelistDelete/${id}`);
export const editwhitelist= (id, data) =>
  axios.put(`${baseUrl}/whitelistEdit/${id}`, data);


// proposal
export const insertproposal = (data) =>
  axios.post(`${baseUrl}/proposal/proposal_list`, data);
export const getproposal = () => axios.get(`${baseUrl}/proposal/get`);
export const deleteproposal = (id) =>
  axios.delete(`${baseUrl}/proposalDelete/${id}`);
export const editproposal= (id, data) =>
  axios.put(`${baseUrl}/proposalEdit/${id}`, data);

// notification
export const insertnotification = (data) =>
  axios.post(`${baseUrl}/notification/notification_list`, data);
export const getnotification = () => axios.get(`${baseUrl}/notification/get`);
export const deletenotification = (id) =>
  axios.delete(`${baseUrl}/notificationDelete/${id}`);
export const editnotification= (id, data) =>
  axios.put(`${baseUrl}/notificationEdit/${id}`, data);


// private sale
export const insertprivateSale = (data) =>
  axios.post(`${baseUrl}/privateSale/privateSale_list`, data);
export const getprivateSale = () => axios.get(`${baseUrl}/privateSale/get`);
export const deleteprivateSale = (id) =>
  axios.delete(`${baseUrl}/privateSaleDelete/${id}`);
export const editprivateSale= (id, data) =>
  axios.put(`${baseUrl}/privateSaleEdit/${id}`, data);


export const getAntsButtonColor = () => axios.get(`${baseUrl}/buttonbgcolor`);
export const updateAntsButtonColor = (id, data) =>
  axios.put(`${baseUrl}/buttonbgcolor/${id}`, data);

export const getAntsPartnersTitle = () =>
  axios.get(`${baseUrl}/antspartnerstitle`);
export const updateAntsPartnersTitle = (id, data) =>
  axios.put(`${baseUrl}/antspartnerstitle/${id}`, data);

export const getAntsPartners = () => axios.get(`${baseUrl}/ourantspartners`);
export const insertAntsPartners = (data) =>
  axios.post(`${baseUrl}/ourantspartners/insert`, data);
export const updateAntsPartners = (id, data) =>
  axios.put(`${baseUrl}/ourantspartners/${id}`, data);
export const deleteAntsPartners = (id) =>
  axios.delete(`${baseUrl}/ourantspartners/${id}`);

export const getAntsBrand = () => axios.get(`${baseUrl}/antsbrand`);
export const insertAntsBrand = (data) =>
  axios.post(`${baseUrl}/antsbrand/insert`, data);
export const updateAntsBrand = (id, data) =>
  axios.put(`${baseUrl}/antsbrand/${id}`, data);
export const deleteAntsBrand = (id) =>
  axios.delete(`${baseUrl}/antsbrand/${id}`);

export const getAntsFooter = () => axios.get(`${baseUrl}/antsfooter`);
export const insertAntsFooter = (data) =>
  axios.post(`${baseUrl}/antsfooter/insert`, data);
export const updateAntsFooter = (id, data) =>
  axios.put(`${baseUrl}/antsfooter/${id}`, data);
export const deleteAntsFooter = (id) =>
  axios.delete(`${baseUrl}/antsfooter/${id}`);

// Footer Content
export const getAntsFooterContent = () => axios.get(`${baseUrl}/footer_content`);
export const insertAntsFooterContent = (data) => axios.post(`${baseUrl}/footer_content/insert`, data);
export const updateAntsFooterContent = (id, data) => axios.put(`${baseUrl}/footer_content/${id}`, data);

// Tickets
export const getAntsTickets = () => axios.get(`${baseUrl}/tickets`);
export const insertAntsTickets = (data) => axios.post(`${baseUrl}/tickets/insert`, data);
export const updateAntsTickets = (id, data) => axios.put(`${baseUrl}/tickets/${id}`, data);
export const deleteAntsTickets = (id) => axios.delete(`${baseUrl}/tickets/${id}`);

// Email Templates
export const getEmailTemplates = () => axios.get(`${baseUrl}/email_templates`);
export const insertEmailTemplates = (data) => axios.post(`${baseUrl}/email_templates/insert`, data);
export const updateEmailTemplates = (id, data) => axios.put(`${baseUrl}/email_templates/${id}`, data);
export const deleteEmailTemplates = (id) => axios.delete(`${baseUrl}/email_templates/${id}`);
export const sendEmail = (data) => axios.post(`${baseUrl}/sendEmail`, data);

export const insertKnowledge = (data) =>
  axios.post(`${baseUrl}/knowledge/insert`, data);
export const getKnowledge = () => axios.get(`${baseUrl}/knowledge/get`);
export const deleteKnowledge = (id) =>
  axios.delete(`${baseUrl}/knowledge/delete/${id}`);
export const updateKnow = (data, id) =>
  axios.put(`${baseUrl}/knowledge/update/${id}`, data);

export const getHowToVote = () => axios.get(`${baseUrl}/howtovote/get`);
export const updateHowToVote = (data, id) =>
  axios.put(`${baseUrl}/howtovote/update/${id}`, data);

export const getWhitePaper = () => axios.get(`${baseUrl}/whitePaper/get`);
export const updateWhitePaper = (data, id) =>
  axios.put(`${baseUrl}/whitePaper/update/${id}`, data);

export const getDonationSection = () =>
  axios.get(`${baseUrl}/donationSection/get`);
export const updateDonationSection = (data, id) =>
  axios.put(`${baseUrl}/donationSection/update/${id}`, data);

export const insertPersonalDonation = (data) =>
  axios.post(`${baseUrl}/personalDonation/insert`, data);
export const getPersonalDonation = () =>
  axios.get(`${baseUrl}/personalDonation/get`);
export const deletePersonalDonation = (id) =>
  axios.delete(`${baseUrl}/personalDonation/delete/${id}`);
export const updatePersonalDonation = (data, id) =>
  axios.put(`${baseUrl}/personalDonation/update/${id}`, data);

// Users Badges:
export const onUpdateUserBadges = (id, data) =>
  axios.put(`${baseUrl}/updateuserbadges/${id}`, data);
export const getUserSingleBadges = (id) =>
  axios.get(`${baseUrl}/usersingleBadge/${id}`);
export const getUsersAndBadges = () => axios.get(`${baseUrl}/usersbadges`);

// Products Category AntsProductCategory
export const getProductsCategory = () =>
  axios.get(`${baseUrl}/productscategory`);
export const insertProductCategory = (data) =>
  axios.post(`${baseUrl}/productscategory`, data);
export const updateProductCategory = (data, id) =>
  axios.put(`${baseUrl}/productscategory/${id}`, data);
export const deleteProductsCategory = (id) =>
  axios.delete(`${baseUrl}/productscategory/${id}`);
//getsinglecategoryproduct
export const getSingleCategoryProduct = (id) =>
  axios.get(`${baseUrl}/productsubcategory/${id}`);
// Products Sub Category - Ants Products Sub Category
export const getProductSubCategory = () =>
  axios.get(`${baseUrl}/productsubcategory`);
export const insertProductSubCategory = (data) =>
  axios.post(`${baseUrl}/productsubcategory`, data);
export const updateProductSubCategory = (data, id) =>
  axios.put(`${baseUrl}/productsubcategory/${id}`, data);
export const deleteProductSubCategory = (id) =>
  axios.delete(`${baseUrl}/productsubcategory/${id}`);

// Ants Products:
export const getAntsProducts = () => axios.get(`${baseUrl}/antsproducts`);
export const insertAntsProducts = (data) =>
  axios.post(`${baseUrl}/antsproducts`, data);
export const updateAntsProducts = (data, id) =>
  axios.put(`${baseUrl}/antsproducts/${id}`, data);
export const deleteAntsProducts = (id) =>
  axios.delete(`${baseUrl}/antsproducts/${id}`);

export const insertSlider = (data) =>
  axios.post(`${baseUrl}/slider/addslider`, data);
export const getSlider = () => axios.get(`${baseUrl}/slider/get`);

export const insertProjectType = (data) =>
  axios.post(`${baseUrl}/projecttype/insert`, data);
export const getProjectType = () => axios.get(`${baseUrl}/projecttype/get`);
export const deleteProjectType = (id) =>
  axios.delete(`${baseUrl}/projecttype/${id}`);
export const updateProjectType = (id, data) =>
  axios.put(`${baseUrl}/projecttype/${id}`, data);

export const insertProjectCate = (data) =>
  axios.post(`${baseUrl}/projectCate/insert`, data);
export const getProjectCate = () => axios.get(`${baseUrl}/projectCate/get`);
export const deleteProjectCate = (id) =>
  axios.delete(`${baseUrl}/projectCate/${id}`);
export const updateProjectCate = (id, data) =>
  axios.put(`${baseUrl}/projectCate/update/${id}`, data);

export const getProject = () => axios.get(`${baseUrl}/dashboard-projects/get`);
export const getProjectWithUsers = () => axios.get(`${baseUrl}/dashboard-projects/users`);
export const updateProjectStatus = (id, data) =>
  axios.put(`${baseUrl}/projectStatus/update/${id}`, data);

export const insertStats = (data) =>
  axios.post(`${baseUrl}/stats/insert`, data);


// SEO INFORMATION

// How it works - SEO
export const getHowItWorksSeo = () => axios.get(`${baseUrl}/howItWorksSeo`);
export const updateHowItWorksSeo = (id, data) => axios.put(`${baseUrl}/howItWorksSeo/update/${id}`, data);

// NGO - SEO
export const getNgoPage = () => axios.get(`${baseUrl}/ngoSeo`);
export const updateNgoPage = (id, data) => axios.put(`${baseUrl}/ngoSeo/update/${id}`, data);

// Profitable - SEO
export const getProfitablePage = () => axios.get(`${baseUrl}/profitableSeo`);
export const updateProfitablePage = (id, data) => axios.put(`${baseUrl}/profitableSeo/update/${id}`, data);

// Launch - SEO
export const getLaunchPage = () => axios.get(`${baseUrl}/launchSeo`);
export const updateLaunchPage = (id, data) => axios.put(`${baseUrl}/launchSeo/update/${id}`, data);

// Governance - SEO
export const getGovernancePage = () => axios.get(`${baseUrl}/governanceSeo`);
export const updateGovernancePage = (id, data) => axios.put(`${baseUrl}/governanceSeo/update/${id}`, data);

// Profile - SEO
export const getProfilePage = () => axios.get(`${baseUrl}/profileSeo`);
export const updateProfilePage = (id, data) => axios.put(`${baseUrl}/profileSeo/update/${id}`, data);

// Create - SEO
export const getCreatePage = () => axios.get(`${baseUrl}/createSeo`);
export const updateCreatePage = (id, data) => axios.put(`${baseUrl}/createSeo/update/${id}`, data);

// Explore - SEO
export const getExplorePage = () => axios.get(`${baseUrl}/exploreSeo`);
export const updateExplorePage = (id, data) => axios.put(`${baseUrl}/exploreSeo/update/${id}`, data);

// Return Percentage
export const getReturnPercentage = () => axios.get(`${baseUrl}/returnPercentage`);
export const updateReturnPercentage = (id, data) => axios.put(`${baseUrl}/returnPercentage/update/${id}`, data);

// Voting Power Criteria
export const getVotingPowerCriteria = () => axios.get(`${baseUrl}/votingPower`);
export const updateVotingPowerCriteria = (id, data) => axios.put(`${baseUrl}/votingPower/update/${id}`, data);

// Case Studies Statistics - Launch Page

export const getCaseStudiesStatistics = () => axios.get(`${baseUrl}/case_studies_statistics`);
export const insertCaseStudiesStatistics = (data) =>
  axios.post(`${baseUrl}/case_studies_statistics/insert`, data);
export const updateCaseStudiesStatistics = (id, data) =>
  axios.put(`${baseUrl}/case_studies_statistics/${id}`, data);
export const deleteCaseStudiesStatistics = (id) =>
  axios.delete(`${baseUrl}/case_studies_statistics/${id}`);

export const deleteUserById = (id) =>
  axios.delete(`${baseUrl}/users/delete/${id}`);
