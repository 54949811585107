import React, { Fragment, useState, useEffect } from "react";
import { Breadcrumbs, H5, Image } from "../../AbstractElements";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Row,
} from "reactstrap";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../assets/css/toast.css'
export const style = {
    width: 40,
    height: 40,
};
export const style2 = { width: 60, fontSize: 14, padding: 4 };
export default function BlogCategoryList() {
  const navigate = useNavigate();
    const [data, setdata] = useState([])
    // const [data2, setdata2] = useState([])


    useEffect(() => {
        axios.get(`${global.backendUrl}/blogCategory/get`)
            .then((response) => {
                setdata(response.data)
            }).catch((err) => {
                console.log(err)
            })
    }, [])

    // useEffect(() => {
    //     axios.get(`${global.backendUrl}/blogSubCategory/get`)
    //         .then((response) => {
    //             setdata2(response.data)
    //         }).catch((err) => {
    //             console.log(err)
    //         })
    // }, [])

    const handleDelete = (id)=>{
        toast(
          <div className="custom-toast">
            <p className="message">Are you sure you want to delete this Category?</p>
            <div className="button-container-toast">
              <button className="button-toast yes" onClick={() => {
                toast.dismiss(); 
                axios.delete(`${global.backendUrl}/blogCategory/delete/${id}`)
                    .then((response) => {
                      const newData = data.filter((t)=>(t.category_blogs_id !== id))
                      setdata(newData)
                      toast.success(`Category Deleted Successfully`, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      });
                    }).catch((err) => {
                        console.log(err)
                    })
              }}>Yes</button>
              <button className="button-toast no" onClick={() => {
                toast.dismiss();
              }}>No</button>
            </div>
          </div>
        );
      }

    //   const handleDelete2 = (id)=>{
    //     toast(
    //       <div className="custom-toast">
    //         <p className="message">Are you sure you want to delete this Sub Category?</p>
    //         <div className="button-container-toast">
    //           <button className="button-toast yes" onClick={() => {
    //             toast.dismiss(); 
    //             axios.delete(`${global.backendUrl}/blogSubCategory/delete/${id}`)
    //                 .then((response) => {
    //                   const newData = data2.filter((t)=>(t.subCate_blogs_id !== id))
    //                   setdata2(newData)
    //                   toast.success(`Sub Category Deleted Successfully`, {
    //                     position: "top-right",
    //                     autoClose: 3000,
    //                     hideProgressBar: false,
    //                     closeOnClick: true,
    //                     pauseOnHover: true,
    //                     draggable: true,
    //                     progress: undefined,
    //                   });
    //                 }).catch((err) => {
    //                     console.log(err)
    //                 })
    //           }}>Yes</button>
    //           <button className="button-toast no" onClick={() => {
    //             toast.dismiss();
    //           }}>No</button>
    //         </div>
    //       </div>
    //     );
    //   }

    const addDataAPI = data?.map((elem) => {
        return {
            Name: `${elem.category_name}`,
            action: (
                <div>
                    <span>
                        <Button
                            onClick={() => handleDelete(elem.category_blogs_id)}
                            className="btn btn-danger btn-xs"
                            style={style2}
                        >
                            Delete
                        </Button>
                    </span>{" "}
                    &nbsp;&nbsp;
                    <span>
                        <Button
                            onClick={() => {
                                navigate(`${process.env.PUBLIC_URL}/blogs/updateBlogCate/${elem.category_blogs_id}`);
                            }}
                            className="btn btn-success btn-xs"
                            style={style2}
                        >
                            Edit{" "}
                        </Button>
                    </span>
                </div>
            ),
        };
    });

    // const addDataAPI2 = data2?.map((elem) => {
    //     return {
    //         Name: `${elem.subCate_name}`,
    //         Status: `${elem.subCate_status}`,
    //         action: (
    //             <div>
    //                 <span>
    //                     <Button
    //                         onClick={() => handleDelete2(elem.subCate_blogs_id)}
    //                         className="btn btn-danger btn-xs"
    //                         style={style2}
    //                     >
    //                         Delete
    //                     </Button>
    //                 </span>{" "}
    //                 &nbsp;&nbsp;
    //                 <span>
    //                     <Button
    //                         onClick={() => {
    //                             navigate(`${process.env.PUBLIC_URL}/blogs/updateBlogSubCate/${elem.subCate_blogs_id}`);
    //                         }}
    //                         className="btn btn-success btn-xs"
    //                         style={style2}
    //                     >
    //                         Edit{" "}
    //                     </Button>
    //                 </span>
    //             </div>
    //         ),
    //     };
    // });

    const addColumns = [
        {
            name: "Name",
            selector: (row) => row.Name,
            sortable: true,
            center: true,
        },
        {
            name: "Action",
            selector: (row) => row.action,
            sortable: true,
            center: true,
        },
    ];
    return (
        <Fragment>
            <Breadcrumbs
                parent="Blogs"
                title="Blogs Categories List"
                mainTitle="Blogs Categories List"
            />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader className="pb-0">
                                <H5>{"Blogs Categories List"}</H5>
                            </CardHeader>
                            <CardBody>
                                <div className="table-responsive product-table">
                                    <DataTable
                                        noHeader
                                        pagination
                                        paginationServer
                                        columns={addColumns}
                                        data={addDataAPI}
                                    />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>

            {/* <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader className="pb-0">
                                <H5>{"Blogs Sub Categories List"}</H5>
                            </CardHeader>
                            <CardBody>
                                <div className="table-responsive product-table">
                                    <DataTable
                                        noHeader
                                        pagination
                                        paginationServer
                                        columns={addColumns}
                                        data={addDataAPI2}
                                    />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container> */}
        </Fragment>
    )
}

