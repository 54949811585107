import React, { Fragment, useEffect, useState } from "react";
import "react-dropzone-uploader/dist/styles.css";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row
} from "reactstrap";
import { Breadcrumbs } from "../../../AbstractElements";
import { editnotification,insertnotification,getSignupUsers } from "../../../api/api";
import toast from 'react-hot-toast';

const AddNotifications = () => {
  const [title, setTitle] = useState("");
  const [dec, setDec] = useState("");
  const [users, setUsers] = useState([]);
  const [open, setOpen] = useState(false);
  const [dropDownValue, setDropDownValue] = useState("Select a User");
  const [selectedUserId, setSelectedUserId] = useState(null);  // Store selected user's ID

 

  const openDropDown = () => {
    setOpen(!open);
  };

  const handleUserSelection = (uid,name) => {
    setDropDownValue(name);
    setSelectedUserId(uid); 
    setOpen(false);  
    console.log("Selected User ID:", uid);
  };
   
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await getSignupUsers();
        if (response.status === 200) {
          setUsers(response.data);  // Assuming the API returns an array of users
        }
      } catch (error) {
        toast.error("Failed to fetch users.");
      }
    };

    fetchUsers();
  }, []);
 
  const handleAdd = async () => {
    if(!title || !dec){
      toast.error("Please enter all fields")
return
    }

    const formData = {
     notification_title: title,
     notification_description: dec,
     notification_user_name: dropDownValue,
    notification_user_id: selectedUserId,
  
    };

    const res = await insertnotification(formData);
    
    if (res.status === 200) {
      navigate(`${process.env.PUBLIC_URL}/notification/notifications_list`);
    
    } else {
      toast.error("Error occure in adding data")

    }
  };
  const handleUpdate = async (id) => {
    const formData = {
      notification_title: title,
      notification_description: dec,
      notification_user_name: dropDownValue,
      notification_user_id: selectedUserId,
      
    };

    const res = await editnotification(id, formData);
    if (res.status === 200) {
      navigate(`${process.env.PUBLIC_URL}/notification/notifications_list`);
    } else {
      toast.error("Error occure in updating data")

    }
  };

  const populateData = () => {
    if (location?.state?.dataObj) {
     const {notification_title,notification_description,notification_user_name,notification_user_id}=location.state.dataObj;
     setTitle(notification_title)
      setDec(notification_description)
      setDropDownValue(notification_user_name)
      setSelectedUserId(notification_user_id);

 
    }
  };
  useEffect(() => {
    populateData();
  }, []);


  return (
    <Fragment>
      <Breadcrumbs parent="Home" title="Add Notifications" mainTitle="Add Notifications" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Form method="POST">
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{"Title"}</Label>
                        <Input
                          onChange={(e) => setTitle(e.target.value)}
                          value={title}
                          type="text"
                          className="form-control"
                          required
                          placeholder="Title"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <FormGroup>
                        <Label>{"Description"}</Label>
                        <Input
                          onChange={(e) => setDec(e.target.value)}
                          value={dec}
                          type="text"
                          className="form-control"
                          required
                          placeholder="Description"
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={6}>
                      <FormGroup>
                        <Label>{"User"}</Label>
                        {/* <Input
                          onChange={(e) => setStart(e.target.value)}
                          value={start}
                          type="text"
                          className="form-control"
                          required
                          placeholder="user"
                        /> */}
                        <div  style={{ position: "relative" }}>
                          <div
                            onClick={openDropDown}
                            style={{
                              cursor: "pointer",
                              backgroundColor: "#f8f9fa",
                              padding: "10px",
                              position: "relative",
                              borderRadius: "5px",
                            }}
                          >
                            {dropDownValue}
                          </div>

                          {open && (
                            <div
                              style={{
                                backgroundColor: "#f8f9fa",
                                position: "absolute",
                                top: "50px",
                                left: "0",
                                right: "0",
                                padding: "10px",
                                borderRadius: "5px",
                                zIndex: 1,
                              }}
                            >
                              {users.map((user) => (
                                <div
                                  key={user.id}
                                  style={{
                                    padding: "8px",
                                    cursor: "pointer",
                                    borderBottom: "1px solid #ddd",
                                  }}
                                  onClick={() => handleUserSelection(user.uid, user.name)}
                                >
                                  {user.name}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup className="mb-0">
                        {location?.state ? (
                          <Button
                            onClick={() =>
                              handleUpdate(location?.state?.dataObj?.notification_id)
                            }
                            className="me-3"
                            color="success"
                          >
                            {"Update"}
                          </Button>
                        ) : (
                          <Button
                            onClick={handleAdd}
                            className="me-3"
                            color="success"
                          >
                            {"Submit"}
                          </Button>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default AddNotifications;