import React, { Fragment, useState, useEffect } from "react";
import { Breadcrumbs, H5 } from "../../../AbstractElements";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import DataTable from "react-data-table-component";
import { getDonationStakeList } from "../../../api/api";

export const style2 = { width: 60, fontSize: 14, padding: 4 };

function StakedTokensList() {
  const [donationStakeListAPI, setDonationStakeList] = useState([]);

  const populateDonationStakeList = async () => {
    const res = await getDonationStakeList();
    setDonationStakeList([...res.data]);
  };

  useEffect(() => {
    populateDonationStakeList();
  }, []);

  const addDataAPI = donationStakeListAPI?.map((elem) => {
    const date = new Date(elem.stakingDate);
    const formattedDate = date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
    return {
      userName: elem.userName,
      walletAddress: elem.walletAddress,
      pool: elem.pool,
      stakedTokens: elem.stakedTokens,
      stakingDate: formattedDate,
    };
  });

  const addColumns = [
    {
      name: "User Name",
      selector: (row) => row.userName,
      sortable: true,
      center: true,
    },
    {
      name: "Wallet Address",
      selector: (row) => row.walletAddress,
      sortable: true,
      center: true,
    },
    {
      name: "Pool",
      selector: (row) => row.pool,
      sortable: true,
      center: true,
    },
    {
      name: "Staked tokens",
      selector: (row) => row.stakedTokens,
      sortable: true,
      center: true,
    },
    {
      name: "Awarded Tokens",
      // selector: (row) => row.status,
      sortable: true,
      center: true,
    },
    {
      name: "Staking date",
      selector: (row) => row.stakingDate,
      sortable: true,
      center: true,
    },
    {
      name: "Transaction Hash",
      // selector: (row) => row.status,
      sortable: true,
      center: true,
    },
  ];

  return (
    <Fragment>
      <Breadcrumbs
        parent="Home"
        title="Hero Staked Tokens"
        mainTitle="Staked Tokens"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="pb-0">
                <H5>{"Staked Tokens List"}</H5>
              </CardHeader>
              <CardBody>
                <div className="table-responsive product-table">
                  <DataTable
                    noHeader
                    pagination
                    paginationServer
                    columns={addColumns}
                    data={addDataAPI}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default StakedTokensList;
