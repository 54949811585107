import React, { Fragment, useState, useEffect } from 'react'
import { Breadcrumbs, H5, H6, P } from '../../../AbstractElements'
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
} from "reactstrap";
import { useParams } from 'react-router-dom';
import axios from 'axios';
export default function ViewUser() {
    const { id } = useParams();
    const [data, setdata] = useState([])

    const populateData = () => {
        axios.get(`${global.backendUrl}/signup/get`)
            .then((response) => {
                const filteredData = response.data.filter((t) => t.uid == id)
                setdata(filteredData[0])
            }).catch((err) => {
                console.log(err)
            })
    };


    useEffect(() => {
        populateData();
    }, []);
    return (
        <Fragment>
            <Breadcrumbs
                parent="Users"
                title="User Profile"
                mainTitle="User Profile"
            />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col sm="3">
                                        <H6>User Name:</H6>
                                    </Col>
                                    <Col sm="3">
                                        <P>{data?.name}</P>
                                    </Col>
                                    <Col sm="3">
                                        <H6>Email:</H6>
                                    </Col>
                                    <Col sm="3">
                                        <P>{data?.email}</P>
                                    </Col>
                                </Row>

                                <Row className='mt-3'>
                                    <Col sm="3">
                                        <H6>Facebook Link:</H6>
                                    </Col>
                                    <Col sm="3">
                                        <a href={data?.link_fb} className='text-primary' target='_blank'>{data?.link_fb}</a>
                                    </Col>
                                    <Col sm="3">
                                        <H6>Twitter Link:</H6>
                                    </Col>
                                    <Col sm="3">
                                        <a href={data?.link_twitter} className='text-primary' target='_blank'>{data?.link_twitter}</a>
                                    </Col>
                                </Row>

                                <Row className='mt-3'>
                                    <Col sm="3">
                                        <H6>Linked Link:</H6>
                                    </Col>
                                    <Col sm="3">
                                        <a href={data?.link_linkedin} className='text-primary' target='_blank'>{data?.link_linkedin}</a>
                                    </Col>
                                    <Col sm="3">
                                        <H6>Discord Link:</H6>
                                    </Col>
                                    <Col sm="3">
                                        <a href={data?.link_discord} className='text-primary' target='_blank'>{data?.link_discord}</a>
                                    </Col>
                                </Row>

                                <Row className='mt-3'>
                                    <Col sm="3">
                                        <H6>Medium Link:</H6>
                                    </Col>
                                    <Col sm="3">
                                        <a href={data?.link_medium} className='text-primary' target='_blank'>{data?.link_medium}</a>
                                    </Col>
                                    <Col sm="3">
                                        <H6>Reddit Link:</H6>
                                    </Col>
                                    <Col sm="3">
                                        <a href={data?.link_reddit} className='text-primary' target='_blank'>{data?.link_reddit}</a>
                                    </Col>
                                </Row>

                                <Row className='mt-3'>
                                    <Col sm="3">
                                        <H6>Website Link:</H6>
                                    </Col>
                                    <Col sm="3">
                                        <a href={data?.link_website} className='text-primary' target='_blank'>{data?.link_website}</a>
                                    </Col>
                                    <Col sm="3">
                                        <H6>Status:</H6>
                                    </Col>
                                    <Col sm="3">
                                        <P>{data?.status}</P>
                                    </Col>
                                </Row>

                                <Row className='mt-3'>
                                    <Col sm="3">
                                        <H6>User Image:</H6>
                                    </Col>
                                    <Col sm="3">
                                        <img className='img-fluid' style={{ width: "150px" }} src={`${global.backendUrl}/nodeassets/${data?.image}`} />
                                    </Col>
                                    <Col sm="3">
                                        <H6>User Featured Image:</H6>
                                    </Col>
                                    <Col sm="3">
                                        <img className='img-fluid' style={{ width: "150px" }} src={`${global.backendUrl}/nodeassets/${data?.featured_img}`} />
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}
