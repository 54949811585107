import React, { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { TagsInput } from 'react-tag-input-component';
import { Breadcrumbs } from '../../../AbstractElements';
import {
  getProductsCategory,
  getSingleCategoryProduct,
  insertAntsProducts,
  updateAntsProducts,
} from '../../../api/api';
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { toast } from 'react-hot-toast';

const AddProducts = () => {
  const [title, setTitle] = useState('');
  const [shortText, setShortText] = useState('');
  const [longDescription, setLongDescription] = useState('');
  const [featuredImg, setFeaturedImg] = useState('');
  const [multipleImgs, setMultipleImgs] = useState([]);
  const [metatitle, setmetatitle] = useState('');
  const [metaKeywords, setMetaKeywords] = useState('');
  const [metaDescription, setMetaDescription] = useState('');
  const [affiliateUrl, setAffiliateUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [getCategories, setGetCategories] = useState([]);
  const [getSubCategories, setGetSubcategories] = useState([]);
  const [selectServices, setSelectServices] = useState('');
  const [selectSubServices, setSelectSubServices] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  const onHandleAdd = async () => {
    if (!title || !featuredImg) {
      toast.error('All fields are required');
      return;
    }
    if (!multipleImgs) {
      toast.error('All fields are required');
      return;
    }
    setLoading(true);
    [selectSubServices].forEach(async (elem) => {
      const formData = new FormData();
      formData.append('products_title', title);
      formData.append('products_text', shortText);
      formData.append('products_description', longDescription);
      formData.append('multiple_images', featuredImg);
      // formData.append("multiple_images", multipleImgs);
      multipleImgs.forEach((element) => {
        formData.append('multiple_images', element);
      });
      formData.append('products_metatitle', metatitle);
      formData.append('products_metakeywords', JSON.stringify(metaKeywords));
      formData.append('products_metadescription', metaDescription);
      formData.append('products_affiliateurl', affiliateUrl);
      formData.append('products_category_id', selectServices);
      formData.append('products_subcategory_id', elem.value);

      const res = await insertAntsProducts(formData);
      setLoading(false);
      if (res.status === 200) {
        navigate(`${process.env.PUBLIC_URL}/product/product_list`);
      } else {
        alert('Error');
        setLoading(false);
      }
    });
  };
  const handleUpdate = async (id) => {
    if (!title) {
      toast.error('All fields are required');
      return;
    }
    if (featuredImg) {
      if (multipleImgs.length == 0) {
        toast.error('Please Select The Mutiple Images');
        return;
      }
    }
    if (multipleImgs.length !== 0) {
      if (!featuredImg) {
        toast.error('Please Select The Featured Image');
        return;
      }
    }
    setLoading(true);

    [selectSubServices].forEach(async (elem) => {
      const formData = new FormData();
      formData.append('products_title', title);
      formData.append('products_text', shortText);
      formData.append('products_description', longDescription);
      formData.append('multiple_images', featuredImg);
      // formData.append("multiple_images", multipleImgs);
      multipleImgs.forEach((element) => {
        formData.append('multiple_images', element);
      });
      formData.append('products_metakeywords', JSON.stringify(metaKeywords));
      formData.append('products_metadescription', metaDescription);
      formData.append('products_affiliateurl', affiliateUrl);
      formData.append('products_category_id', selectServices);
      formData.append('products_subcategory_id', elem.value);

      const res = await updateAntsProducts(formData, id);
      setLoading(false);
      if (res.status === 200) {
        navigate(`${process.env.PUBLIC_URL}/product/product_list`);
      } else {
        alert('Error occured.');
        setLoading(false);
      }
    });
  };

  const populateData = () => {
    if (location?.state) {
      setTitle(location?.state?.dataObj?.products_title);
      setShortText(location?.state?.dataObj?.products_text);
      setLongDescription(location?.state?.dataObj?.products_description);
      // setFeaturedImg(location?.state?.dataObj?.products_featuredImg);
      // setMultipleImgs(location?.state?.dataObj?.products_multipleImgs);
      setmetatitle(location?.state?.dataObj?.products_metatitle);
      setMetaKeywords(
        JSON.parse(location?.state?.dataObj?.products_metakeywords)
      );
      setMetaDescription(location?.state?.dataObj?.products_metadescription);
      setAffiliateUrl(location?.state?.dataObj?.products_affiliateurl);
      setSelectServices(location.state.dataObj.products_category_id);
      setSelectSubServices(location.state.dataObj.products_subcategory_id);
    }
  };

  const handleChangeStatus = ({ meta, file }, status) => {
    setFeaturedImg(file);
  };

  // const handleChangeMultipleImages = ({ meta, file }, status) => {
  //   setMultipleImgs(file);
  // };

  const handleChangeMultipleImages = (fileObject, status) => {
    const { file } = fileObject;
    const fileExists = multipleImgs.some(
      (existingFile) => existingFile.name === file.name
    );

    if (!fileExists) {
      setMultipleImgs((prevMultipleImgs) => [...prevMultipleImgs, file]);
    }
  };

  function handleChangeDescription(value) {
    setLongDescription(value);
  }

  const fetchSubServices = async (id) => {
    getSingleCategoryProduct(id)
      .then((res) => {
        const mutilOptions = res.data.map((val) => {
          const itemReturn = {
            label: val.product_subcategory_name,
            value: val.product_subcategory_id,
          };
          return itemReturn;
        });
        setGetSubcategories(mutilOptions);

        const filteredData = res.data.find(
          (val) =>
            val.product_subcategory_id ===
            location.state.dataObj.products_subcategory_id
        );
        const organizedData = {
          value: filteredData.product_subcategory_id,
          label: filteredData.product_subcategory_name,
        };
        if (res?.data?.length > 0) {
          setSelectSubServices(organizedData);
          // fetchSubServices(res?.data[0]?.product_subcategory_id);
        }
      })
      .catch((error) => console.log(error));
  };
  const fetchServices = async () => {
    const res = await getProductsCategory();

    setGetCategories(res.data);
    const filteredData = res.data.find(
      (val) =>
        val.product_category_id === location.state.dataObj.products_category_id
    );

    if (res?.data?.length > 0) {
      fetchSubServices(filteredData.product_category_id);
      // setSelectServices(res?.data[0]?.product_category_id);
    }
  };

  useEffect(() => {
    populateData();
    fetchServices();
  }, []);

  return (
    <>
      <Fragment>
        <Breadcrumbs
          parent="Products Category"
          title="Add Products Category"
          mainTitle="Add Products Category"
        />
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardBody>
                  <Form method="post">
                    <Row>
                      <Col lg={6}>
                        <FormGroup>
                          <Label>{'Name'}</Label>
                          <input
                            placeholder="Enter Name"
                            className="form-control"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg={6}>
                        <FormGroup>
                          <Label>{'Short Text'}</Label>
                          <input
                            placeholder="Enter Text"
                            className="form-control"
                            value={shortText}
                            onChange={(e) => setShortText(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <Label>Select Category</Label>
                          <Input
                            type={'select'}
                            className="form-control"
                            value={selectServices}
                            onChange={(e) => {
                              fetchSubServices(e.target.value);
                              setSelectServices(e.target.value);
                            }}
                          >
                            <option>Select Category</option>
                            {getCategories?.map((val, i) => (
                              <option key={i} value={val.product_category_id}>
                                {val.product_category_name}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <Label>Select Sub Categories</Label>
                          <Select
                            onChange={(e) => {
                              setSelectSubServices(e);
                              // console.log(e);
                            }}
                            value={selectSubServices}
                            options={getSubCategories}
                            className="js-example-basic-single col-sm-12"
                            // isMulti
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <FormGroup>
                          <Label>{'Product Url'}</Label>
                          <input
                            placeholder="Enter Product Url"
                            className="form-control"
                            value={affiliateUrl}
                            onChange={(e) => setAffiliateUrl(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg={6}>
                        <FormGroup>
                          <Label>{'Meta Title'}</Label>
                          <input
                            placeholder="Enter Meta Title"
                            className="form-control"
                            value={metatitle}
                            onChange={(e) => setmetatitle(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <FormGroup>
                          <Label>{'Meta Description'}</Label>
                          <input
                            placeholder="Enter Meta Description"
                            className="form-control"
                            value={metaDescription}
                            onChange={(e) => setMetaDescription(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg={6}>
                        <FormGroup>
                          <Label>{'Meta Keywords'}</Label>
                          <TagsInput
                            value={metaKeywords}
                            onChange={setMetaKeywords}
                            placeHolder="Enter Keywords"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>{'Featured Image'}</Label>
                          <Dropzone
                            onChangeStatus={handleChangeStatus}
                            inputContent="Drop An Image"
                            multiple={false}
                            canCancel={true}
                            maxFiles={1}
                            styles={{
                              dropzone: { width: '100%', minHeight: 50 },
                              dropzoneActive: { borderColor: 'green' },
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>{'Mutiple Images'}</Label>
                          <Dropzone
                            onChangeStatus={handleChangeMultipleImages}
                            inputContent="Drop An Image"
                            multiple={true}
                            canCancel={true}
                            maxFiles={10}
                            styles={{
                              dropzone: { width: '100%', minHeight: 50 },
                              dropzoneActive: { borderColor: 'green' },
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="mb-5">
                      <Col>
                        <FormGroup>
                          <Label>{'Description'}</Label>
                          <ReactQuill
                            value={longDescription}
                            onChange={handleChangeDescription}
                            placeholder="Type here..."
                            modules={{
                              toolbar: [
                                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                                ['bold', 'italic', 'underline', 'strike'],
                                [{ color: [] }, { background: [] }],
                                [{ align: [] }],
                                [{ list: 'ordered' }, { list: 'bullet' }],
                                ['link', 'image', 'video'],
                                ['clean'],
                              ],
                            }}
                            formats={[
                              'header',
                              'bold',
                              'italic',
                              'underline',
                              'strike',
                              'color',
                              'background',
                              'align',
                              'list',
                              'bullet',
                              'link',
                              'image',
                              'video',
                              'script',
                              'indent',
                              'direction',
                              'font',
                              'size',
                              'blockquote',
                              'code-block',
                            ]}
                            style={{ height: '35vh' }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup className="mb-0">
                          {location?.state ? (
                            <Button
                              onClick={() =>
                                handleUpdate(
                                  location?.state?.dataObj?.products_id
                                )
                              }
                              className="me-3"
                              color="success"
                            >
                              {loading ? 'Updating...' : 'Update'}
                            </Button>
                          ) : (
                            <Button
                              onClick={onHandleAdd}
                              className="me-3"
                              color="success"
                            >
                              {loading ? 'Submiting...' : 'Submit'}
                            </Button>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment>
    </>
  );
};

export default AddProducts;
