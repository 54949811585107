import React, { Fragment, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { FaLock } from 'react-icons/fa';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap";
import { Breadcrumbs, H5 } from "../../../AbstractElements";
import {
  deleteproposal,
  getproposal,
  getProposalWithVotes,
} from "../../../api/api";

export const style2 = { width: 60, fontSize: 14, padding: 4 };

function ProposalsList() {
  const navigate = useNavigate();
  const [proposalVotes, setProposalVotes] = useState([]);

 
  const populateProposalVotes = async () => {
    const res = await getProposalWithVotes();
    setProposalVotes([...res.data.proposals]);
  };

 
  const deleteDataItem = async (id) => {
    const res = await deleteproposal(id);
    if (res.status === 200) {
      const freshArray = proposalVotes.filter((val) => val.proposal_id !== id);
      setProposalVotes(freshArray);
    }
  };

  useEffect(() => {
    populateProposalVotes()
  }, []);
  console.log('proposal with votes',proposalVotes)

   const addDataAPI = proposalVotes?.map((elem) => {
    return {
      ProposalTitle: elem.proposal_title,
      ProposalDescription: elem.proposal_description,
      ProposalStart: elem.proposal_start,
      ProposalEnd: elem.proposal_end,
      ProposalCreatedBy: elem.proposal_created,
      VoteCount: elem.vote_count,
      action: elem.proposal_created === "admin" ? (
        <div>
          <span>
            <Button
              className="btn btn-danger btn-xs"
              style={style2}
              onClick={() => deleteDataItem(elem.proposal_id)}
            >
              Delete
            </Button>
          </span>
          &nbsp;&nbsp;
          <span>
            <Button
              className="btn btn-success btn-xs"
              style={style2}
              onClick={() => {
                navigate(`${process.env.PUBLIC_URL}/proposals/add_proposals`, {
                  state: { dataObj: elem },
                });
              }}
            >
              Edit
            </Button>
          </span>
        </div>
      ) : (
      <FaLock style={{ color: "#ccc" }} />
        // <Button className='btn btn-warning btn-xs' style={{ color: "#ccc", fontStyle: "italic" }}>No Actions</Button> // Placeholder when not admin
      ),
    };
  });
  

  const addColumns = [
    {
      name: "Title",
      selector: (row) => row.ProposalTitle,
      sortable: true,
      center: true,
    },
    {
      name: "Description",
      selector: (row) => row.ProposalDescription,
      sortable: true,
      center: true,
    },
    {
      name: "Start At",
      selector: (row) => row.ProposalStart,
      sortable: true,
      center: true,
    },
    {
      name: "End At",
      selector: (row) => row.ProposalEnd,
      sortable: true,
      center: true,
    },
    {
      name: "Votes",
      selector: (row) => row.VoteCount,
      sortable: true,
      center: true,
    },

    {
      name: "Action",
      selector: (row) => row.action,
      sortable: true,
      center: true,
    },
  ];

  return (
    <Fragment>
      <Breadcrumbs parent="Home" title="Proposals" mainTitle="Proposals" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="pb-0">
                <H5>{"Proposals List"}</H5>
              </CardHeader>
              <CardBody>
                <div className="table-responsive product-table">
                  <DataTable
                    noHeader
                    pagination
                    paginationServer
                    columns={addColumns}
                    data={addDataAPI}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default ProposalsList;
