import React, { Fragment, useState, useEffect } from "react";
import { Breadcrumbs, H5, Image } from "../../../../AbstractElements";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { deleteairdrop, getairdrop } from "../../../../api/api";
export const style = {
  width: 40,
  height: 40,
};
export const style2 = { width: 60, fontSize: 14, padding: 4 };
function AirDropPools() {
    const navigate = useNavigate();
    const [dataAPI, setDataAPI] = useState([]);
  
    const populateData = async () => {
      const res = await getairdrop();
      setDataAPI([...res.data]);
    };
  
   
    const deleteDataItem = async (id) => {
      const res = await deleteairdrop(id);
      if (res.status === 200) {
        const freshArray = dataAPI.filter((val) => val.airdrop_id !== id);
        setDataAPI(freshArray);
      }
    };
  
    useEffect(() => {
      populateData();
    }, []);
     console.log(dataAPI)
  
    const addDataAPI = dataAPI?.map((elem) => {
      return {

        WalletAddress: elem.wallet_address,
        NumberOfTokens: elem.token_count,
        LockTimePeriod: elem.duration_days,
 
  
        action: (
          <div>
            <span>
              <Button
                onClick={() => deleteDataItem(elem.airdrop_id)}
                className="btn btn-danger btn-xs"
                style={style2}
              >
                Delete
              </Button>
            </span>{" "}
            &nbsp;&nbsp;
            <span>
              <Button
                onClick={() => {
                  navigate(`${process.env.PUBLIC_URL}/airdrop/add_airdrop_pools`, {
                    state: { dataObj: elem },
                  });
                }}
                className="btn btn-success btn-xs"
                style={style2}
              >
                Edit{" "}
              </Button>
            </span>
          </div>
        ),
      };
    });
  
    const addColumns = [
   
      {
        name: "Wallet address",
        selector: (row) => row.WalletAddress,
        sortable: true,
        center: true,
      },
      {
        name: "Number of tokens",
        selector: (row) => row.NumberOfTokens,
        sortable: true,
        center: true,
      },
      {
        name: "Lock time period",
        selector: (row) => row.LockTimePeriod,
        sortable: true,
        center: true,
      },
 
      {
        name: "Action",
        selector: (row) => row.action,
        sortable: true,
        center: true,
      },
    ];
  return (
    <Fragment>
      <Breadcrumbs
        parent="Home"
        title="Airdrop List"
        mainTitle="Airdrop"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="pb-0">
                <H5>{"Airdrop List"}</H5>
              </CardHeader>
              <CardBody>
                <div className="table-responsive product-table">
                  <DataTable
                    noHeader
                    pagination
                    paginationServer
                    columns={addColumns}
                    data={addDataAPI}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}

export default AirDropPools
