import React, { Fragment, useState, useEffect } from "react";
import { Breadcrumbs, H5 } from "../../../AbstractElements";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { getAntsUsers } from "../../../api/api";
import axios from 'axios'
export const style = {
  width: 40,
  height: 40,
};
export const style2 = { width: 60, fontSize: 14, padding: 4 };

function WalletTransactionsList() {
  const navigate = useNavigate();
  const [dataAPI, setDataAPI] = useState([]);

  const populateData = async () => {
    let apiKey = global.etherscanApiKey;
    
    const res = await getAntsUsers();
    console.log(res)
    const filteredData = await Promise.all(res.data.map(async (element) => {
      try {
        const response = await axios.get(`${global.etherscanUrl}?module=proxy&action=eth_blockNumber&apikey=${apiKey}`);
        const currentBlockNumber = parseInt(response.data.result, 16);

        // Calculate the block number for 30 days ago
        const thirtyDaysInSeconds = 30 * 24 * 60 * 60;
        const thirtyDaysAgoBlockNumber = currentBlockNumber - Math.floor(thirtyDaysInSeconds / 15);

        // Fetch transactions from the specified block number until the current block
        const transactionsResponse = await axios.get(`${global.etherscanUrl}?module=account&action=txlist&address=${element.user_walletaddress}&startblock=${thirtyDaysAgoBlockNumber}&endblock=latest&sort=desc&apikey=${apiKey}`);
        const transactions = transactionsResponse.data.result;
        console.log(transactions);

        // Return the updated element with the transactions data
        return {
          ...element,
          transactions: transactions,
          transactions_length: transactions.length
        };
      } catch (error) {
        console.error('Error fetching transaction history:', error);
        return element; // Return the original element in case of an error
      }
    }));

    console.log(filteredData);
    setDataAPI(filteredData);
  };


  useEffect(() => {
    populateData();
  }, []);

  const addDataAPI = dataAPI?.map((elem) => {
    return {
      user_fullname: elem.user_fullname,
      user_email: elem.user_email,
      user_walletaddress: elem.user_walletaddress,
      transactions_length: elem.transactions_length,

      action: (
        <div>
          <span>
            <Button
              onClick={() => {
                navigate(`${process.env.PUBLIC_URL}/wallet_transactions/wallet_transactions_list_details`, {
                  state: { dataObj: elem },
                });
              }}
              className="btn btn-success btn-xs"
              style={style2}
            >
              View
            </Button>
          </span>
        </div>
      ),
    };
  });

  const addColumns = [
    {
      name: "Name",
      selector: (row) => row.user_fullname,
      sortable: true,
      center: true,
    },
    {
      name: "Email",
      selector: (row) => row.user_email,
      sortable: true,
      center: true,
    },
    {
      name: "Wallet",
      selector: (row) => row.user_walletaddress,
      sortable: true,
      center: true,
    },
    {
      name: "Transactions",
      selector: (row) => row.transactions_length,
      sortable: true,
      center: true,
    },
    {
      name: "Action",
      selector: (row) => row.action,
      sortable: true,
      center: true,
    },
  ];

  return (
    <Fragment>
      <Breadcrumbs
        parent="Wallet Transactions"
        title="Wallet Transactions List"
        mainTitle="Wallet Transactions"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="pb-0">
                <H5>{"Wallet Transactions List"}</H5>
              </CardHeader>
              <CardBody>
                <div className="table-responsive product-table">
                  <DataTable
                    noHeader
                    pagination
                    paginationServer
                    columns={addColumns}
                    data={addDataAPI}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default WalletTransactionsList;
