import React, { Fragment, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap";
import { Breadcrumbs, H5 } from "../../../AbstractElements";
import { deletenotification, getnotification } from "../../../api/api";
 

export const style2 = { width: 60, fontSize: 14, padding: 4 };

function NotificationsList() {
  const navigate = useNavigate();
  const [dataAPI, setDataAPI] = useState([]);

  const populateData = async () => {
    const res = await getnotification();
    setDataAPI([...res.data]);
  };

  const deleteDataItem = async (id) => {
    const res = await deletenotification(id);
    if (res.status === 200) {
      const freshArray = dataAPI.filter((val) => val.notification_id !== id);
      setDataAPI(freshArray);
    }
  };

  useEffect(() => {
    populateData();
  }, []);

  const addDataAPI = dataAPI?.map((elem) => {
    return {
      title: elem.notification_title,
      dec: elem.notification_description,
      user: elem.notification_user_name,

      action: (
        <div>
          <span>
            <Button className="btn btn-danger btn-xs" style={style2}
            onClick={() => deleteDataItem(elem.notification_id)}
            >
              Delete
            </Button>
          </span>{" "}
          &nbsp;&nbsp;
          <span>
            <Button className="btn btn-success btn-xs" style={style2}
            onClick={() => {
              navigate(`${process.env.PUBLIC_URL}/notification/add_notifications`, {
                state: { dataObj: elem },
              });
            }}
            >
              Edit{" "}
            </Button>
          </span>
        </div>
      ),
    };
  });

  const addColumns = [
    {
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
      center: true,
    },
    {
      name: "Description",
      selector: (row) => row.dec,
      sortable: true,
      center: true,
    },
    {
      name: "User",
      selector: (row) => row.user,
      sortable: true,
      center: true,
    },

    {
      name: "Action",
      selector: (row) => row.action,
      sortable: true,
      center: true,
    },
  ];

  return (
    <Fragment>
      <Breadcrumbs
        parent="Home"
        title="Notification"
        mainTitle="Notification"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="pb-0">
                <H5>{"Notification List"}</H5>
              </CardHeader>
              <CardBody>
                <div className="table-responsive product-table">
                  <DataTable
                    noHeader
                    pagination
                    paginationServer
                    columns={addColumns}
                    data={addDataAPI}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default NotificationsList;
