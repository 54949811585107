import React, { Fragment, useState, useEffect } from 'react';
import { Breadcrumbs, Image } from '../../../AbstractElements';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import { deleteAntsTickets, getAntsTickets } from '../../../api/api';

export const style = {
  width: 40,
  height: 40,
};
export const style2 = { width: 60, fontSize: 14, padding: 4 };

function AntsTicketsList() {
  const navigate = useNavigate();
  const [dataAPI, setDataAPI] = useState([]);

  const populateData = async () => {
    const res = await getAntsTickets();
    setDataAPI([...res.data]);
  };

  // console.log(`dataAPI`, dataAPI);
  const deleteDataItem = async (id) => {
    const res = await deleteAntsTickets(id);
    if (res.status === 200) {
      const freshArray = dataAPI.filter((val) => val.ticket_id !== id);
      setDataAPI(freshArray);
    }
  };

  useEffect(() => {
    populateData();
  }, []);

  const addDataAPI = dataAPI?.map((elem) => {
    return {
      ticket_name: elem.ticket_name,
      ticket_price: elem.ticket_price,
      ticket_return: elem.ticket_return,
      ticket_weightage: elem.ticket_weightage,
      ticket_description: elem.ticket_description,

      action: (
        <div>
          <span>
            <Button
              onClick={() => deleteDataItem(elem.ticket_id)}
              className="btn btn-danger btn-xs"
              style={style2}
            >
              Delete
            </Button>
          </span>{' '}
          &nbsp;&nbsp;
          <span>
            <Button
              onClick={() => {
                navigate(`${process.env.PUBLIC_URL}/tickets/add_ticket`, {
                  state: { dataObj: elem },
                });
              }}
              className="btn btn-success btn-xs"
              style={style2}
            >
              Edit{' '}
            </Button>
          </span>
        </div>
      ),
    };
  });

  const addColumns = [
    {
      name: 'Ticket Name',
      selector: (row) => row.ticket_name,
      sortable: true,
      center: true,
    },
    {
      name: 'Price',
      selector: (row) => row.ticket_price,
      sortable: true,
      center: true,
    },
    {
      name: 'Return Percentage',
      selector: (row) => row.ticket_return,
      sortable: true,
      center: true,
    },
    {
      name: 'Weightage',
      selector: (row) => row.ticket_weightage,
      sortable: true,
      center: true,
    },
    {
      name: 'Action',
      selector: (row) => row.action,
      sortable: true,
      center: true,
    },
  ];

  return (
    <Fragment>
      <Breadcrumbs
        parent="Tickets"
        title="Tickets List"
        mainTitle="Tickets List"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <div className="table-responsive product-table">
                  <DataTable
                    noHeader
                    pagination
                    paginationServer
                    columns={addColumns}
                    data={addDataAPI}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default AntsTicketsList;
