import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { Breadcrumbs } from "../../../AbstractElements";
import { getBadges, insertCashback, updateCashback } from "../../../api/api";

const AddCashback = () => {
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [getBadgeList, setGetBadgeList] = useState([]);
  const [badge, setBadge] = useState("");

  const navigate = useNavigate();
  const location = useLocation();

  const handleAdd = async () => {
    const formData = {
      cashback_name: title,
      cashback_howmuch: text,
      cashback_badge: badge,
    };

    const res = await insertCashback(formData);
    if (res.status === 200) {
      navigate(`${process.env.PUBLIC_URL}/cashback/cashback_list_percentage`);
    } else {
      alert("Error");
    }
  };
  const handleUpdate = async (id) => {
    const formData = {
      cashback_name: title,
      cashback_howmuch: text,
      cashback_badge: badge,
    };

    const res = await updateCashback(id, formData);
    if (res.status === 200) {
      navigate(`${process.env.PUBLIC_URL}/cashback/cashback_list_percentage`);
    } else {
      alert("Error occured.");
    }
  };

  const populateData = () => {
    if (location?.state) {
      setTitle(location?.state?.dataObj?.cashback_name);
      setText(location?.state?.dataObj?.cashback_howmuch);
      setBadge(location?.state?.dataObj?.cashback_badge);
    }
  };

  const fetchBadges = async () => {
    const res = await getBadges();
    setGetBadgeList(res.data);
  };

  useEffect(() => {
    populateData();
    fetchBadges();
  }, []);

  return (
    <>
      <Fragment>
        <Breadcrumbs
          parent="Cashback"
          title="Add Cashback"
          mainTitle="Add Cashback"
        />
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardBody>
                  <Form method="post">
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>{"Cashback Name"}</Label>
                          <input
                            placeholder="Title"
                            className="form-control"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>{"How Much Percentage?"}</Label>
                          <input
                            placeholder="Enter percentage"
                            className="form-control"
                            value={text}
                            type="number"
                            onChange={(e) => setText(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>Select Badges</Label>
                          <Input
                            type="select"
                            value={badge}
                            className="form-control"
                            onChange={(e) => setBadge(e.target.value)}
                          >
                            <option value={""}>Select a Badge</option>
                            {getBadgeList?.map((b, i) => (
                              <option key={i} value={b?.badge_id}>
                                {b?.badge_name}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup className="mb-0">
                          {location?.state ? (
                            <Button
                              onClick={() =>
                                handleUpdate(
                                  location?.state?.dataObj?.cashback_id
                                )
                              }
                              className="me-3"
                              color="success"
                            >
                              {"Update"}
                            </Button>
                          ) : (
                            <Button
                              onClick={handleAdd}
                              className="me-3"
                              color="success"
                            >
                              {"Submit"}
                            </Button>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment>
    </>
  );
};

export default AddCashback;
