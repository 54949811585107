import React, { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Breadcrumbs } from '../../../../AbstractElements';
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import toast from 'react-hot-toast';
import Web3 from "web3";
import ABI_MyContract from '../../../../api/abi-mycontract';
import { insertairdrop,editairdrop } from '../../../../api/api'

function AddAirDrop() {
    const [walletAddress, setWalletAddress] = useState('');
    const [tokenCount, setTokenCount] = useState('');
    const [durationDays, setDurationDays] = useState('');
    
  
    const navigate = useNavigate();
    const location = useLocation();
  
  
    const handleAdd = async () => {
      if(!walletAddress || !tokenCount || !durationDays){
        toast.error("Please enter all fields")
return
      }
      const formData = {
        wallet_address: walletAddress,
        token_count: tokenCount,
        duration_days: durationDays,
      };
  
      const res = await insertairdrop(formData);
      
      if (res.status === 200) {
        navigate(`${process.env.PUBLIC_URL}/airdrop/airdrop_pools_list`);
     
      } else {
        toast.error("Error occure in adding data")

      }
    };
  
  
  
    const handleUpdate = async (id) => {
      const formData = {
        wallet_address: walletAddress,
        token_count: tokenCount,
        duration_days: durationDays,
      };
  
      const res = await editairdrop(id, formData);
      if (res.status === 200) {
        navigate(`${process.env.PUBLIC_URL}/airdrop/airdrop_pools_list`);
      } else {
        toast.error("Error occure in updating data")

      }
    };
  
    const populateData = () => {
      if (location?.state?.dataObj) {
       const {wallet_address,token_count,duration_days}=location.state.dataObj;
       setWalletAddress(wallet_address)
       setTokenCount(token_count)
       setDurationDays(duration_days)
      }
    };
    
  
    useEffect(() => {
      populateData();
    }, []);
  return (
    <Fragment>
    <Breadcrumbs
      parent="Home"
      title="Add Airdrop"
      mainTitle="Add airdrop"
    />
    <Container fluid={true}>
      <Row>
        <Col sm="12">
          <Card>
            <CardBody>
              <Form method="POST">
                <Row>
                  <Col>
                    <FormGroup>
                      <Label>{'Wallet address'}</Label>
                      <Input
                        onChange={(e) => setWalletAddress(e.target.value)}
                        value={walletAddress}
                        type="text"
                        className="form-control"
                        required
                        placeholder="wallet address"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <FormGroup>
                      <Label>{'Number of tokens'}</Label>
                      <Input
                        onChange={(e) => setTokenCount(e.target.value)}
                        value={tokenCount}
                        type="number"
                        className="form-control"
                        required
                        placeholder="Number of tokens"
                      />
                    </FormGroup>
                  </Col>
                  <Col lg={6}>
                    <FormGroup>
                      <Label>{'Lock time period(Days)'}</Label>
                      <Input
                        onChange={(e) => setDurationDays(e.target.value)}
                        value={durationDays}
                        type="number"
                        className="form-control"
                        required
                        placeholder="Lock time period"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup className="mb-0">
                      {location?.state ? (
                        <Button
                          onClick={() =>
                            handleUpdate(location?.state?.dataObj?.airdrop_id)
                          }
                          className="me-3"
                          color="success"
                        >
                          {'Update'}
                        </Button>
                      ) : (
                        <Button
                          onClick={handleAdd}
                          className="me-3"
                          color="success"
                        >
                          {'Submit'}
                        </Button>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  </Fragment>
  )
}

export default AddAirDrop
