import React, { Fragment, useState, useEffect } from "react";
import { Breadcrumbs, H5, Image } from "../../AbstractElements";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
// import { deleteProjectCategory, getProjectCategory } from "../../../api/api";
import { getProject } from "../../api/api";
import Select from 'react-select';
export const style = {
  width: 40,
  height: 40,
};
export const style2 = { width: 60, fontSize: 14, padding: 4 };

function UserProjectsList() {
  const navigate = useNavigate();
  const [dataAPI, setDataAPI] = useState([]);
  const [filteredData, setfilteredData] = useState([])
  const [status, setstatus] = useState('')
  const [type, settype] = useState('')

  const populateData = async () => {
    const res = await getProject();
    setfilteredData([...res.data])
    setDataAPI([...res.data]);
  };


  useEffect(() => {
    populateData();
  }, []);

  const addDataAPI = filteredData?.map((elem) => {
    return {
      Name: `${elem.project_name}`,
      Type: `${elem.project_type}`,
      Status: `${elem.project_status}`,

      action: (
        <div>
          <span>
            <Button
              onClick={() => {
                navigate(`${process.env.PUBLIC_URL}/project/updateStatus/${elem.project_id}`);
              }}
              className="btn btn-success btn-xs"
              style={style2}
            >
              View{" "}
            </Button>
          </span>
        </div>
      ),
    };
  });

  const addColumns = [
    {
      name: "Project Name",
      selector: (row) => row.Name,
      sortable: true,
      center: true,
    },
    {
      name: "Project Type",
      selector: (row) => row.Type,
      sortable: true,
      center: true,
    },
    {
      name: "Project Status",
      selector: (row) => row.Status,
      sortable: true,
      center: true,
    },
    {
      name: "Action",
      selector: (row) => row.action,
      sortable: true,
      center: true,
    },
  ];

  const options = [
    { value: 'NGO', label: "NGO" },
    { value: 'Profitable Business', label: "Profitable Business" },
  ]

  const options2 = [
    { value: 'approved', label: "Approved" },
    { value: 'rejected', label: "Rejected" },
    { value: 'pending', label: "Pending" },
  ]

  useEffect(() => {
    // const checker = dataAPI.filter((t) => t.project_status == status.value)
    // if (checker.length == 0) {
    //   const checker2 = dataAPI.filter((t) => t.project_type == status.value)
    //   setfilteredData(checker2)
    // } else {
    //   setfilteredData(checker)
    // }
    if (type && !status) {
      const checker = dataAPI.filter((t) => t.project_type == type.value)
      setfilteredData(checker)
    }
    if(!type && status){
      const checker = dataAPI.filter((t) => t.project_status == status.value)
      setfilteredData(checker)
    }

    if(type && status){
      const checker = dataAPI.filter((t) => t.project_type == type.value && t.project_status == status.value)
      setfilteredData(checker)
    }

  }, [status, type])


  return (
    <Fragment>
      <Breadcrumbs
        parent="Types"
        title="Projects"
        mainTitle="Projects"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>

              <div className="row">
                <div className="col-lg-6 col-md-6 col-12">
                  <div className="d-flex justify-content-center align-items-center mt-3">
                    <Select
                      options={options}
                      placeholder="Project Type"
                      value={type}
                      onChange={e => settype(e)}
                      className="js-example-basic-single col-sm-10"
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                  <div className="d-flex justify-content-center align-items-center mt-3">
                    <Select
                      options={options2}
                      placeholder="Project Status"
                      value={status}
                      onChange={e => setstatus(e)}
                      className="js-example-basic-single col-sm-10"
                    />
                  </div>
                </div>
              </div>
              <CardHeader className="pb-0">
                <H5>{"User Projects"}</H5>
              </CardHeader>
              <CardBody>
                <div className="table-responsive product-table">
                  <DataTable
                    noHeader
                    pagination
                    paginationServer
                    columns={addColumns}
                    data={addDataAPI}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default UserProjectsList;
