import React, { Fragment, useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { Breadcrumbs } from "../../../AbstractElements";
import { getAntsButtonColor, updateAntsButtonColor } from "../../../api/api";

const AntsButtons = () => {
  const [buttonColor, setButtonColor] = useState("");
  const [showColor, setShowColor] = useState("");
  const [idState, setIdState] = useState([]);

  useEffect(() => {
    getAntsButtonColor()
      .then((res) => {
        setButtonColor(res?.data[0].button_bgcolor);
        setShowColor(res?.data[0].button_bgcolor); // Set the initial value of the input field to the fetched color
        setIdState(res?.data[0].button_id);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleColorChange = (e) => {
    setButtonColor(e.target.value); // Update the selected color in the state
    setShowColor(e.target.value); // Update the color shown in the input field
  };

  const updateData = async (e) => {
    e.preventDefault();
    const formData = {
      button_bgcolor: buttonColor, // Use the selected color from the state
    };

    await updateAntsButtonColor(idState, formData);
    alert(`Updated!`);
  };

  return (
    <>
      <Fragment>
        <Breadcrumbs
          parent="Ants Buttons"
          title="Buttons Background Color"
          mainTitle="Buttons Background Color"
        />
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardBody>
                  <Form method="post">
                    <Row>
                      <Col md={1}>
                        <FormGroup>
                          <Label>{"Select Color"}</Label>
                          <Input
                            onChange={handleColorChange}
                            value={buttonColor}
                            type="color"
                            style={{
                                height: '5vh'
                            }}
                            class="form-control form-control-color"
                          />
                        </FormGroup>
                      </Col>
                      <Col md={11}>
                        <FormGroup>
                          <Label>{"Background Color"}</Label>
                          <Input
                            onChange={handleColorChange}
                            value={showColor}
                            type="text"
                            style={{
                                height: '5vh'
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup className="mb-0">
                          <Button
                            type="submit"
                            onClick={updateData}
                            color="success"
                          >
                            Update
                          </Button>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment>
    </>
  );
};

export default AntsButtons;
