import React, { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Breadcrumbs } from '../../../AbstractElements';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { getAntsFooterContent, insertAntsFooterContent, updateAntsFooterContent } from '../../../api/api';
import { toast } from 'react-toastify';

const AntsFooterDescription = () => {
  const [footerDescription, setFooterDescription] = useState('');
  const [footerId, setFooterId] = useState('');

  const handleAdd = async () => {
    if (footerDescription === "") {
      toast.error("Please add the description value");
      return;
    }

    const formData = {
      footer_description: footerDescription
    };

    const res = await insertAntsFooterContent(formData);
    console.log("res", res)
    if (res.status === 200) {
      toast.success("Description added successfully!")
      setFooterId(res.data.insertId)
    } else {
      alert('Error');
    }
  };
  const handleUpdate = async (id) => {
    if (footerDescription === "") {
      toast.error("Please add the description value");
      return;
    }
    const formData = {
      footer_description: footerDescription
    };

    const res = await updateAntsFooterContent(id, formData);
    if (res.status === 200) {
      toast.success("Description updated successfully!")
    } else {
      alert('Error occured.');
    }
  };

  const populateData = async () => {
    const res = await getAntsFooterContent();
    if (res.data.length > 0) {
      setFooterDescription(res.data[0].footer_description)
      setFooterId(res.data[0].footer_id)
    }
  };

  useEffect(() => {
    populateData();
  }, []);

  return (
    <Fragment>
      <Breadcrumbs
        parent="Ants Footer Socials"
        title="Add Footer Socials"
        mainTitle="Add Footer Socials"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Form method="POST">
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{'Footer Description'}</Label>
                        <Input
                          onChange={(e) => setFooterDescription(e.target.value)}
                          value={footerDescription}
                          type="text"
                          className="form-control"
                          required
                          placeholder="Enter Description"
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <FormGroup className="mb-0">
                        {footerId ? (
                          <Button
                            onClick={() =>
                              handleUpdate(footerId)
                            }
                            className="me-3"
                            color="success"
                          >
                            {'Update'}
                          </Button>
                        ) : (
                          <Button
                            onClick={handleAdd}
                            className="me-3"
                            color="success"
                          >
                            {'Submit'}
                          </Button>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default AntsFooterDescription;
