import React, { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getAntsBrand } from '../../../api/api';
import { Breadcrumbs } from '../../../AbstractElements';
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { updateAntsBrand } from '../../../api/api';

const AntsBrand = () => {
  const [headerImg, setheaderImg] = useState('');
  const [footerImg, setfooterImg] = useState('');
  const [favImg, setfavImg] = useState('');

  const [id, setid] = useState();

  const navigate = useNavigate();
  const location = useLocation();

  const handleChangeHeader = ({ meta, file }, status) => {
    setheaderImg(file);
  };
  const handleChangeFooter = ({ meta, file }, status) => {
    console.log({ file });
    setfooterImg(file);
  };
  const handleChangeFacIcon = ({ meta, file }, status) => {
    setfavImg(file);
  };

  // const handleAdd = async () => {
  //   const formData = new FormData();
  //   formData.append("antsbrand_img", imgFile);

  //   const res = await insertAntsBrand(formData);
  //   if (res.status === 200) {
  //     navigate(`${process.env.PUBLIC_URL}/antsbrand/antsbrand_list`);
  //   } else {
  //     alert("Error");
  //   }
  // };
  const handleUpdate = async () => {
    // if (!headerImg) {
    //   return showError('select Header Image');
    // }
    // if (!footerImg) {
    //   return showError('select Footer Image');
    // }
    // if (!favImg) {
    //   return showError('select Fav Icon Image');
    // }

    function showError(fieldName) {
      toast.error(`Please ${fieldName}`, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    const formData = new FormData();
    formData.append('multiple_images', headerImg);
    formData.append('multiple_images', footerImg);
    formData.append('multiple_images', favImg);

    const res = await updateAntsBrand(id, formData);
    if (res.status === 200) {
      navigate(`${process.env.PUBLIC_URL}/antsbrand/antsbrand_list`);
    } else {
      alert('Error occured.');
    }
  };

  const populateData = async () => {
    const res = await getAntsBrand();
    setid(res?.data[0]?.antsbrand_id);
  };

  useEffect(() => {
    populateData();
  }, []);

  return (
    <Fragment>
      <Breadcrumbs
        parent="Ants Brand"
        title="Add Ants Brand"
        mainTitle="Add Ants Brand"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Form method="POST">
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{'Header Image'}</Label>
                        <Dropzone
                          onChangeStatus={handleChangeHeader}
                          inputContent="Drop An Image"
                          multiple={false}
                          canCancel={true}
                          maxFiles={1}
                          styles={{
                            dropzone: { width: '100%', minHeight: 50 },
                            dropzoneActive: { borderColor: 'green' },
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{'Footer Image'}</Label>
                        <Dropzone
                          onChangeStatus={handleChangeFooter}
                          inputContent="Drop An Image"
                          multiple={false}
                          canCancel={true}
                          maxFiles={1}
                          styles={{
                            dropzone: { width: '100%', minHeight: 50 },
                            dropzoneActive: { borderColor: 'green' },
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{'Fav Icon/Image'}</Label>
                        <Dropzone
                          onChangeStatus={handleChangeFacIcon}
                          inputContent="Drop An Image"
                          multiple={false}
                          canCancel={true}
                          maxFiles={1}
                          styles={{
                            dropzone: { width: '100%', minHeight: 50 },
                            dropzoneActive: { borderColor: 'green' },
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup className="mb-0">
                        <Button
                          onClick={handleUpdate}
                          className="me-3"
                          color="success"
                        >
                          {'Update'}
                        </Button>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default AntsBrand;
// {location?.state ? (
//   <Button
//     onClick={() =>
//       handleUpdate(location?.state?.dataObj?.antsbrand_id)
//     }
//     className="me-3"
//     color="success"
//   >
//     {"Update"}
//   </Button>
// ) : (
//   <Button
//     onClick={handleAdd}
//     className="me-3"
//     color="success"
//   >
//     {"Submit"}
//   </Button>
// )}
