import React, { Fragment, useEffect, useState } from "react";
import { Breadcrumbs } from "../../AbstractElements";
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
} from "reactstrap";
// import { insertProjectCategory, updateProjectCategory } from "../../../api/api";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
export default function UpdateBlogCate() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [name, setname] = useState('')
    // const [status, setstatus] = useState('')
    // const options = [
    //     { value: 'active', label: 'Active' },
    //     { value: 'disActive', label: 'DisActive' },
    // ];

    const handleUpdate = () => {
        if (!name) {
            return showError("enter the name of category");
        }

        function showError(fieldName) {
            toast.error(`Please ${fieldName}`, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        const formData = {
            category_name: name,
        }

        axios.put(`${global.backendUrl}/blogCategory/update/${id}`, formData)
        .then((response) => {
          toast.success(`Category Updated Successfully`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          navigate(`${process.env.PUBLIC_URL}/blogs/blogCategoryList`);
        }).catch((err) => {
          console.log(err)
        })
    }

    useEffect(() => {
        axios.get(`${global.backendUrl}/blogCategory/get`)
            .then((response) => {
                const currentCat = response.data.filter((t) => t.category_blogs_id == id)
                setname(currentCat[0].category_name)
            }).catch((err) => {
                console.log(err)
            })
    }, [])
    return (
        <Fragment>
            <Breadcrumbs
                parent="Blog Categories"
                title="Update Blog Category"
                mainTitle="Update Blog Category"
            />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody>
                                <Form method="POST">
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <Label>{"Category Name"}</Label>
                                                <Input
                                                    type="text"
                                                    required
                                                    value={name}
                                                    className="form-control"
                                                    placeholder="Enter the Category Name"
                                                    onChange={(e) => setname(e.target.value)}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup className="mb-0">
                                                <Button
                                                    onClick={handleUpdate}
                                                    className="me-3"
                                                    color="success"
                                                >
                                                    {"Update"}
                                                </Button>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}
