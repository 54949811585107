import React, { Fragment, useEffect, useState } from "react";
import { Breadcrumbs } from "../../../AbstractElements";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getProjectCategory,
  insertProjectsVote,
  updateProjectsVote,
} from "../../../api/api";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import { TagsInput } from "react-tag-input-component";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const AddProjects = () => {
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [category, setCategory] = useState("");
  const [price, setPrice] = useState("");
  const [etherPrice, setEtherPrice] = useState("");
  const [description, setDescription] = useState("");
  const [metaTitle, setmetaTitle] = useState("");
  const [metaDesc, setmetaDesc] = useState("");
  const [metaKeywordState, setmetaKeywordState] = useState([]);
  const [walletAddress, setwalletAddress] = useState("");
  const [imgFile, setImageFile] = useState("");
  const [getCategories, setGetCategories] = useState([]);
  const [totalRaised, setTotalRaised] = useState("");
  const [donors, setDonors] = useState("");
  const [endBenificeries, setEndBenificeries] = useState("");
  const [openingVotes, setOpeningVotes] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const handleChangeStatus = ({ meta, file }, status) => {
    setImageFile(file);
  };

  const handleAdd = async () => {
    if (title == "") {
      toast.error("Please Enter Title of Project!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    } else if (walletAddress == "") {
      toast.error("Please Enter Wallet Address of Project Owner!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    // console.log("description", description)
    // return
    setIsLoading(true);
    const formData = new FormData();
    formData.append("projects_title", title);
    formData.append("projects_text", text);
    formData.append("projects_category_id", category);
    formData.append("projects_price", price);
    formData.append("projects_price_ether", etherPrice);
    formData.append("projects_description", description);
    formData.append("projects_image", imgFile);
    formData.append("project_walletAddress", walletAddress);
    formData.append("metaTitle", metaTitle);
    formData.append("metaDesc", metaDesc);
    formData.append("projects_total_raised", totalRaised);
    formData.append("projects_donors", donors);
    formData.append("projects_end_benificeries", endBenificeries);
    formData.append("projects_opening_votes", openingVotes);
    formData.append("metaKeywords", JSON.stringify(metaKeywordState));

    const res = await insertProjectsVote(formData);
    if (res.status === 200) {
      setIsLoading(false);
      navigate(`${process.env.PUBLIC_URL}/projects/project_list`);
    } else {
      setIsLoading(false);
      alert("Error");
    }
  };
  const handleUpdate = async (id) => {
    if (title == "") {
      toast.error("Please Enter Title of Project!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    } else if (walletAddress == "") {
      toast.error("Please Enter Wallet Address of Project Owner!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    setIsLoading(true);
    const formData = new FormData();
    formData.append("projects_title", title);
    formData.append("projects_text", text);
    formData.append("projects_category_id", category);
    formData.append("projects_price", price);
    formData.append("projects_price_ether", etherPrice);
    formData.append("projects_description", description);
    formData.append("projects_image", imgFile);
    formData.append("project_walletAddress", walletAddress);
    formData.append("metaTitle", metaTitle);
    formData.append("metaDesc", metaDesc);
    formData.append("projects_total_raised", totalRaised);
    formData.append("projects_donors", donors);
    formData.append("projects_end_benificeries", endBenificeries);
    formData.append("projects_opening_votes", openingVotes);
    formData.append("metaKeywords", JSON.stringify(metaKeywordState));

    const res = await updateProjectsVote(id, formData);
    if (res.status === 200) {
      setIsLoading(false);
      navigate(`${process.env.PUBLIC_URL}/projects/project_list`);
    } else {
      setIsLoading(false);
      alert("Error occured.");
    }
  };

  const populateData = () => {
    if (location?.state) {
      setTitle(location?.state?.dataObj?.projects_title);
      setText(location?.state?.dataObj?.projects_text);
      setCategory(location?.state?.dataObj?.projects_category_id);
      setPrice(location?.state?.dataObj?.projects_price);
      setEtherPrice(location?.state?.dataObj?.projects_price_ether);
      setDescription(location?.state?.dataObj?.projects_description);
      setImageFile(location?.state?.dataObj?.projects_image);
      setwalletAddress(location?.state?.dataObj?.project_walletAddress);
      setmetaTitle(location?.state?.dataObj?.projects_metatitle);
      setmetaDesc(location?.state?.dataObj?.projects_metadesc);
      setmetaKeywordState(
        JSON.parse(location?.state?.dataObj?.projects_metakeywords)
      );
      setTotalRaised(location?.state?.dataObj?.projects_total_raised);
      setDonors(location?.state?.dataObj?.projects_donors);
      setEndBenificeries(location?.state?.dataObj?.projects_end_benificeries);
      setOpeningVotes(location?.state?.dataObj?.projects_opening_votes);
    }
  };

  const fetchGetCategories = async () => {
    const res = await getProjectCategory(); // API Request
    setGetCategories(res?.data);
    // console.log(res?.data)
    if (res?.data.length > 0) {
      // console.log("Working", res?.data[0].category_id)
      setCategory(res?.data[0].category_id);
    }
  };
  useEffect(() => {
    populateData();
    fetchGetCategories();
  }, []);

  function handleChangeDescription(value) {
    setDescription(value);
  }
  return (
    <>
      <Fragment>
        <Breadcrumbs
          parent="Projects"
          title="Add Projects"
          mainTitle="Add Projects"
        />
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardBody>
                  <Form method="POST">
                    <Row>
                      <Col lg={6}>
                        <FormGroup>
                          <Label>{"Title"}</Label>
                          <Input
                            onChange={(e) => setTitle(e.target.value)}
                            value={title}
                            type="text"
                            className="form-control"
                            required
                            placeholder="Enter Title"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg={6}>
                        <FormGroup>
                          <Label>{"Short Text"}</Label>
                          <Input
                            onChange={(e) => setText(e.target.value)}
                            value={text}
                            type="text"
                            className="form-control"
                            required
                            placeholder="Enter Text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <FormGroup>
                          <Label>{"Price"}</Label>
                          <Input
                            onChange={(e) => setPrice(e.target.value)}
                            value={price}
                            type="text"
                            className="form-control"
                            required
                            placeholder="Enter Price"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg={6}>
                        <FormGroup>
                          <Label>{"Ether Price"}</Label>
                          <Input
                            onChange={(e) => setEtherPrice(e.target.value)}
                            value={etherPrice}
                            type="text"
                            className="form-control"
                            required
                            placeholder="Enter Ether Price"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <FormGroup>
                          <Label>{"Select Category"}</Label>
                          <Input
                            type="select"
                            value={category}
                            onChange={(e) => setCategory(e.target.value)}
                            placeholder="Select a Category "
                            className="form-control"
                            required
                          >
                            {getCategories?.map((val, i) => (
                              <option value={val.category_id} key={i}>
                                {val.category_name}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col lg={6}>
                        <FormGroup>
                          <Label>{"Total Raised"}</Label>
                          <Input
                            value={totalRaised}
                            onChange={(e) => {
                              setTotalRaised(e.target.value);
                            }}
                            placeHolder="Enter Total Raised"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>{"Donors"}</Label>
                          <Input
                            value={donors}
                            onChange={(e) => {
                              setDonors(e.target.value);
                            }}
                            placeHolder="Enter Donors"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg={6}>
                        <FormGroup>
                          <Label>{"End Benificeries"}</Label>
                          <Input
                            value={endBenificeries}
                            onChange={(e) => {
                              setEndBenificeries(e.target.value);
                            }}
                            placeHolder="Enter Benificeries"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <FormGroup>
                          <Label>{"Wallet Address"}</Label>
                          <Input
                            onChange={(e) => setwalletAddress(e.target.value)}
                            value={walletAddress}
                            type="text"
                            className="form-control"
                            required
                            placeholder="Enter Your Wallet Address"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg={6}>
                        <FormGroup>
                          <Label>{"Opening Votes"}</Label>
                          <Input
                            value={openingVotes}
                            onChange={(e) => {
                              setOpeningVotes(e.target.value);
                            }}
                            placeHolder="Enter Opening Votes"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>{"Meta Title"}</Label>
                          <Input
                            type="text"
                            required
                            value={metaTitle}
                            className="form-control"
                            placeholder="Enter the Meta Title"
                            onChange={(e) => setmetaTitle(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label>{"Meta Description"}</Label>
                          <Input
                            type="text"
                            required
                            value={metaDesc}
                            className="form-control"
                            placeholder="Enter the Meta Description"
                            onChange={(e) => setmetaDesc(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>{"Meta Keywords"}</Label>
                          <TagsInput
                            value={metaKeywordState}
                            onChange={setmetaKeywordState}
                            placeHolder="Enter Keywords"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>{"Image"}</Label>
                          <Dropzone
                            onChangeStatus={handleChangeStatus}
                            inputContent="Drop An Image"
                            multiple={false}
                            canCancel={true}
                            maxFiles={1}
                            styles={{
                              dropzone: { minHeight: 50 },
                              dropzoneActive: { borderColor: "green" },
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="mb-5">
                      <Col>
                        <FormGroup>
                          <Label>{"Long Description"}</Label>
                          <ReactQuill
                            value={description}
                            onChange={handleChangeDescription}
                            placeholder="Type something here..."
                            modules={{
                              toolbar: [
                                [{ header: [1, 2, 3, 4, false] }],
                                ["bold", "italic", "underline", "strike"],
                                [{ color: [] }, { background: [] }],
                                [{ align: [] }],
                                [{ list: "ordered" }, { list: "bullet" }],
                                ["link", "image", "video"],
                                ["clean"],
                              ],
                            }}
                            formats={[
                              "header",
                              "bold",
                              "italic",
                              "underline",
                              "strike",
                              "color",
                              "background",
                              "align",
                              "list",
                              "bullet",
                              "link",
                              "image",
                              "video",
                            ]}
                            style={{ height: "35vh" }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup className="mb-0">
                          {location?.state ? (
                            <Button
                              onClick={() =>
                                handleUpdate(
                                  location?.state?.dataObj?.projects_id
                                )
                              }
                              className="me-3"
                              color="success"
                            >
                              {isLoading ? (
                                <div
                                  class="spinner-border spinner-border-sm"
                                  role="status"
                                >
                                  <span class="sr-only">Loading...</span>
                                </div>
                              ) : (
                                "Update"
                              )}
                            </Button>
                          ) : (
                            <Button
                              onClick={handleAdd}
                              className="me-3"
                              color="success"
                            >
                              {isLoading ? (
                                <div
                                  class="spinner-border spinner-border-sm"
                                  role="status"
                                >
                                  <span class="sr-only">Loading...</span>
                                </div>
                              ) : (
                                "Submit"
                              )}
                            </Button>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment>
    </>
  );
};

export default AddProjects;
