import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import { Breadcrumbs } from "../../../AbstractElements";
import { insertAntsToken, updateAntsToken } from "../../../api/api";
import CKEditors from "react-ckeditor-component";

const AntsWallet = () => {
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [imgFile, setImageFile] = useState("");
  const [widthState, setWidthState] = useState("");
  const [heightState, setHeightState] = useState("");

  const navigate = useNavigate();
  const location = useLocation();

  const onChange = (evt) => {
    const newContent = evt.editor.getData();
    setText(newContent);
  };

  const handleChangeStatus = ({ meta, file }, status) => {
    setImageFile(file);
  };

  const handleAdd = async () => {
    const formData = new FormData();
    formData.append("wallet_title", title);
    formData.append("wallet_text", text);
    formData.append("wallet_image", imgFile);
    formData.append("wallet_width", widthState);
    formData.append("wallet_height", heightState);

    const res = await insertAntsToken(formData);
    if (res.status === 200) {
      navigate(`${process.env.PUBLIC_URL}/token/antstoken_list`);
    } else {
      alert("Error");
    }
  };
  const handleUpdate = async (id) => {
    const formData = new FormData();
    formData.append("wallet_title", title);
    formData.append("wallet_text", text);
    formData.append("wallet_image", imgFile);
    formData.append("wallet_width", widthState);
    formData.append("wallet_height", heightState);

    const res = await updateAntsToken(id, formData);
    if (res.status === 200) {
      navigate(`${process.env.PUBLIC_URL}/token/antstoken_list`);
    } else {
      alert("Error occured.");
    }
  };

  const populateData = () => {
    if (location?.state) {
      setTitle(location?.state?.dataObj?.wallet_title);
      setText(location?.state?.dataObj?.wallet_text);
      setImageFile(location?.state?.dataObj?.wallet_image);
      setWidthState(location?.state?.dataObj?.wallet_width);
      setHeightState(location?.state?.dataObj?.wallet_height);
    }
  };

  useEffect(() => {
    populateData();
  }, []);

  return (
    <>
      <Fragment>
        <Breadcrumbs
          parent="Ants Wallet"
          title="Add Ants Wallet"
          mainTitle="Add Ants Wallet"
        />
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardBody>
                  <Form method="post">
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>{"Title"}</Label>
                          <input
                            placeholder="Title"
                            className="form-control"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>{"Set Width(%, px)"}</Label>
                          <input
                            type="text"
                            value={widthState}
                            className="form-control"
                            placeholder="Enter Width"
                            onChange={(e) => setWidthState(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label>{"Set Height(vh, px)"}</Label>
                          <input
                            type="text"
                            value={heightState}
                            className="form-control"
                            placeholder="Enter Height"
                            onChange={(e) => setHeightState(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>{"Image"}</Label>
                          <Dropzone
                            onChangeStatus={handleChangeStatus}
                            inputContent="Drop An Image"
                            multiple={false}
                            canCancel={true}
                            maxFiles={1}
                            styles={{
                              dropzone: { width: "100%", minHeight: 50 },
                              dropzoneActive: { borderColor: "green" },
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>{"Text"}</Label>
                          <CKEditors
                            activeclassName="p10"
                            content={text}
                            events={{
                              change: onChange,
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup className="mb-0">
                          {location?.state ? (
                            <Button
                              onClick={() =>
                                handleUpdate(
                                  location?.state?.dataObj?.wallet_id
                                )
                              }
                              className="me-3"
                              color="success"
                            >
                              {"Update"}
                            </Button>
                          ) : (
                            <Button
                              onClick={handleAdd}
                              className="me-3"
                              color="success"
                            >
                              {"Submit"}
                            </Button>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment>
    </>
  );
};

export default AntsWallet;
