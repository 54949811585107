import React, { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Breadcrumbs } from '../../../AbstractElements';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { insertAntsTickets, updateAntsTickets } from '../../../api/api';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const AntsTickets = () => {
  const [name, setName] = useState('');
  const [price, setPrice] = useState('');
  const [returnValue, setReturnValue] = useState('');
  const [weightage, setWeightage] = useState('');
  const [description, setDescription] = useState('');

  const [isExpiryPermanent, setIsExpiryPermanent] = useState(false);
  const [expiryDate, setExpiryDate] = useState("");

  const navigate = useNavigate();
  const location = useLocation();

  const handleAdd = async () => {
    const formData = {
      ticket_name: name,
      ticket_price: price,
      ticket_return: returnValue,
      ticket_weightage: weightage,
      ticket_description: description,
      ticket_expiry_date: expiryDate,
      ticket_is_expiry_permanent: isExpiryPermanent ? "Yes" : "No"
    };

    const res = await insertAntsTickets(formData);
    if (res.status === 200) {
      navigate(`${process.env.PUBLIC_URL}/tickets/ticket_list`);
    } else {
      alert('Error');
    }
  };
  const handleUpdate = async (id) => {
    const formData = {
      ticket_name: name,
      ticket_price: price,
      ticket_return: returnValue,
      ticket_weightage: weightage,
      ticket_description: description,
      ticket_expiry_date: expiryDate,
      ticket_is_expiry_permanent: isExpiryPermanent ? "Yes" : "No"
    };

    const res = await updateAntsTickets(id, formData);
    if (res.status === 200) {
      navigate(`${process.env.PUBLIC_URL}/tickets/ticket_list`);
    } else {
      alert('Error occured.');
    }
  };

  const populateData = () => {
    if (location?.state) {
      setName(location?.state?.dataObj?.ticket_name);
      setPrice(location?.state?.dataObj?.ticket_price);
      setReturnValue(location?.state?.dataObj?.ticket_return);
      setWeightage(location?.state?.dataObj?.ticket_weightage);
      setDescription(location?.state?.dataObj?.ticket_description);
      if (location?.state?.dataObj?.ticket_is_expiry_permanent) {
        setExpiryDate(location?.state?.dataObj?.ticket_expiry_date);
      }
      // ticket_is_expiry_permanent
      let ticket_is_expiry_permanent = false;
      if (location?.state?.dataObj?.ticket_is_expiry_permanent) {
        if (location?.state?.dataObj?.ticket_is_expiry_permanent === "Yes") {
          ticket_is_expiry_permanent = true
        }
      }
      setIsExpiryPermanent(ticket_is_expiry_permanent);
    }
  };

  useEffect(() => {
    populateData();
  }, []);
  function handleChangeDescription(value) {
    setDescription(value);
  }
  return (
    <Fragment>
      <Breadcrumbs
        parent="Tickets"
        title="Add Tickets"
        mainTitle="Add Tickets"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Form method="POST">
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{'Ticket Name'}</Label>
                        <Input
                          onChange={(e) => setName(e.target.value)}
                          value={name}
                          type="text"
                          className="form-control"
                          required
                          placeholder="Enter Name"
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>{'Ticket Price'}</Label>
                        <Input
                          onChange={(e) => setPrice(e.target.value)}
                          value={price}
                          type="number"
                          className="form-control"
                          required
                          placeholder="Enter Price"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{'Ticket Return Percentage'}</Label>
                        <Input
                          onChange={(e) => setReturnValue(e.target.value)}
                          value={returnValue}
                          type="number"
                          className="form-control"
                          required
                          placeholder="Enter Return Percentage"
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>{'Ticket Weightage'}</Label>
                        <Input
                          onChange={(e) => setWeightage(e.target.value)}
                          value={weightage}
                          type="number"
                          className="form-control"
                          required
                          placeholder="Enter Weightage"
                        />
                      </FormGroup>
                    </Col>
                    <Row>
                      <Col md={3}>
                        <FormGroup>
                          <input
                            type="checkbox"
                            value="Is Expiry Permanent"
                            checked={isExpiryPermanent}
                            onChange={(e) => {
                              setIsExpiryPermanent(e.target.checked)
                            }}
                            className='mt-2'
                          /> Is Expiry Permanent
                        </FormGroup>
                      </Col>
                      <Col md={6} >
                        <FormGroup>
                          <Label>{'Expiry (Days)'}</Label>
                          <Input
                            type="number"
                            value={expiryDate}
                            onChange={(e) => {
                              setExpiryDate(e.target.value)
                            }}
                            className="form-control"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{'Ticket Description'}</Label>
                        <ReactQuill
                          value={description}
                          onChange={handleChangeDescription}
                          placeholder="Type something here..."
                          modules={{
                            toolbar: [
                              [{ header: [1, 2, 3, 4, false] }],
                              ["bold", "italic", "underline", "strike"],
                              [{ color: [] }, { background: [] }],
                              [{ align: [] }],
                              [{ list: "ordered" }, { list: "bullet" }],
                              ["link", "image", "video"],
                              ["clean"],
                            ],
                          }}
                          formats={[
                            "header",
                            "bold",
                            "italic",
                            "underline",
                            "strike",
                            "color",
                            "background",
                            "align",
                            "list",
                            "bullet",
                            "link",
                            "image",
                            "video",
                          ]}
                          style={{ height: "35vh" }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <FormGroup className="mb-0 mt-5">
                        {location?.state ? (
                          <Button
                            onClick={() =>
                              handleUpdate(location?.state?.dataObj?.ticket_id)
                            }
                            className="me-3"
                            color="success"
                          >
                            {'Update'}
                          </Button>
                        ) : (
                          <Button
                            onClick={handleAdd}
                            className="me-3"
                            color="success"
                          >
                            {'Submit'}
                          </Button>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default AntsTickets;
