import React, { Fragment, useEffect, useState } from "react";
import { Breadcrumbs } from "../../AbstractElements";
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
} from "reactstrap";
import { insertProjectType, updateProjectType } from "../../api/api";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";

const AddStat = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false)

    const [totalRaised, settotalRaised] = useState('')
    const [projectFun, setprojectFun] = useState('')
    const [participants, setparticipants] = useState('')
    const [antsSupply, setantsSupply] = useState('')
    const [id, setid] = useState('')

    const handleUpdate = () => {
        const formData = {
            total_raised: totalRaised,
            projects_funded: projectFun,
            participants: participants,
            ants_supply: antsSupply,
        }
        setIsLoading(true)
        axios.put(`${global.backendUrl}/stats/update/${id}`, formData)
            .then((response) => {
                setIsLoading(false)
                toast.success(`Stats Updated Successfully`, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }).catch((err) => {
                console.log(err)
            })
    };

    useEffect(() => {
        axios.get(`${global.backendUrl}/stats/get`)
            .then((response) => {
                settotalRaised(response?.data[0].total_raised)
                setantsSupply(response?.data[0].ants_supply)
                setparticipants(response?.data[0].participants)
                setprojectFun(response?.data[0].projects_funded)
                setid(response?.data[0].id)
            }).catch((err) => {
                console.log(err)
            })
    }, []);

    return (
        <>
            <Fragment>
                <Breadcrumbs
                    parent="Stats"
                    title="Update Stats"
                    mainTitle="Update Stats"
                />
                <Container fluid={true}>
                    <Row>
                        <Col sm="12">
                            <Card>
                                <CardBody>
                                    <Form method="POST">
                                        <Row>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <Label>{"Total Raised"}</Label>
                                                    <Input
                                                        type="text"
                                                        required
                                                        value={totalRaised}
                                                        className="form-control"
                                                        placeholder="Enter Total Raised"
                                                        onChange={(e) => settotalRaised(e.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <Label>{"Projects Funded"}</Label>
                                                    <Input
                                                        type="text"
                                                        required
                                                        value={projectFun}
                                                        className="form-control"
                                                        placeholder="Enter Projects Funded"
                                                        onChange={(e) => setprojectFun(e.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <Label>{"Participants"}</Label>
                                                    <Input
                                                        type="text"
                                                        required
                                                        value={participants}
                                                        className="form-control"
                                                        placeholder="Enter Participants"
                                                        onChange={(e) => setparticipants(e.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <Label>{"Ants Supply"}</Label>
                                                    <Input
                                                        type="text"
                                                        required
                                                        value={antsSupply}
                                                        className="form-control"
                                                        placeholder="Enter Ants Supply"
                                                        onChange={(e) => setantsSupply(e.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup className="mb-0">
                                                    {/* {location?.state ? (
                            <Button
                              onClick={() =>
                                handleUpdate(location?.state?.dataObj?.type_id)
                              }
                              className="me-3"
                              color="success"
                            >
                              {
                                isLoading ?
                                  <div class="spinner-border spinner-border-sm" role="status">
                                    <span class="sr-only">Loading...</span>
                                  </div>
                                  : "Update"
                              }
                            </Button>
                          ) : ( */}
                                                    <Button
                                                        onClick={handleUpdate}
                                                        className="me-3"
                                                        color="success"
                                                    >
                                                        {
                                                            isLoading ?
                                                                <div class="spinner-border spinner-border-sm" role="status">
                                                                    <span class="sr-only">Loading...</span>
                                                                </div>
                                                                : "Update"
                                                        }
                                                    </Button>
                                                    {/* )} */}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        </>
    );
};

export default AddStat;
